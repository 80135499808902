import { notification } from "antd";
const SuccessNotification = props => {
  notification["success"]({
    message: `Success`,
    description: `${props.text}`,
    duration: 4
  });
};

export default SuccessNotification;
