import React, { useState, useEffect, useCallback, useContext } from "react";
import { Input, Layout, Typography, AutoComplete } from "antd";
import { useHistory } from "react-router-dom";
import intl from "react-intl-universal";
import Loader from "../../components/Loader";
import ErrorNotification from "../../components/UI/Notifications/ErrorNotification";
import debounce from "lodash.debounce";
import { createReports, autocompleteGetItems } from "../../services/api";
import { LangContext } from "../../controllers/Lang";
import "./NewReport.scss";

const { Content } = Layout;
const noop = () => {};

const NewReport = () => {
  const { lang } = useContext(LangContext);
  const history = useHistory();
  const [loaded, setLoaded] = useState(true);
  const [name, setName] = useState("");
  const [company, setCompany] = useState(null);
  const [companySearch, setCompanySearch] = useState("");
  const [companyOptions, setCompanyOptions] = useState([]);

  const create = useCallback(async () => {
    setLoaded(false);
    try {
      const response = await createReports({ name, company_id: +company });
      history.replace(`/${lang}/reports/${response.data.id}`);
    } catch (text) {
      ErrorNotification({ text });
    }
    setLoaded(true);
  }, [setLoaded, name, company, history, lang]);

  const handleName = useCallback(
    e => {
      setName(e.target.value);
    },
    [setName]
  );

  const searchCompanies = useCallback(
    debounce(async search => {
      try {
        if (search !== "") {
          const data = {
            name: search,
            model: "companies"
          };
          const opts = await autocompleteGetItems(data);
          setCompanyOptions(opts.data.items);
        } else {
          setCompanyOptions([]);
        }
      } catch (text) {
        setCompanyOptions([]);
        ErrorNotification({ text });
      }
    }, 1200),
    [setCompanyOptions]
  );

  const handleCompanySelect = useCallback(
    (e, o) => {
      setCompanySearch(o.props.children);
      setCompany(e);
    },
    [setCompany, setCompanySearch]
  );

  const handleCompanySearch = useCallback(
    e => {
      setCompany(null);
      searchCompanies(e);
      setCompanySearch(e);
    },
    [setCompany, setCompanySearch, searchCompanies]
  );

  useEffect(() => {
    //load(nav);
  }, []);

  return (
    <Layout className="report-new">
      <Content>
        {!loaded && <Loader />}
        {loaded && (
          <div className="report-new-form">
            <Typography.Title className="report-new-field">
              {intl.get("reportName")}
            </Typography.Title>
            <Input
              value={name}
              className="newReportReportName"
              onChange={handleName}
              placeholder={intl.get("reportNamePlaceholder")}
            />
            <Typography.Title className="report-new-field">
              {intl.get("reportCompany")}
            </Typography.Title>
            <AutoComplete
              className="newReportChooseCompanySelect"
              value={companySearch}
              onSearch={handleCompanySearch}
              onSelect={handleCompanySelect}
              dropdownMatchSelectWidth={false}
              placeholder={intl.get("reportCompanyPlaceholder")}
            >
              {companyOptions.map(o => (
                <AutoComplete.Option key={o.id}>{o.name}</AutoComplete.Option>
              ))}
            </AutoComplete>
            <div
              className={
                name.length > 3 && company !== null
                  ? "reports-new-next"
                  : "reports-new-disabled"
              }
              onClick={name.length > 3 && company !== null ? create : noop}
            >
              {intl.get("reportNext")}
            </div>
          </div>
        )}
      </Content>
    </Layout>
  );
};

export default NewReport;
