import React, { Component } from "react";
import countryList from "iso-639-1";
import { Spin, Layout } from "antd";
import intl from "react-intl-universal";
import AddLanguage from "../../components/AddLanguage";
import SuccessNotification from "../../components/UI/Notifications/SuccessNotification";
import ErrorNotification from "../../components/UI/Notifications/ErrorNotification";
import {
  getLanguageList,
  addLanguageToDB,
  removeLanguageItemFromDB
} from "../../services/api";
import { filterArraysOfCountries } from "../../utils/filteringArrays";

class LanguagePage extends Component {
  state = {
    countriesList: [],
    choosedCountries: [],
    chosedCountry: "",
    inited: false
  };
  componentDidMount = () => {
    this.updateListOfCountries();
  };
  onChangeSelector = e => {
    this.setState({
      chosedCountry: e
    });
  };
  addLanguegeToDB = async () => {
    const { chosedCountry } = this.state;
    if (chosedCountry !== "") {
      try {
        const data = {
          name: chosedCountry,
          code: countryList.getCode(chosedCountry)
        };
        const res = await addLanguageToDB(data);
        this.setState({
          inited: false
        });
        if (res.status === 200) {
          this.updateListOfCountries();
          SuccessNotification({
            text: intl.get("successMessageForNotificationWindow")
          });
        }
      } catch (error) {
        ErrorNotification({ text: error });
      }
    }
  };
  removeLanguageFromDB = async item => {
    try {
      const res = await removeLanguageItemFromDB(item.id);
      this.setState({
        inited: false
      });
      if (res.status === 200) {
        this.updateListOfCountries();
        SuccessNotification({
          text: intl.get("successMessageForNotificationWindow")
        });
      }
    } catch (error) {
      ErrorNotification({ text: error });
    }
  };
  updateListOfCountries = async () => {
    try {
      const choosedCountriesList = (await getLanguageList()) || [];
      const prepareArrayOfCountries = countryList.getAllNames() || [];
      const filteredCountriesArray = filterArraysOfCountries(
        prepareArrayOfCountries,
        choosedCountriesList.items
      );
      this.setState({
        countriesList: filteredCountriesArray,
        choosedCountries: choosedCountriesList.items || [],
        chosedCountry: "",
        inited: true
      });
    } catch (error) {
      ErrorNotification({ text: error });
      this.setState({
        inited: true
      });
    }
  };
  render() {
    const { countriesList, choosedCountries = [], inited } = this.state;
    if (!inited) {
      return (
        <Layout className="layoutForSpinner">
          <Spin size="large" className="spinComponentStyle" />
        </Layout>
      );
    }
    return (
      <React.Fragment>
        <AddLanguage
          countryList={countriesList}
          onChangeSelector={this.onChangeSelector}
          choosedCountries={choosedCountries}
          addLanguegeToDB={this.addLanguegeToDB}
          removeLanguageFromDB={this.removeLanguageFromDB}
        />
      </React.Fragment>
    );
  }
}

export default LanguagePage;
