import React from "react";
import "./LogoBlock.scss";

const LogoBlock = () => {
  return (
    <div className="logoBlock">
      <img alt="Logo" src={`/images/swathlyLogo.svg`} />
    </div>
  );
};
export default LogoBlock;
