import intl from "react-intl-universal";
import IntlPolyfill from "intl";
import "intl/locale-data/jsonp/en.js";
import en from "./en.json";

global.Intl = IntlPolyfill;

export const isSupportedLocale = lang => ["en"].includes(lang);

export default lang =>
  intl.init({
    escapeHtml: false,
    currentLocale: lang,
    locales: { en }
  });
