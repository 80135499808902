import React, { useContext, useEffect, useState } from "react";
import { Button, Table } from "antd";
import { getUserList } from "../../services/api";
import { TableButton, Flex } from "./styled";
import { AuthContext } from "../../controllers/Auth";
import { Link } from "react-router-dom";
import { useHistory } from "react-router";
import ModalWindow from "../../components/UI/ModalWindow";
import ErrorNotification from "../../components/UI/Notifications/ErrorNotification";
import { removeUser } from "../../services/auth";
import EditUserModal, { USER_CHANGE } from "./Modal";
import { useModal } from "../../hooks/useModal";

const limit = 20;

function Users() {
  const history = useHistory();
  const { open } = useModal(USER_CHANGE);
  const [loading, setLoading] = useState(true);
  const [list, setList] = useState([]);
  const [offset, setOffset] = useState(0);
  const [deleteUserId, toggleDeleteUser] = useState(0);
  const { user } = useContext(AuthContext);

  if (!["super_admin", "admin"].includes(user?.role?.name)) {
    history.go(-1);
  }

  const columns = [
    {
      title: "Company",
      dataIndex: "company",
      render: company => <span>{company?.name || "-"}</span>
    },
    {
      title: "Name",
      dataIndex: "first_name",
      render: (first_name, { last_name }) => (
        <span>
          {first_name} {last_name}
        </span>
      )
    },
    {
      title: "Email",
      dataIndex: "email"
    },
    {
      title: "Access to report",
      dataIndex: "reports",
      render: reports => (
        <span>{reports.map((i, index) => [!!index && ", ", i.name])}</span>
      )
    },
    {
      title: "Edit Permission",
      key: "actions",
      width: "99px",
      align: "center",
      render: (i, { reports, first_name, last_name }) => (
        <TableButton
          type="edit"
          onClick={() =>
            open({ id: i.id, reports, name: `${first_name} ${last_name}` })
          }
        />
      )
    },
    {
      title: "Delete user",
      key: "remove",
      align: "center",
      width: "99px",
      render: i => (
        <TableButton
          type="close-circle-o"
          onClick={() => toggleDeleteUser(i.id)}
        />
      )
    }
  ];

  const [pagination, setPagination] = useState({
    defaultPageSize: limit,
    total: 200,
    showTotal: total => `Total ${total} items`
  });

  useEffect(() => {
    loadData();
  }, [offset]);

  async function loadData() {
    setLoading(true);
    const res = await getUserList(limit, offset);
    setPagination(prev => ({
      ...prev,
      total: res.total,
      current: Math.floor((limit + offset) / limit)
    }));
    setList(res.items);
    setLoading(false);
  }

  function handleTableChange(pagination) {
    setOffset((pagination.current - 1) * limit);
  }
  function handleRemoveUser() {
    removeUser(deleteUserId)
      .then(() => {
        toggleDeleteUser(0);
        loadData();
      })
      .catch(error => {
        ErrorNotification({ text: error });
      });
  }
  function handleRefetch() {
    loadData();
  }
  return (
    <>
      <Flex>
        <h1>Users</h1>
        <Link to="users/create">
          <Button size="large" type="primary">
            Create
          </Button>
        </Link>
      </Flex>
      <Table
        rowKey={record => record.id}
        columns={columns}
        dataSource={list}
        loading={loading}
        pagination={pagination}
        onChange={handleTableChange}
      />
      <ModalWindow
        title="Delete confirm window"
        open={!!deleteUserId}
        handleSubmit={handleRemoveUser}
        handleClose={() => toggleDeleteUser(0)}
      >
        Are you sure want to delete this user?
      </ModalWindow>
      <EditUserModal refetch={handleRefetch} />
    </>
  );
}
export default Users;
