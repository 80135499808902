import { DataContext } from "../../Context";
import React, { memo, useContext, useState } from "react";
import { Formik } from "formik";
import { Button as Submit } from "../../styled";
import { SelectWrap, Title } from "./styled";
import { Form, Select } from "formik-antd";
import makeID from "../../../../../utils/getID";
import { Button, Tabs } from "antd";

const { Option } = Select;
const { TabPane } = Tabs;

function Step3({ uploadFile }) {
  const [tab, setTab] = useState("1");
  const { data, updateData } = useContext(DataContext);
  const [fields, addField] = useState(() => [makeID()]);
  const [contacts, addContacts] = useState(() => [makeID()]);
  function handleOnSubmit(val) {
    updateData(prev => ({
      ...prev,
      ...val,
      activeTab: tab === "1" ? "companies" : "contacts"
    }));
    setTimeout(() => {
      uploadFile();
    }, 0);
  }

  return (
    <>
      <Title>Update specific field</Title>
      <Tabs defaultActiveKey="1" activeKey={tab} onChange={setTab}>
        <TabPane tab="Companies" key="1" />
        <TabPane tab="Contacts" key="2" />
      </Tabs>
      <Formik
        initialValues={{}}
        onSubmit={handleOnSubmit}
        render={() => (
          <Form>
            {tab === "1" && (
              <>
                {fields.map(id => (
                  <SelectWrap>
                    <Select name={`specific_field-${id}`} allowClear>
                      {data.options.updateFields.map(i => (
                        <Option value={i.field} key={i.field}>
                          {i.name}
                        </Option>
                      ))}
                    </Select>
                  </SelectWrap>
                ))}
                <Button
                  type="link"
                  onClick={() => addField(prev => [...prev, makeID()])}
                >
                  + Add field
                </Button>
              </>
            )}
            {tab === "2" && (
              <>
                {contacts.map(id => (
                  <SelectWrap>
                    <Select name={`contact-field-${id}`} allowClear>
                      {data.options.contactUpdateFields.map(i => (
                        <Option value={i.field} key={i.field}>
                          {i.name}
                        </Option>
                      ))}
                    </Select>
                  </SelectWrap>
                ))}
                <Button
                  type="link"
                  onClick={() => addContacts(prev => [...prev, makeID()])}
                >
                  + Add field
                </Button>
              </>
            )}
            <Submit type="primary" htmlType="submit" size="large">
              Import file
            </Submit>
          </Form>
        )}
      />
    </>
  );
}
export default memo(Step3);
