import { Form as _Form } from "formik-antd";
import styled from "styled-components";

export const Form = styled(_Form)`
  width: 500px;
  margin: 0 auto;
  text-align: left;
  padding: 48px 0;

  .bigger .ant-form-item-label,
  h3 {
    font-weight: bold;
    margin-bottom: 5px;

    font-size: 18px;
    line-height: 21px;
    color: #4a5360;
  }
  .bigger .ant-form-item-label label {
    font-size: 18px;
  }
  h3 {
    padding-bottom: 16px;
  }
  .ant-btn-link {
    padding-left: 0;
    margin-bottom: 16px;
  }
  .ant-input::placeholder {
    text-align: left;
  }
`;

export const Buttons = styled.div`
  display: flex;
  justify-content: center;
  padding-top: 32px;
  > * {
    margin: 0 16px;
    flex: none;
    width: 233px;
  }
`;
export const Error = styled.p`
  color: #f5222d;
`;
