import React, { useState, useCallback } from "react";
import { Input, Layout, Typography, Button, Icon } from "antd";
import intl from "react-intl-universal";
import Loader from "../../components/Loader";
import ErrorNotification from "../../components/UI/Notifications/ErrorNotification";
import { createProspect, updateProspect } from "../../services/api";
import { ReportNameAndStatus } from "./ReportNameAndStatus";

const { Content } = Layout;
const noop = () => {};

const isInvalidUrl = string => {
  var res = string.match(
    /(http(s)?:\/\/.)?(www\.)?[-a-zA-Z0-9@:%._+~#=]{2,256}\.[a-z]{2,6}\b([-a-zA-Z0-9@:%_+.~#?&//=]*)/g
  );
  return !res;
};

const ProspectDetails = ({ report, next }) => {
  const [loaded, setLoaded] = useState(true);
  const [urls, setUrls] = useState(report.prospect.map(i => i.url));

  const handleNext = useCallback(async () => {
    setLoaded(false);
    try {
      const request =
        report.prospect && report.prospect.length
          ? updateProspect
          : createProspect;
      await request({
        report_id: +report.id,
        company_id: +report.company_id,
        url: urls
      });
      next();
    } catch (text) {
      ErrorNotification({ text });
    }
    setLoaded(true);
  }, [setLoaded, report, next, urls]);

  const handleUrl = useCallback(
    (i, { target: { value } }) => {
      setUrls(s => {
        const collection = [...s.map((u, j) => (j === i ? value : u))];
        return collection;
      });
    },
    [setUrls]
  );

  const handleAddUrl = useCallback(() => {
    setUrls(s => {
      const collection = [...s];
      collection.push("");
      return collection;
    });
  }, [setUrls]);

  const handleImport = useCallback(
    ({ target: { files } }) => {
      try {
        if (files && files[0]) {
          const reader = new FileReader();
          reader.onload = function(e) {
            try {
              const data = [];
              e.target.result.split("\n").forEach(line => {
                if (!line.length) {
                  return;
                }
                line.split(",").forEach((col, i) => {
                  if (i === 0) {
                    data.push(col);
                  }
                });
              });
              setUrls(data);
            } catch (text) {
              ErrorNotification({ text });
            }
          };
          reader.readAsText(files[0]);
        }
      } catch (text) {
        ErrorNotification({ text });
      }
    },
    [setUrls]
  );

  const handleRemoveUrl = useCallback(
    j => {
      setUrls(s => {
        const collection = [...s].filter((e, i) => i !== j);
        return collection;
      });
    },
    [setUrls]
  );

  const valid = !urls.some(isInvalidUrl);

  return (
    <Layout>
      <Content className="report">
        {!loaded && <Loader />}
        {loaded && (
          <div className="report-form">
            {report && report.name && <ReportNameAndStatus report={report} />}
            <Typography.Title className="report-field">
              {intl.get("reportUrlTitle")}
            </Typography.Title>

            {urls.map((url, i) => (
              <Input
                className="report-value"
                key={i}
                value={url}
                onChange={handleUrl.bind(null, i)}
                placeholder={intl.get("reportUrlPlaceholder")}
                addonAfter={
                  i && (
                    <Button
                      icon="delete"
                      size="small"
                      type="link"
                      onClick={handleRemoveUrl.bind(null, i)}
                    />
                  )
                }
              />
            ))}
            <div className="report-url-add" onClick={handleAddUrl}>
              <img alt="Status" src={`/images/add.png`} />
              {intl.get("reportUrlAdd")}
            </div>
            <label className="report-import">
              <Icon className="companiesImportIconStyle" type="upload" />
              {intl.get("reportsImport")}
              <input
                className="report-import-file"
                type="file"
                onChange={handleImport}
              />
            </label>
            <div
              className={valid ? "reports-next" : "reports-disabled"}
              onClick={valid ? handleNext : noop}
            >
              {intl.get("reportNext")}
            </div>
          </div>
        )}
      </Content>
    </Layout>
  );
};

export default ProspectDetails;
