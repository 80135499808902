import styled from "styled-components";
import { List as _List } from "antd";
import { Icon } from "antd";

export const List = styled(_List)`
  width: 1000px;
  text-align: left;
  margin: 0 auto;
`;
export const TableButton = styled(Icon)`
  color: #2593fc;
  font-size: 16px;
`;
export const Flex = styled.div`
  display: flex;
  justify-content: space-between;
`;
export const Item = styled.div`
  display: inline-block;
  padding-bottom: 16px;
  padding-right: 16px;
  button {
    margin-left: 9px;
  }
`;
export const Label = styled.label`
  > span {
    font-size: 14px;
    color: #4a5360;
    padding-bottom: 16px;
  }
`;
