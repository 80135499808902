import React, { createContext, useState } from "react";

const LANG_STATE = {
  lang: "en",
  setLang: lang => {
    console.log(lang);
  }
};

export const LangContext = createContext(LANG_STATE);

export const LangController = ({ children }) => {
  const [lang, setLang] = useState(LANG_STATE.lang);
  return (
    <LangContext.Provider
      value={{
        lang,
        setLang
      }}
    >
      {children}
    </LangContext.Provider>
  );
};
