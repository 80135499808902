import React, { useState, useCallback } from "react";
import { Tag, Modal, InputNumber, Icon } from "antd";
import ErrorNotification from "../../../../components/UI/Notifications/ErrorNotification";
import SuccessNotification from "../../../../components/UI/Notifications/SuccessNotification";
import {
  updateCompaniesKeywordGroup,
  editKeywordItemInTaxonomy
} from "../../../../services/api";

import "./KeywordListItem.scss";

const KeywordListItem = props => {
  const [state, setState] = useState({
    openEditWindow: false,
    keywordItemCount: props.item.count || "",
    keywordItemSemantic: props.item.semantic || "",
    keywordGroupName:
      props.item && props.item.keywordGroups && props.item.keywordGroups.name
        ? props.item.keywordGroups.name
        : props.item.keywordGroup.name
  });

  const handleClose = useCallback(() => {
    setState(s => ({
      ...s,
      openEditWindow: false
    }));
  }, [setState]);

  const handleSaveKeywordData = useCallback(async () => {
    try {
      const data = {
        count: Number(state.keywordItemCount),
        semantic: Number(state.keywordItemSemantic)
      };
      await (props.type === "taxonomy"
        ? editKeywordItemInTaxonomy
        : updateCompaniesKeywordGroup)(props.item.id, data).then(result => {
        SuccessNotification({ text: "Keyword item was updated" });
        handleClose();
      });
    } catch (error) {
      ErrorNotification({ text: error });
    }
  }, [
    handleClose,
    props.item.id,
    props.type,
    state.keywordItemCount,
    state.keywordItemSemantic
  ]);
  const handleOpen = useCallback(() => {
    setState(s => ({
      ...s,
      openEditWindow: true
    }));
  }, [setState]);
  return (
    <>
      <Tag className="companyProfileKeywordListItemTag">
        <div className="companyProfileTopologyKeywordTagItemContainer">
          <div>
            <span className="companyProfileKeywordListItemText">
              {state.keywordGroupName}
            </span>
            <span className="keywordItemSemanticData">
              {"#"}
              {state.keywordItemCount}
              {" - "}
              {state.keywordItemSemantic}
              {"%"}
            </span>
          </div>
          <div>
            <Icon
              className="companyProfileTopologyKeywordItemIcon"
              type="edit"
              onClick={handleOpen}
            />
            <Icon
              className="companyProfileTopologyKeywordItemIcon"
              type="close-circle"
              onClick={() => {
                props.handleRemoveItem(props.item.id);
              }}
            />
          </div>
        </div>
      </Tag>
      <Modal
        title="Edit keyword data"
        visible={state.openEditWindow}
        onOk={handleSaveKeywordData}
        onCancel={handleClose}
      >
        <div>
          <span>Edit count:</span>
          <InputNumber
            className="addDataSourceInput"
            placeholder="Add count"
            value={state.keywordItemCount}
            onChange={value => {
              setState(state => {
                return {
                  ...state,
                  keywordItemCount: value
                };
              });
            }}
          />
          <span>Edit semantic:</span>
          <InputNumber
            className="addDataSourceInput"
            placeholder="Add semantic"
            value={state.keywordItemSemantic}
            onChange={value => {
              setState(state => {
                return {
                  ...state,
                  keywordItemSemantic: value
                };
              });
            }}
          />
        </div>
      </Modal>
    </>
  );
};

export default KeywordListItem;
