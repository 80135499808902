import React from "react";
import { Modal } from "antd";
import { useModal } from "../../../hooks/useModal";

const ModalWindow = props => {
  const { name } = props;
  const { isOpen, close } = useModal(name);

  function handleOnClose() {
    if (typeof props.handleOnClose === "function") {
      props.handleOnClose();
    }
    close();
  }

  return (
    <>
      <Modal
        title={props.title}
        visible={isOpen}
        onOk={props.handleSubmit}
        onCancel={handleOnClose}
        {...props}
      >
        {props.children}
      </Modal>
    </>
  );
};

export default ModalWindow;
