import React from "react";
import { Icon } from "antd";
const HeaderForPanel = props => {
  return (
    <>
      <div className="headerForIndustryPanel">
        {props.headerFor === "industry" && (
          <p className="industryTitlePanel">{props.name}</p>
        )}
        {props.headerFor === "segment" && (
          <>
            <Icon
              type="minus-circle"
              theme="filled"
              className="segmentIconStyle"
            />
            <p className="segmentTitlePanel">{props.name}</p>
          </>
        )}
        {props.headerFor === "category" && (
          <>
            <Icon
              type="minus-circle"
              theme="filled"
              className="categoryIconStyle"
            />
            <p className="segmentTitlePanel">{props.name}</p>
          </>
        )}
        {props.headerFor === "industry" && (
          <div className="itemCountWithPictureBlock">
            <div className="countAndLabelTextBlock">
              <Icon type="picture" className="listItemImagesStyle" />
              <p className="listItemText">{props.segmentsCount} segments</p>
            </div>
            <div className="countAndLabelTextBlock">
              <Icon type="picture" className="listItemImagesStyle" />
              <p className="listItemText">{props.categoriesCount} categories</p>
            </div>
            <div className="countAndLabelTextBlock">
              <Icon type="picture" className="listItemImagesStyle" />
              <p className="listItemText">{props.keywordsCount} keywords</p>
            </div>
          </div>
        )}
        {props.headerFor === "segment" && (
          <div className="itemCountWithPictureBlock">
            <div className="countAndLabelTextBlock">
              <Icon type="picture" className="listItemImagesStyle" />
              <p className="listItemText">{props.categoriesCount} categories</p>
            </div>
            <div className="countAndLabelTextBlock">
              <Icon type="picture" className="listItemImagesStyle" />
              <p className="listItemText">{props.keywordsCount} keywords</p>
            </div>
          </div>
        )}
        {props.headerFor === "category" && (
          <div className="itemCountWithPictureBlock">
            <div className="countAndLabelTextBlock">
              <Icon type="picture" className="listItemImagesStyle" />
              <p className="listItemText">{props.keywordsCount} keywords</p>
            </div>
          </div>
        )}
      </div>
    </>
  );
};

export default HeaderForPanel;
