import React from "react";
import { Radio, Select } from "formik-antd";
import { FieldWrap } from "./styled";
import DataSource from "./FormItems/AutoComplete";

const { Option } = Select;

const CustomField = ({ index, data, prefix, isAutocomplete, findBy }) => {
  return (
    <FieldWrap>
      <Radio.Group
        name={`${prefix}_${index}.radio`}
        options={[
          { label: "Add", value: "add" },
          { label: "Delete", value: "delete" }
        ]}
      />
      {isAutocomplete ? (
        <DataSource
          name={`${prefix}_${index}.select`}
          findBy={findBy}
          iniData={data}
        />
      ) : (
        <Select name={`${prefix}_${index}.select`} allowClear>
          {data.map(i => (
            <Option value={i.id} key={i.id}>
              {prefix === "dataSource" ? i.value : i.name}
            </Option>
          ))}
        </Select>
      )}
    </FieldWrap>
  );
};
export default CustomField;
