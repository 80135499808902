import { Form as _Form } from "formik-antd";
import styled from "styled-components";

export const Form = styled(_Form)`
  width: 844px;
  margin: 0 auto;
  text-align: left;
  padding: 48px 0;

  .bigger .ant-form-item-label,
  h3 {
    font-weight: bold;
    margin-bottom: 5px;

    font-size: 18px;
    line-height: 21px;
    color: #4a5360;
  }
  .bigger .ant-form-item-label label {
    font-size: 18px;
  }
  h3 {
    padding-bottom: 16px;
  }
  .ant-btn-link {
    padding-left: 0;
    margin-bottom: 16px;
  }
`;
export const RadioLabel = styled.div`
  &:before {
    display: block;
    content: "";
    height: 26px;
  }
`;
export const FieldWrap = styled.div`
  display: flex;
  align-items: center;
  padding-bottom: 16px;
  .ant-radio-group {
    display: flex;
  }
  .ant-form-explain {
    display: none;
  }
  .ant-form-item-label {
    line-height: 16px;
    padding-bottom: 10px;
  }
  .ant-form-item {
    margin-bottom: 0;
    flex: 1;
    margin-left: 16px;
    &:first-child {
      margin-left: 0;
    }
  }
`;
export const Buttons = styled.div`
  display: flex;
  justify-content: center;
  padding-top: 32px;
  > * {
    margin: 0 16px;
    flex: none;
    width: 233px;
  }
`;
