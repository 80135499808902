import React, { useState, useEffect } from "react";
import { Form, Button, Input } from "antd";

const YearEstablished = props => {
  const [year, setYear] = useState(null);

  useEffect(() => {
    if (props.initialData) {
      setYear(props.initialData);
    }
  }, [props.initialData]);

  const handleSubmit = e => {
    e.preventDefault();
    if (year) {
      const data = { year_established: year };
      props.handleSetValues(data);
      props.handleCloseModalWindow();
    }
  };

  return (
    <Form onSubmit={handleSubmit}>
      <Form.Item label="Year established">
        <Input
          value={year}
          onChange={e => {
            setYear(e.target.value);
          }}
        />
      </Form.Item>
      <Form.Item>
        <Button
          className="submitFormButtonInputForm"
          type="primary"
          htmlType="submit"
        >
          Submit
        </Button>
      </Form.Item>
    </Form>
  );
};

const WrappedDynamicFieldSet = Form.create({ name: "year_established" })(
  YearEstablished
);
export default WrappedDynamicFieldSet;
