import * as React from "react";
import { Formik } from "formik";
import FormView from "./FormView";
import { useHistory } from "react-router-dom";
import { addUser } from "../../../services/auth";
import { useContext } from "react";
import { AuthContext } from "../../../controllers/Auth";

const iniValues = {
  submitError: "",
  company: "",
  first_name: "",
  last_name: "",
  email: "",
  password: ""
};
const validate = values => {
  const errors = {};
  if (!values.email) {
    errors.email = "Required";
  } else if (!/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i.test(values.email)) {
    errors.email = "Invalid email address";
  }
  if (!values.password) errors.password = "This field is required";
  if (!values.first_name) errors.first_name = "This field is required";
  if (!values.last_name) errors.last_name = "This field is required";
  if (!values.company) errors.company = "This field is required";
  return errors;
};

const CreateUser = props => {
  const history = useHistory();
  const { user } = useContext(AuthContext);

  if (!["super_admin", "admin"].includes(user?.role?.name)) {
    history.go(-1);
  }
  async function handleOnSubmit(values, actions) {
    addUser({
      first_name: values.first_name,
      last_name: values.last_name,
      email: values.email,
      password: values.password,
      company_id: values.company.key
    })
      .then(res => {
        history.push(`/${props.lang}/users`);
      })
      .catch(e => {
        actions.setFieldError(
          "submitError",
          e.response?.data?.message || "Error"
        );
      });
  }

  return (
    <div>
      <h1>Create user</h1>
      <Formik
        initialValues={iniValues}
        onSubmit={handleOnSubmit}
        render={renderProps => <FormView {...renderProps} {...props} />}
        validate={validate}
      />
    </div>
  );
};
export default CreateUser;
