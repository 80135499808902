import React from "react";
import PropTypes from "prop-types";
import intl from "react-intl-universal";
import { Button, Icon, List } from "antd";
import LisItem from "../UI/LisItem";
import "./IndustryList.scss";

const IndustryList = props => {
  return (
    <React.Fragment>
      <div className="industryPageWrapper">
        <div>
          <List
            className="listOfSegment"
            itemLayout="horizontal"
            dataSource={props.industryList}
            renderItem={item => (
              <LisItem
                key={item.id}
                handleDeleteItem={props.removeIndustryItem}
                listItem={item}
                goToNext={props.goToNext}
                selectedItem={props.choosedIndustryItemId}
                selectListItem={props.chooseIndustryItem}
                handleEditForm={props.updateIndustryItem}
                title={intl.get("categoryEditItemModalWindowTitle")}
                updateItem={props.updateIndustryItem}
                inputPlaceHolder={intl.get(
                  "categoryEditItemNodalWindowInputPlaceholder"
                )}
              >
                {item.name}
              </LisItem>
            )}
          />
        </div>
        <Button
          type="primary"
          size={"large"}
          className="addNewIndustryButton"
          onClick={props.openModalWindowForAdd}
        >
          <Icon type="plus-circle" className="iconAddONButton" />
          {intl.get("addIndustry")}
        </Button>
      </div>
    </React.Fragment>
  );
};

IndustryList.propTypes = {
  industryList: PropTypes.arrayOf(PropTypes.any),
  choosedIndustryItemId: PropTypes.string,
  chooseIndustryItem: PropTypes.func.isRequired,
  openModalWindowForAdd: PropTypes.func.isRequired,
  removeIndustryItem: PropTypes.func.isRequired,
  updateIndustryItem: PropTypes.func.isRequired,
  goToNext: PropTypes.func.isRequired
};

IndustryList.defaultProps = {
  industryList: [],
  choosedIndustryItemId: ""
};

export default IndustryList;
