import React from "react";
import { Form, Select, Icon, Button } from "antd";
import {
  getIndustryList,
  getSegmentForCurrentIndustry
} from "../../services/api";

let id = 0;

class AddIndustryFormCompanyView extends React.Component {
  state = {
    industryListItems: [],
    segmentListItems: []
  };
  componentDidMount = async () => {
    const { resetFields } = this.props.form;
    try {
      resetFields();
      id = 0;
      const getAllIndustryListItems = await getIndustryList();
      this.setState({
        industryListItems: getAllIndustryListItems.items || []
      });
      if (this.props.initialData && Array.isArray(this.props.initialData)) {
        for (let index = 0; index < this.props.initialData.length; index++) {
          this.add();
          const segmentList = `segmentListItems${index}`;
          const getAllSegmentList = await getSegmentForCurrentIndustry(
            this.props.initialData[index].id
          );
          this.setState({
            [segmentList]: getAllSegmentList.data.items || []
          });
        }
      }
    } catch (error) {
      console.log(error);
    }
  };
  remove = k => {
    const { form } = this.props;
    const keys = form.getFieldValue("keys");
    if (keys.length === 1) {
      return;
    }
    form.setFieldsValue({
      keys: keys.filter(key => key !== k)
    });
  };

  add = () => {
    const { form } = this.props;
    const keys = form.getFieldValue("keys");
    const nextKeys = keys.concat(id++);
    form.setFieldsValue({
      keys: nextKeys
    });
  };

  handleSubmit = e => {
    e.preventDefault();
    this.props.form.validateFields((err, values) => {
      if (!err) {
        const { keys, names } = values;
        const data = {
          industries: keys.map(key => {
            const item = {
              id: Array.isArray(names[key].id)
                ? names[key].id.join()
                : names[key].id,
              segments: names[key].segments
            };
            return item;
          })
        };
        this.props.handleSetValues(data);
        this.props.handleCloseModalWindow();
      }
    });
  };
  getSegmentsListItems = async (industryId, index) => {
    try {
      const segmentList = `segmentListItems${index}`;
      const getAllSegmentList = await getSegmentForCurrentIndustry(industryId);
      this.setState({
        [segmentList]: getAllSegmentList.data.items || []
      });
    } catch (error) {
      console.log(error);
    }
  };
  handleChangeIndustry = (value, k) => {
    this.getSegmentsListItems(value, k);
  };

  render() {
    const { industryListItems } = this.state;
    const { getFieldDecorator, getFieldValue } = this.props.form;
    getFieldDecorator("keys", { initialValue: [] });
    const keys = getFieldValue("keys");
    const formItems = keys.map((k, index) => (
      <Form.Item required={false} key={k}>
        {getFieldDecorator(`names[${k}].id`, {
          validateTrigger: ["onChange", "onBlur"],
          rules: [],
          initialValue: [
            this.props.initialData && this.props.initialData[k]
              ? this.props.initialData[k].id
              : undefined
          ]
        })(
          <Select
            className="arrayInputComponent"
            style={{ width: "100%" }}
            onChange={value => {
              this.handleChangeIndustry(value, k);
            }}
          >
            {industryListItems.map(item => (
              <Select.Option key={item.id}>{item.name}</Select.Option>
            ))}
          </Select>
        )}
        {keys.length > 1 ? (
          <Icon
            className="removeStringIconButton"
            type="minus-circle-o"
            onClick={() => this.remove(k)}
          />
        ) : null}
        {getFieldDecorator(`names[${k}].segments`, {
          validateTrigger: ["onChange", "onBlur"],
          rules: this.props.validationRules,
          initialValue:
            this.props.initialData &&
            this.props.initialData[k] &&
            this.props.initialData[k].segments
              ? this.props.initialData[k].segments
              : undefined
        })(
          <Select mode="multiple" className="arrayInputComponent">
            {this.state[`segmentListItems${k}`] &&
              this.state[`segmentListItems${k}`].map(item => (
                <Select.Option key={item.id} value={Number(item.id)}>
                  {item.name}
                </Select.Option>
              ))}
          </Select>
        )}
      </Form.Item>
    ));
    return (
      <Form onSubmit={this.handleSubmit}>
        {formItems}
        <Form.Item>
          <Button
            className="submitButtonOnForm"
            type="dashed"
            onClick={this.add}
          >
            <Icon type="plus" /> Add field
          </Button>
        </Form.Item>
        <Form.Item>
          <Button
            className="submitButtonOnForm"
            type="primary"
            htmlType="submit"
          >
            Submit
          </Button>
        </Form.Item>
      </Form>
    );
  }
}

const WrappedDynamicFieldSet = Form.create({ name: "dynamic_form_item" })(
  AddIndustryFormCompanyView
);
export default WrappedDynamicFieldSet;
