/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useState, useEffect, useCallback } from "react";
import { Breadcrumb } from "antd";
import { useHistory } from "react-router-dom";
import {
  getIndustryItem,
  getSegmentItemById,
  getCategoryItem
} from "../../services/api";

const initialData = {
  industryItem: null,
  segmentItem: null,
  categoryItem: null,
  keywords: null
};
const BreadcrumbComponent = ({ lang, matchParam, currentPlace }) => {
  const [state, setState] = useState(initialData);
  const history = useHistory();
  const getIndustryCurrentItem = useCallback(
    async industryId => {
      const getIndustryItemFromServer = await getIndustryItem(industryId);
      setState(state => {
        return {
          ...state,
          industryItem: getIndustryItemFromServer || []
        };
      });
    },
    [setState]
  );
  const getSegmentItemFromDB = useCallback(
    async segmentId => {
      const getSegmentCurrentItem = await getSegmentItemById(segmentId);
      setState(state => {
        return {
          ...state,
          segmentItem: getSegmentCurrentItem.data || []
        };
      });
    },
    [setState]
  );
  const getCategoryItemFromDB = useCallback(
    async catId => {
      const getItemFromDB = await getCategoryItem(catId);
      setState(state => {
        return {
          ...state,
          categoryItem: getItemFromDB.data || []
        };
      });
    },
    [setState]
  );
  useEffect(() => {
    if (matchParam.params.industryId) {
      getIndustryCurrentItem(matchParam.params.industryId);
    }
    if (matchParam.params.segmentId) {
      getSegmentItemFromDB(matchParam.params.segmentId);
    }
    if (matchParam.params.categoryId) {
      getCategoryItemFromDB(matchParam.params.categoryId);
    }
  }, [
    getCategoryItemFromDB,
    getIndustryCurrentItem,
    getSegmentItemFromDB,
    matchParam
  ]);
  return (
    <>
      <Breadcrumb className="breadCrumbsComponent">
        {state.industryItem && (
          <Breadcrumb.Item>
            <a
              onClick={() => {
                history.push(`/${lang}/typology`);
              }}
            >
              {state.industryItem ? state.industryItem.name : ""}
            </a>
          </Breadcrumb.Item>
        )}
        {state.segmentItem && (
          <Breadcrumb.Item>
            <a
              onClick={() => {
                history.push(
                  `/${lang}/typology/${matchParam.params.industryId}/segment`
                );
              }}
            >
              {state.segmentItem ? state.segmentItem.name : ""}
            </a>
          </Breadcrumb.Item>
        )}
        {state.categoryItem && (
          <Breadcrumb.Item>
            <a
              onClick={() => {
                history.push(
                  `/${lang}/typology/${matchParam.params.industryId}/segment/${matchParam.params.segmentId}/category`
                );
              }}
            >
              {state.categoryItem ? state.categoryItem.name : ""}
            </a>
          </Breadcrumb.Item>
        )}
        <Breadcrumb.Item>
          <a onClick={() => {}}>{currentPlace}</a>
        </Breadcrumb.Item>
      </Breadcrumb>
    </>
  );
};

export default BreadcrumbComponent;
