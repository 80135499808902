import { useEffect, useState } from "react";

import { autocompleteGetItems } from "../../services/api";

function useIniData() {
  const [loading, setLoading] = useState(true);
  const [data, setData] = useState();

  useEffect(() => {
    Promise.all([
      autocompleteGetItems({
        model: "qualification",
        name: "",
        limit: 999
      }),
      autocompleteGetItems({
        model: "lead_priority",
        name: "",
        limit: 999
      }),
      autocompleteGetItems({
        model: "lead_phase",
        name: "",
        limit: 999
      }),
      autocompleteGetItems({
        model: "lead_statuses",
        name: "",
        limit: 999
      })
    ])
      .then(res => {
        setData({
          qualification: res[0].data.items,
          lead_priority: res[1].data.items,
          lead_phase: res[2].data.items,
          lead_statuses: res[3].data.items
        });
        setLoading(false);
      })
      .catch(err => console.error({ err }));
  }, []);
  return [data, loading];
}

export default useIniData;
