import Axios from "axios";
import config from "../config.json";

let axios = Axios.create({
  baseURL: config.api,
  timeout: 15000
});

export const setup = (accessToken, tryUpdateTokens) => {
  axios = Axios.create({
    baseURL: config.api,
    timeout: 15000,
    headers: { Authorization: `Bearer ${accessToken}` }
  });
  axios.interceptors.response.use(
    response => response,
    async error => {
      const status = error.response ? error.response.status : null;
      if (status === 401) {
        const accessToken = await tryUpdateTokens();
        if (accessToken) {
          error.config.headers["Authorization"] = `Bearer ${accessToken}`;
          return axios.request(error.config);
        }
      }
      return Promise.reject(error);
    }
  );
};

//language end-points
export const getUserList = async (limit, offset) => {
  const res = await axios.get(`/users?offset=${offset}&limit=${limit}`, {
    baseURL: config.auth
  });
  return res.data;
};

//language end-points
export const getLanguageList = async () => {
  const res = await axios.get(`/languages`);
  return res.data;
};

export const addLanguageToDB = async data => {
  const res = await axios.post(`/languages/create`, data);
  return res;
};
export const removeLanguageItemFromDB = async id => {
  const res = await axios.delete(`/languages/delete/${id}`);
  return res;
};

export const getLanguageItemById = async id => {
  const res = await axios.get(`/languages/${id}`);
  return res.data;
};

//data-source end-points
export const getDataSources = async params => {
  const res = await axios.get(`/datasources`, { params: params });
  return res.data;
};

export const addDataSource = async data => {
  const res = await axios.post(`/datasources/create`, data);
  return res;
};

export const removeDataSource = async id => {
  const res = await axios.delete(`/datasources/delete/${id}`);
  return res;
};

export const getDataSourceItem = async id => {
  const res = await axios.get(`/datasources/${id}`);
  return res;
};

//Industry end-points
export const getIndustryList = async () => {
  const res = await axios.get(`/industries`);
  return res.data;
};

export const getIndustryItem = async id => {
  const res = await axios.get(`/industries/${id}`);
  return res.data;
};

export const addNewIndustry = async data => {
  const res = await axios.post(`/industries/create`, data);
  return res;
};

export const updateIndustryItem = async data => {
  const res = await axios.patch(`/industries/edit`, data);
  return res;
};

export const removeIndustry = async id => {
  const res = await axios.delete(`/industries/delete/${id}`);
  return res;
};

//segment api's
export const getSegmentForCurrentIndustry = async id => {
  const res = await axios.get(`/segments/${id}`);
  return res;
};

export const addNewSegment = async data => {
  const res = await axios.post(`/segments/create`, data);
  return res;
};

export const deleteSegmentItem = async id => {
  const res = await axios.delete(`/segments/delete/${id}`);
  return res;
};

export const updateSegmentItem = async data => {
  const res = await axios.patch(`/segments/edit`, data);
  return res;
};

export const getSegmentItemById = async id => {
  const res = await axios.get(`/segments/0/${id}`);
  return res;
};

//category api's
export const getAllCategories = async id => {
  const res = await axios.get(`/categories/${id}`);
  return res;
};

export const addNewCategory = async data => {
  const res = await axios.post(`/categories/create`, data);
  return res;
};

export const deleteCategoryItem = async id => {
  const res = await axios.delete(`/categories/delete/${id}`);
  return res;
};

export const updateCategoryItem = async (id, data) => {
  const res = await axios.patch(`/categories/edit/${id}`, data);
  return res;
};

export const getCategoryItem = async id => {
  const res = await axios.get(`/categories/0/${id}`);
  return res;
};

//keyword api's
export const getAllKeywordGroup = async (id, limit, offset) => {
  const res = await axios.get(`/keywordGroups/${id}`, {
    params: {
      limit: limit,
      offset: offset
    }
  });
  return res;
};

export const searchAllKeywordGroup = async (id, name) => {
  const res = await axios.get(`/keywordGroups/search/${id}`, {
    params: {
      name: name
    }
  });
  return res;
};

export const addNewKeywordGroup = async data => {
  const res = await axios.post(`/keywordGroups/create`, data);
  return res;
};

export const updateKeyworGroupdItem = async (id, data) => {
  const res = await axios.patch(`/keywordGroups/edit/${id}`, data);
  return res;
};

export const deleteKeywordGroupItem = async id => {
  const res = await axios.delete(`/keywordGroups/delete/${id}`);
  return res;
};

export const changeCaseInChosenKeyword = async (id, data) => {
  const res = await axios.patch(`/keywordGroups/caseSensitive/${id}`, data);
  return res;
};

export const globalKeywordsSearch = async (name, limit, offset) => {
  const res = await axios.get(`/keywordGroups/es/search`, {
    params: {
      name: name,
      limit: limit,
      offset: offset
    }
  });
  return res;
};

//synonyms api's
export const getAllSynonyms = async id => {
  const res = await axios.get(`/keywords/${id}`);
  return res;
};

export const addNewSynonym = async data => {
  const res = await axios.post(`/keywords/create`, data);
  return res;
};

export const deleteSynonymItem = async id => {
  const res = await axios.delete(`/keywords/delete/${id}`);
  return res;
};

export const updateSynonymItem = async (id, data) => {
  const res = await axios.patch(`/keywords/edit/${id}`, data);
  return res;
};

//companies api's
export const createNewCompanyProfile = async data => {
  const res = await axios.post(`/companies/create`, data);
  return res;
};

export const getCompaniesList = async data => {
  const res = await axios.get(
    `/companies?offset=${data.offset}&limit=${data.limit}`
  );
  return res;
};

export const getChosenCompanyProfile = async id => {
  const res = await axios.get(`/companies/${id}`);
  return res;
};

export const getCompanyTopologyList = async id => {
  const res = await axios.get(`/companies/keywordGroup/${id}`);
  return res;
};

export const confirmCompanyItemById = async id => {
  const res = await axios.get(`/companies/confirmed/${id}`);
  return res;
};

export const deleteCompanyItem = async id => {
  const res = await axios.delete(`/companies/delete/${id}`);
  return res;
};

export const companyProfileUpdate = async (id, data) => {
  const res = await axios.patch(`/companies/edit/${id}`, data);
  return res;
};

export const filtersForCompanies = async (filters, data) => {
  const res = await axios.post(
    `/companies/es?offset=${data.offset}&limit=${data.limit}`,
    filters
  );
  return res;
};

export const getWordsForCurrentCompany = async (id, data) => {
  const res = await axios.get(
    `/companies/keywordGroup/${id}/words?offset=${data.offset}&limit=${data.limit}`
  );
  return res;
};

export const getTopWordsForCurrentCompany = async id => {
  const res = await axios.get(`/companies/keywordGroup/${id}/top`);
  return res;
};

export const getIndustryListForCompanyById = async companyId => {
  const res = await axios.get(`/companies/industries/${companyId}`);
  return res;
};

export const getSegmentListForCompanyByIds = async (companyId, industryId) => {
  const res = await axios.get(`/companies/segments/${companyId}/${industryId}`);
  return res;
};

export const getCategoriesListItemsForCompanyByIds = async (
  companyId,
  segmentId
) => {
  const res = await axios.get(
    `/companies/categories/${companyId}/${segmentId}`
  );
  return res;
};

export const getKeywordsListForCompanyByIds = async (companyId, categoryId) => {
  const res = await axios.get(
    `/companies/keywordGroups/${companyId}/${categoryId}`
  );
  return res;
};

export const removeKeywordItemFromCompany = async id => {
  const res = await axios.delete(`/companies/keywordGroup/delete/${id}`);
  return res;
};

export const addWordItemToCompanyKeywords = async (wordId, body) => {
  const res = await axios.patch(
    `/companies/keywordGroup/transfer/${wordId}`,
    body
  );
  return res;
};

export const addKeywordGroupToCompany = async body => {
  const res = await axios.post(`/companies/keywordGroup/add`, body);
  return res;
};
export const deleteCompanies = async body => {
  const res = await axios.delete("/companies/bulkDelete", body);
  return res;
};

export const runScraper = async body => {
  const res = await axios.post(`/companies/scrape`, body);
  return res;
};

export const updateCompaniesKeywordGroup = async (id, data) => {
  const res = await axios.patch(`/companies/keywordGroup/edit/${id}`, data);
  return res;
};

export const updateCompaniesBulkChange = async data => {
  const res = await axios.post(`/companies/bulkChange`, data);
  return res;
};

//business model
export const getBusinessModelList = async () => {
  const res = await axios.get(`/models`);
  return res;
};

export const getBusinessModelItemById = async id => {
  const res = await axios.get(`/models/${id}`);
  return res;
};

//business type
export const getBusinessTypeList = async () => {
  const res = await axios.get(`/types`);
  return res;
};
export const getBusinessTypeItemById = async id => {
  const res = await axios.get(`/types/${id}`);
  return res;
};

//business size
export const getBusinessSizeList = async () => {
  const res = await axios.get(`/sizes`);
  return res;
};

export const getBusinessSizeItemById = async id => {
  const res = await axios.get(`/sizes/${id}`);
  return res;
};

//countries api's
export const getCountriesList = async () => {
  const res = await axios.get(`/countries`);
  return res;
};

export const getCountryById = async id => {
  const res = await axios.get(`/countries/${id}`);
  return res;
};

//reports api's
export const getReports = async data => {
  const res = await axios.get(
    `/reports?offset=${data.offset}&limit=${data.limit}`
  );
  return res;
};

export const createReports = async data => {
  const res = await axios.post(`/reports/create`, data);
  return res;
};

export const copyReportApi = async (id, data) => {
  const res = await axios.post(`/reports/copy/${id}`, data);
  return res;
};

export const deleteReport = async id => {
  const res = await axios.delete(`/reports/delete/${id}`);
  return res;
};

export const getReportDetails = async data => {
  const res = await axios.get(`/reports/details/${data}`);
  return res;
};

export const confirmCompanyProfile = async data => {
  const res = await axios.get(`/reports/profile/${data}/confirm`);
  return res;
};

export const createProspect = async data => {
  const res = await axios.post(`/prospects/create`, data);
  return res;
};

export const updateProspect = async data => {
  const res = await axios.patch(`/prospects/update`, data);
  return res;
};

export const matchCompanies = async (id, data) => {
  const res = await axios.post(`/reports/match/${id}`, data);
  return res;
};

export const getTopWordsForCurrentTaxonomy = async id => {
  const res = await axios.get(`/taxonomies/top/${id}`);
  return res;
};

export const getWordsForCurrentTaxonomy = async id => {
  const res = await axios.get(`/taxonomies/${id}/words`);
  return res;
};

export const removeKeywordItemFromTaxonomy = async id => {
  const res = await axios.delete(`/taxonomies/delete/${id}`);
  return res;
};

export const editKeywordItemInTaxonomy = async (id, data) => {
  const res = await axios.patch(`/taxonomies/edit/${id}`, data);
  return res;
};

export const addKeywordItemInTaxonomy = async body => {
  const res = await axios.post(`/taxonomies/add`, body);
  return res;
};

export const getIndustryListForTaxonomyById = async id => {
  const res = await axios.get(`/taxonomies/industries/${id}`);
  return res;
};

export const getSegmentListForTaxonomyByIds = async (
  report_id,
  industry_id
) => {
  const res = await axios.get(
    `/taxonomies/segments/${report_id}/${industry_id}`
  );
  return res;
};

export const getCategoriesListItemsForTaxonomyByIds = async (
  report_id,
  segment_id
) => {
  const res = await axios.get(
    `/taxonomies/categories/${report_id}/${segment_id}`
  );
  return res;
};

export const addWordItemToTaxonomyKeywords = async (taxonomy_id, data) => {
  const res = await axios.patch(`/taxonomies/transfer/${taxonomy_id}`, data);
  return res;
};

export const getKeywordsListForTaxonomyByIds = async (
  report_id,
  category_id
) => {
  const res = await axios.get(
    `/taxonomies/keywordGroups/${report_id}/${category_id}`
  );
  return res;
};

export const confirmTaxonomy = async id => {
  const res = await axios.get(`/reports/taxonomy/${id}/confirm`);
  return res;
};

export const getReportCompaniesList = async (id, data) => {
  const res = await axios.get(
    `/reports/${id}?offset=${data.offset}&limit=${data.limit}`
  );
  return res;
};

export const saveCustomFieldOnReport = async (id, data) => {
  const res = await axios.patch(`/reports/edit/${id}`, data);
  return res;
};

//autocomplete api's
export const autocompleteGetItems = async data => {
  const res = await axios.get(
    `/autocomplete/${data.model}/${data.parent_id || ""}${
      data.name || data.limit ? "?" : ""
    }${data.name ? `name=${data.name}&` : ""}${
      data.limit ? `limit=${data.limit}&` : ""
    }`
  );
  return res;
};

export const autocompleteGetItemsWithParentId = async data => {
  const res = await axios.post(
    `/autocomplete/${data.model}/${data.name || data.limit ? "?" : ""}${
      data.name ? `name=${data.name}&` : ""
    }${data.limit ? `limit=${data.limit}&` : ""}`,
    { parent_id: data.parent_id }
  );
  return res;
};

//product type api's

export const getProductTypeItem = async (id, data) => {
  const res = await axios.get(`/productType/${id}`);
  return res;
};

//logs api's
export const getExcelLogs = async (limit, offset) => {
  const res = await axios.post(`/logs`, {
    params: {
      limit: limit,
      offset: offset
    }
  });
  return res;
};

//logs api's
export const getExcelLogsItem = async (id, limit, offset) => {
  const res = await axios.post(`/logs/${id}?offset=${offset}&limit=${limit}`);
  return res;
};

export const updateReportAccess = async (id, body) => {
  const res = await axios.post(`/reports/access/${id}`, body);
  return res;
};
