import React, { useState, useEffect, useCallback } from "react";
import intl from "react-intl-universal";
import { Layout, Spin, Table, Button, Icon } from "antd";
import {
  getReportCompaniesList,
  getCompaniesList,
  filtersForCompanies,
  deleteCompanyItem,
  deleteCompanies,
  updateCompaniesBulkChange,
  companyProfileUpdate
} from "../../services/api";
import { exportAllCompaniesWithFilter } from "../../services/importExportApi";
import ErrorNotification from "../../components/UI/Notifications/ErrorNotification";
import SuccessNotification from "../../components/UI/Notifications/SuccessNotification";
import CompaniesButtonsBlock from "./CompaniesButtonsBlock";
import CompanyFiltersBlock from "./CompanyFiltersBlock";
import ModalWindow from "../../components/UI/ModalWindow";
import BulkChanges from "./BulkChanges";
import "./Companies.scss";
import isEmpty from "lodash.isempty";
import { Content, Select } from "./styled";
import moment from "moment";
import useIniData from "./initialDataHook";
const { Option } = Select;
const { Sider } = Layout;

const companiesState = {
  companiesList: [],
  inited: false,
  selectedRowKeys: [],
  rowSelected: [],
  totalItemsInDB: 0,
  companyFilters: {
    companyFilter: {}
  },
  fullTextSearchInputValue: "",
  deleteCompanyId: "",
  openDeleteCompanyWindow: false,
  nav: { offset: 0, limit: 10 }
};
const companyFiltersState = {
  companyFilter: {
    business_model: {
      value: [],
      rule: ""
    },
    business_type: {
      value: [],
      rule: ""
    },
    size: { value: {} },
    country: {
      value: []
    },
    industries: {},
    segments: {},
    categories: {},
    keyword_groups: {},
    data_source: {},
    states: {},
    employees_count: { value: {} },
    keyword_groups_total: { value: {} },
    confirmed: {},
    year_established: { value: {} },
    product_type: { value: [] }
  }
};

const CompaniesContainer = props => {
  const [state, setState] = useState({ ...companiesState });
  const [iniData, loading] = useIniData();
  const [areFiltersApplied, setFiltersApplied] = useState(false);
  const [companyFilters, setCompanyFilters] = useState(companyFiltersState);
  const [sort, setSort] = useState({});
  const [bulkChangesType, setBulkChangesType] = useState(false);
  const updateCompaniesList = async nav => {
    try {
      const companiesList =
        props.type !== "report"
          ? await getCompaniesList(nav)
          : await getReportCompaniesList(props.id, nav);
      setState(state => {
        return {
          ...state,
          companiesList:
            companiesList.data.items.map(x => {
              if (props.type === "report") {
                return { ...x, ...x.company };
              }
              return x;
            }) || [],
          totalItemsInDB: companiesList.data.total || 0,
          inited: true
        };
      });
    } catch (error) {
      ErrorNotification({ text: error });
    }
  };

  const rowSelection = {
    onChange: selectedRowKeys => {
      setState(state => ({
        ...state,
        rowSelected: selectedRowKeys
      }));
    }
  };

  const handleClose = useCallback(() => {
    setState(s => {
      return {
        ...s,
        deleteCompanyId: "",
        openDeleteCompanyWindow: false
      };
    });
  }, [setState]);

  const handleDeleteCompanyItem = useCallback(async () => {
    try {
      await deleteCompanyItem(state.deleteCompanyId);
      SuccessNotification({
        text: intl.get("successMessageForNotificationWindow")
      });
      updateCompaniesList(state.nav);
      handleClose();
    } catch (error) {
      ErrorNotification({ text: error });
    }
  }, [handleClose, state.deleteCompanyId, state.nav, updateCompaniesList]);

  async function handleUpdateCompanyFilters(
    companyFiltersFormValue,
    nav,
    sort
  ) {
    try {
      const getSearchResultFromES =
        companyFiltersFormValue !== null
          ? await filtersForCompanies(
              {
                ...companyFiltersFormValue.companyFilter,
                sort,
                full_text: { value: state.fullTextSearchInputValue }
              },
              nav
            )
          : await filtersForCompanies(
              {
                sort,
                ...companyFiltersState.companyFilter,
                full_text: { value: "" }
              },
              { offset: 0, limit: state.nav.limit }
            );
      setState(s => ({
        ...s,
        fullTextSearchInputValue:
          companyFiltersFormValue === null ? "" : s.fullTextSearchInputValue,
        nav:
          companyFiltersFormValue === null
            ? { limit: state.nav.limit, offset: 0 }
            : nav,
        companiesList: getSearchResultFromES.data.items,
        totalItemsInDB: getSearchResultFromES.data.total,
        companyFilters: companyFiltersFormValue,
        inited: true
      }));
      setFiltersApplied(!!companyFiltersFormValue);
      setCompanyFilters(
        companyFiltersFormValue ? companyFiltersFormValue : companyFiltersState
      );
    } catch (error) {
      ErrorNotification({ text: error });
    }
  }

  const handleChangeSearchInput = useCallback(
    value => {
      setState(s => {
        return {
          ...s,
          fullTextSearchInputValue: value
        };
      });
    },
    [setState]
  );

  const handleFullTextSearch = async () => {
    const getSearchResultFromES = await filtersForCompanies(
      {
        full_text: { value: state.fullTextSearchInputValue },
        ...companyFilters.companyFilter,
        sort
      },
      state.nav
    );
    setFiltersApplied(true);
    setState(s => {
      return {
        ...s,
        companiesList: getSearchResultFromES.data.items,
        totalItemsInDB: getSearchResultFromES.data.total,
        nav: state.nav
      };
    });
  };

  async function handleExportAllCompaniesByFilter() {
    try {
      if (
        (companyFilters && companyFilters.companyFilter) ||
        state.fullTextSearchInputValue
      ) {
        await exportAllCompaniesWithFilter({
          full_text: { value: state.fullTextSearchInputValue },
          ...companyFilters.companyFilter
        });
      } else {
        await exportAllCompaniesWithFilter(undefined);
      }
    } catch (error) {
      ErrorNotification({ text: error });
    }
  }

  async function handleRemoveAllCompaniesByFilter() {
    try {
      if (
        (companyFilters && companyFilters.companyFilter) ||
        state.fullTextSearchInputValue
      ) {
        await deleteCompanies({
          full_text: { value: state.fullTextSearchInputValue },
          ...companyFilters.companyFilter
        });
      } else {
        await deleteCompanies(undefined);
      }
    } catch (error) {
      ErrorNotification({ text: error });
    }
  }

  useEffect(() => {
    if (props.type === "report") {
      updateCompaniesList(state.nav);
    } else {
      handleUpdateCompanyFilters(companyFilters, state.nav);
    }
  }, []);

  async function handleRowSelectChange(id, type, rowId) {
    try {
      await companyProfileUpdate(rowId, { [`${type}_id`]: id });
      const value = iniData[type].find(i => i.id === id);
      const companies = [...state.companiesList];
      const company = state.companiesList.findIndex(i => i.id === rowId);
      companies[company] = { ...companies[company], [type]: value };
      setState(prev => ({ ...prev, companiesList: companies }));
    } catch (e) {
      ErrorNotification({ text: e });
    }
  }
  function rowSelect(row, id, rowId) {
    return (
      <Select
        value={row?.id || ""}
        onChange={val => handleRowSelectChange(val, id, rowId)}
      >
        <Option value=""> - </Option>
        {iniData?.[id]?.map(i => (
          <Option key={i.id} value={i.id}>
            {i.name}
          </Option>
        ))}
      </Select>
    );
  }

  const columns = [
    {
      title: intl.get("companiesListColumnName"),
      dataIndex: "name"
    },
    {
      title: intl.get("companiesListColumnCompanyLink"),
      dataIndex: "website",
      key: "website",
      className: "companiesWebsiteColumn"
    },
    {
      title: intl.get("companiesListColumnCountry"),
      dataIndex: "addresses",
      key: "addresses",
      render: addressItem => (
        <div className="companiesArrayBlockOnTable">
          {addressItem &&
            addressItem.map(countryItems => (
              <span key={countryItems.id}>
                {countryItems &&
                countryItems.country &&
                countryItems.country.name
                  ? countryItems.country.name
                  : null}
              </span>
            ))}
        </div>
      )
    },
    {
      title: intl.get("companiesListIndustries"),
      dataIndex: "industries",
      key: "industries",
      render: industries => (
        <div className="companiesArrayBlockOnTable">
          {industries &&
            industries.map(indItems => (
              <span key={indItems.id}>{indItems.name}</span>
            ))}
        </div>
      )
    },
    {
      title: intl.get("companiesListSegments"),
      dataIndex: "segments",
      key: "segments",
      className: "companiesSegmentColumn",
      render: segments => (
        <div className="companiesArrayBlockOnTable">
          {segments &&
            segments.map(segmentItems => (
              <span key={segmentItems.id}>{segmentItems.name}</span>
            ))}
        </div>
      )
    },
    {
      title: "Qualification",
      dataIndex: "qualification",
      render: (row, { id }) => rowSelect(row, "qualification", id)
    },
    {
      title: "Lead Priority",
      dataIndex: "lead_priority",
      render: (row, { id }) => rowSelect(row, "lead_priority", id)
    },
    {
      title: "Lead Phase",
      dataIndex: "lead_phase",
      render: (row, { id }) => rowSelect(row, "lead_phase", id)
    },
    {
      title: "Lead status",
      dataIndex: "lead_statuses",
      render: (row, { id }) => rowSelect(row, "lead_statuses", id)
    },
    {
      title: "Date adding a lead",
      dataIndex: "created_at",
      sorter: true,
      render: date => moment(date).format("ll")
    },
    {
      title: intl.get("companiesListViewProfile"),
      dataIndex: "viewProfile",
      key: "viewProfile",
      align: "center",
      render: (text, record) => (
        <span>
          <Icon
            className="companiesListConfirmButton"
            type="eye"
            onClick={() => {
              window.open(`/#/${props.lang}/companyProfile/${record.id}`);
            }}
          />
        </span>
      )
    }
  ];

  if (!state.inited) {
    return (
      <Layout className="layoutForSpinner">
        <Spin size="large" className="spinComponentStyle" />
      </Layout>
    );
  }
  function handleTableChange(pag, filters, sorter) {
    const { current, pageSize } = pag;
    const sort = {};
    const field = sorter.field;
    if (field) {
      if (sorter.order) {
        sort[field] = sorter.order === "descend" ? "desc" : "asc";
      }
      setSort(sort);
      const nav = {
        offset: pageSize * (current - 1),
        limit: pageSize
      };
      setState(s => {
        return { ...s, nav };
      });

      handleUpdateCompanyFilters(companyFilters, nav, sort);
      return;
    }
    setSort(sort);
    setState(s => {
      const nav = {
        offset: pageSize * (current - 1),
        limit: pageSize
      };

      if (areFiltersApplied) {
        handleUpdateCompanyFilters(companyFilters, nav, sort);
      } else updateCompaniesList(nav);

      return { ...s, nav };
    });
  }
  function handleBulkChangesType(type) {
    if (type === "selected" && !state.rowSelected.length) {
      ErrorNotification({ text: "Please select the company" });
      return;
    }
    setBulkChangesType(type);
  }
  async function handleBulkChangesSubmit(data) {
    const _data = { changes: data };
    if (bulkChangesType === "all") {
      if (
        (companyFilters && companyFilters.companyFilter) ||
        state.fullTextSearchInputValue
      ) {
        Object.assign(_data, {
          filter: {
            full_text: { value: state.fullTextSearchInputValue },
            ...companyFilters.companyFilter
          }
        });
      }
    } else if (bulkChangesType === "selected") {
      if (!isEmpty(state.rowSelected)) {
        _data.selected = state.rowSelected;
      }
    }

    handleFullTextSearch();
    setBulkChangesType(undefined);
    setState(state => ({
      ...state,
      rowSelected: []
    }));
    try {
      await updateCompaniesBulkChange(_data);
    } catch (text) {
      ErrorNotification({ text });
    }
  }

  if (bulkChangesType) {
    return (
      <BulkChanges
        onSubmit={handleBulkChangesSubmit}
        onClose={() => setBulkChangesType(undefined)}
        count={
          bulkChangesType === "all"
            ? state.totalItemsInDB
            : state.rowSelected.length
        }
      />
    );
  }
  return (
    <Layout className="removeBackGroundOnLayout">
      <Content>
        <Layout style={{ padding: "24px 0", background: "#fff" }}>
          <Sider
            width={230}
            className="companiesListSidebar"
            style={{
              background: "#fff",
              overflow: "auto",
              height: "calc(100vh - 130px)",
              position: "sticky",
              top: "120px",
              left: 0
            }}
          >
            <CompanyFiltersBlock
              filter={props.type === "report" ? props.filter : undefined}
              editable={props.type !== "report"}
              handleUpdateCompanyFilters={handleUpdateCompanyFilters}
            />
          </Sider>
          <Content>
            <div className="companiesListTableBlock">
              {props.type === "companies" && (
                <CompaniesButtonsBlock
                  lang={props.lang}
                  fullTextSearchInputValue={state.fullTextSearchInputValue}
                  handleExportAllCompaniesByFilter={
                    handleExportAllCompaniesByFilter
                  }
                  handleRemoveAllCompaniesByFilter={
                    handleRemoveAllCompaniesByFilter
                  }
                  handleBulkChanges={handleBulkChangesType}
                  handleChangeSearchInput={handleChangeSearchInput}
                  selectedCompanies={state.rowSelected}
                  handleFullTextSearch={handleFullTextSearch}
                  updateList={handleFullTextSearch}
                />
              )}
              <Table
                onChange={handleTableChange}
                rowSelection={
                  props.type === "companies" ? rowSelection : undefined
                }
                pagination={{
                  position: "bottom",
                  current: state.nav.offset / state.nav.limit + 1,
                  total: state.totalItemsInDB,
                  showSizeChanger: true,
                  pageSizeOptions: ["10", "20", "50", "100"]
                }}
                dataSource={state.companiesList}
                rowKey="id"
                columns={columns}
                footer={() => "Total items: " + state.totalItemsInDB}
              />
            </div>
          </Content>
        </Layout>
        {props.type === "match" && (
          <Button
            className="companiesMatch"
            onClick={() => {
              props.next(companyFilters);
            }}
          >
            {intl.get("matchCompanyList")}
          </Button>
        )}
      </Content>
      <ModalWindow
        title="Delete confirm window"
        open={state.openDeleteCompanyWindow}
        handleSubmit={handleDeleteCompanyItem}
        handleClose={handleClose}
      >
        {intl.get("confirmRemoveCompanyItemText")}
      </ModalWindow>
    </Layout>
  );
};

CompaniesContainer.defaultProps = {
  type: "companies"
};

export default CompaniesContainer;
