import React, { Component } from "react";
import {
  Layout,
  Typography,
  Spin,
  Radio,
  Input,
  Button,
  List,
  Select,
  Form
} from "antd";
import {
  getLanguageList,
  addNewSynonym,
  deleteSynonymItem,
  updateSynonymItem
} from "../../../services/api";
import intl from "react-intl-universal";
import ModalWindow from "../../../components/UI/ModalWindow";
import LisItem from "../../../components/UI/LisItem";
import EditSynonymItem from "./EditSynonymItem";
import ErrorNotification from "../../../components/UI/Notifications/ErrorNotification";
import SuccessNotification from "../../../components/UI/Notifications/SuccessNotification";
import "./Synonyms.scss";

const { Content, Header, Footer } = Layout;

class Synonyms extends Component {
  state = {
    inited: false,
    newSynonymsItemName: "",
    choosedLanguage: null,
    synonymOrTranslation: "synonym",
    sensetiveCase: "sensitive",
    modalForEditSynonymItem: false,
    newSynonymItemName: "",
    editableItem: {}
  };
  componentDidMount = () => {
    this.updateSynonymsList();
  };
  componentWillUnmount = () => {
    this.setState({
      inited: false,
      newSynonymsItemName: "",
      choosedLanguage: null,
      synonymOrTranslation: "synonym",
      sensetiveCase: "sensitive"
    });
  };
  updateSynonymsList = async () => {
    try {
      const { form } = this.props;
      const getLanguegeList = await getLanguageList();
      this.setState({
        inited: true,
        languageList: getLanguegeList.items || [],
        modalForEditSynonymItem: false,
        newSynonymsItemName: ""
      });
      form.resetFields();
    } catch (error) {
      ErrorNotification({ text: error });
    }
  };
  heandleChangeNewSysnonymsNameItem = e => {
    this.setState({
      newSynonymsItemName: e.target.value
    });
  };
  heandleAddNewSynonym = async e => {
    e.preventDefault();
    this.props.form.validateFields((err, values) => {
      if (!err) {
        this.addNewSynonymItem();
      }
    });
  };
  addNewSynonymItem = async () => {
    const { keywordCategory, match, getSynonymsForChosedKeyword } = this.props;
    const {
      choosedLanguage,
      newSynonymsItemName,
      synonymOrTranslation,
      sensetiveCase
    } = this.state;
    const data = {
      name: newSynonymsItemName,
      isSynonym: synonymOrTranslation === "synonym",
      keywordGroupId: Number(keywordCategory),
      keywordGroupCategoryId: Number(match.params.categoryId),
      keywordGroupCategorySegmentId: Number(match.params.segmentId),
      caseSensitive: sensetiveCase === "sensitive",
      languageId: choosedLanguage ? Number(choosedLanguage) : undefined
    };
    try {
      await addNewSynonym(data);
      SuccessNotification({
        text: intl.get("successMessageForNotificationWindow")
      });
      getSynonymsForChosedKeyword();
      this.updateSynonymsList();
    } catch (error) {
      ErrorNotification({ text: error });
    }
  };
  handleRemoveSynonymItem = async item => {
    const { getSynonymsForChosedKeyword } = this.props;
    try {
      await deleteSynonymItem(item.id);
      getSynonymsForChosedKeyword();
      SuccessNotification({
        text: intl.get("successMessageForNotificationWindow")
      });
    } catch (error) {
      ErrorNotification({ text: error });
    }
  };
  onChangeLanguageList = item => {
    this.setState({
      choosedLanguage: item
    });
  };
  handleChangeSynonymOrTranslationRadio = e => {
    this.setState({
      synonymOrTranslation: e.target.value
    });
  };
  handleChangeCase = e => {
    this.setState({
      sensetiveCase: e.target.value
    });
  };
  handleEditForm = () => {
    this.setState({
      modalForEditSynonymItem: true
    });
  };
  selectListItem = itemId => {
    this.setState({
      editableItem: itemId
    });
  };
  handleClose = () => {
    this.setState({
      modalForEditSynonymItem: false
    });
  };
  editSynonymItem = async data => {
    const { getSynonymsForChosedKeyword } = this.props;
    const { editableItem } = this.state;
    try {
      await updateSynonymItem(editableItem.id, data);
      getSynonymsForChosedKeyword();
      this.updateSynonymsList();
      SuccessNotification({ text: intl.get("synonymSuccessUpdateItem") });
    } catch (error) {
      ErrorNotification({ text: error });
    }
  };
  render() {
    const { form, synonymsList, keywordGroupName, onCancel } = this.props;
    const { getFieldDecorator } = form;
    const {
      inited,
      languageList,
      synonymOrTranslation,
      sensetiveCase,
      modalForEditSynonymItem,
      newSynonymItemName,
      editableItem
    } = this.state;
    if (!inited) {
      return (
        <Layout className="layoutForSpinner">
          <Spin size="large" className="spinComponentStyle" />
        </Layout>
      );
    }
    return (
      <Layout>
        <Header className="headerStyle">
          <Typography.Title className="titleForAddSynonyms">
            {intl.get("synonymsModalTitle")} {keywordGroupName}
          </Typography.Title>
        </Header>
        <Content>
          <List
            className="listOfSynonyms"
            itemLayout="horizontal"
            dataSource={synonymsList}
            renderItem={item => (
              <LisItem
                key={item.id}
                synonymItem={true}
                handleDeleteItem={this.handleRemoveSynonymItem}
                listItem={item}
                selectedItem={""}
                selectListItem={this.selectListItem}
                handleEditForm={this.handleEditForm}
              >
                {item.name}
              </LisItem>
            )}
          />
          <div>
            <Form onSubmit={this.heandleAddNewSynonym}>
              <div className="inputBlockForNewSynonym">
                <div className="newSynonymINput">
                  <Form.Item>
                    {getFieldDecorator("synonymName", {
                      rules: [
                        {
                          required: true,
                          message: `${intl.get("synonymFormValidationName")}`
                        }
                      ],
                      initialValue: ""
                    })(
                      <Input
                        placeholder={intl.get("synonymAddItemFiledPlaceholder")}
                        onChange={this.heandleChangeNewSysnonymsNameItem}
                      />
                    )}
                  </Form.Item>
                  <div className="addNewSynonymRadioBlock">
                    <Form.Item>
                      {getFieldDecorator("caseSensetive", {
                        rules: [
                          {
                            required: true,
                            message: `${intl.get(
                              "synonymFormValidationChooseCase"
                            )}`
                          }
                        ],
                        initialValue: sensetiveCase
                      })(
                        <Radio.Group onChange={this.handleChangeCase}>
                          <Radio value={"sensitive"}>
                            {intl.get("synonymRadioCaseSensetive")}
                          </Radio>
                          <Radio value={"unsensitive"}>
                            {intl.get("synonymRadioCaseInsensetive")}
                          </Radio>
                        </Radio.Group>
                      )}
                    </Form.Item>
                    <Form.Item>
                      {getFieldDecorator("itemType", {
                        rules: [
                          {
                            required: true,
                            message: `${intl.get("synonymFormValidationType")}`
                          }
                        ],
                        initialValue: synonymOrTranslation
                      })(
                        <Radio.Group
                          onChange={this.handleChangeSynonymOrTranslationRadio}
                        >
                          <Radio value={"synonym"}>
                            {intl.get("synonymAddNewItemTypeSynonym")}
                          </Radio>
                          <Radio value={"translation"}>
                            {intl.get("synonymAddNewItemTypeTranslation")}
                          </Radio>
                        </Radio.Group>
                      )}
                    </Form.Item>
                  </div>
                </div>
                <Form.Item className="selectCountryForNewsynonym">
                  {getFieldDecorator(
                    "chooseLanguage",
                    {}
                  )(
                    <Select
                      showSearch
                      placeholder={intl.get(
                        "synonymAddNewItemSelectPlaceholder"
                      )}
                      optionFilterProp="children"
                      onChange={this.onChangeLanguageList}
                    >
                      {languageList.map((item, index) => (
                        <Select.Option key={item.id} value={item.id}>
                          {item.name}
                        </Select.Option>
                      ))}
                    </Select>
                  )}
                </Form.Item>
              </div>
              <div className="addNewSynonymButtonBlock">
                <Button
                  type="primary"
                  htmlType="submit"
                  className="addNewSynonymButton"
                  size={"large"}
                >
                  {intl.get("addButtonName")}
                </Button>
              </div>
            </Form>
            <div className="doneButtonContainer">
              <Button
                type="primary"
                onClick={onCancel}
                className="doneButtonForCloseModalWindow"
                size={"large"}
              >
                {intl.get("doneButtonForSynonymModalWindow")}
              </Button>
            </div>
          </div>
        </Content>
        <Footer className="addNewSynonymModalFooter">
          <ModalWindow
            open={modalForEditSynonymItem}
            handleClose={this.handleClose}
            title={intl.get("synonymEditItemModalWindowTitle")}
            footer={null}
          >
            <EditSynonymItem
              handleSubmit={this.editSynonymItem}
              handleClose={this.handleClose}
              languageList={languageList}
              editableItem={editableItem}
              inputValue={newSynonymItemName}
              inputOnchange={this.handleChangeNewNameSynonymItem}
            />
          </ModalWindow>
        </Footer>
      </Layout>
    );
  }
}

export default Form.create({ name: "login" })(Synonyms);
