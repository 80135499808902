import Axios from "axios";
import config from "../config.json";

export const login = credentials =>
  Axios.post(`${config.auth}/login`, credentials);
export const check = accessToken =>
  Axios.post(`${config.auth}/check`, { accessToken });
export const refresh = refreshToken =>
  Axios.post(`${config.auth}/refresh`, { refreshToken });

let axiosAuth = Axios.create({
  baseURL: config.auth,
  timeout: 15000
});

export const setupAuth = (accessToken, tryUpdateTokens) => {
  axiosAuth = Axios.create({
    baseURL: config.auth,
    timeout: 15000,
    headers: { Authorization: `Bearer ${accessToken}` }
  });
  axiosAuth.interceptors.response.use(
    response => response,
    async error => {
      const status = error.response ? error.response.status : null;
      if (status === 401) {
        const accessToken = await tryUpdateTokens();
        if (accessToken) {
          error.config.headers["Authorization"] = `Bearer ${accessToken}`;
          return axiosAuth.request(error.config);
        }
      }
      return Promise.reject(error);
    }
  );
};

export const me = () => axiosAuth.get(`${config.auth}/me`);

export const addUser = async data => {
  const res = await axiosAuth.post(`/register`, data);
  return res;
};
export const removeUser = async id => {
  const res = await axiosAuth.delete(`/delete/${id}`);
  return res;
};
