import React from "react";
import { Layout, Typography } from "antd";
function PageNotFound() {
  return (
    <Layout style={{ height: "100vh" }}>
      <Typography.Title className="pageTitle">
        404 Page Not Found
      </Typography.Title>
    </Layout>
  );
}

export default PageNotFound;
