import React, { useCallback, useState } from "react";
import PropTypes from "prop-types";
import isEmpty from "lodash.isempty";
import { useHistory } from "react-router-dom";
import { Input, Button, Icon, Select } from "antd";
import intl from "react-intl-universal";
import ErrorNotification from "../../../components/UI/Notifications/ErrorNotification";
import { exportCompaniesProfile } from "../../../services/importExportApi";
import { deleteCompanies as deleteCompaniesApi } from "../../../services/api";
import "./CompaniesButtonsBlock.scss";
import { Wrap, Label, SearchField } from "./styled";

const { Option } = Select;

const CompaniesButtonsBlock = props => {
  const { lang } = props;
  const history = useHistory();
  const [loading, setLoading] = useState(false);
  const [activeAction, setAction] = useState();

  const addNewCompanyButton = () => {
    history.push(`/${lang}/addNewCompany`);
  };

  const exportCompanies = async () => {
    setLoading(true);
    try {
      if (!isEmpty(props.selectedCompanies)) {
        const data = {
          selected: props.selectedCompanies.map(i => Number(i))
        };
        await exportCompaniesProfile(data);
      } else {
        console.log("Eroro!");
        ErrorNotification({
          text: intl.get("companiesListExportButtonNoCompaniesSelected")
        });
      }
    } catch (error) {
      ErrorNotification({ text: error });
    }
    setLoading(false);
  };
  const deleteCompanies = async () => {
    setLoading(true);
    try {
      if (!isEmpty(props.selectedCompanies)) {
        const data = {
          selected: props.selectedCompanies.map(i => Number(i))
        };

        await deleteCompaniesApi({ data });
        await new Promise(resolve => setTimeout(resolve, 2000));

        props.updateList();
      } else {
        console.log("Eroro!");
        ErrorNotification({
          text: intl.get("companiesListExportButtonNoCompaniesSelected")
        });
      }
    } catch (error) {
      ErrorNotification({ text: error });
    }
    setLoading(false);
  };

  const handleChangeFullTextSearch = useCallback(
    e => {
      e.preventDefault();
      props.handleChangeSearchInput(e.target.value);
    },
    [props]
  );
  function exportAllCompanies() {
    setLoading(true);
    props.handleExportAllCompaniesByFilter().finally(() => {
      setLoading(false);
    });
  }

  function removeAllCompanies() {
    setLoading(true);
    props.handleRemoveAllCompaniesByFilter().finally(() => {
      setLoading(false);
    });
  }

  function handleTextSearch(e) {
    if (e.key === "Enter") {
      props.handleFullTextSearch();
    }
  }

  function handleChange(value) {
    setAction(value);
  }
  function handleOnActionSubmit() {
    if (!activeAction) return;
    switch (activeAction) {
      case "exportSelected":
        exportCompanies();
        break;
      case "exportAll":
        exportAllCompanies();
      case "deleteSelected":
        deleteCompanies();
        break;
      case "deleteAll":
        removeAllCompanies();
        break;
      case "changeSelected":
        props.handleBulkChanges("selected");
        break;
      case "changeSelectedAll":
        props.handleBulkChanges("all");
        break;
    }
  }

  return (
    <Wrap>
      <Label>
        <p>Actions</p>
        <Select style={{ width: 200 }} onChange={handleChange}>
          <Option value="exportSelected">
            {intl.get("exportCompaniesFile")}
          </Option>
          <Option value="exportAll">
            {intl.get("exportCompaniesAllCompanies")}
          </Option>
          <Option value="changeSelected">Bulk changes to selected</Option>
          {/*<Option value="changeSelectedAll">Bulk changes to all</Option>*/}
          <Option value="deleteSelected" style={{ color: "#EB3B3B" }}>
            {intl.get("deleteSelectedLeads")}
          </Option>
          {/*<Option value="deleteAll" style={{ color: "#EB3B3B" }}>
            {intl.get("deleteAllCompanies")}
          </Option>*/}
        </Select>
        <Button
          className="action__submit"
          onClick={handleOnActionSubmit}
          loading={loading}
          type="primary"
          ghost
        >
          Apply
        </Button>
      </Label>
      <SearchField>
        <Input
          placeholder={intl.get("companiesListSearchInput")}
          onChange={handleChangeFullTextSearch}
          value={props.fullTextSearchInputValue}
          onKeyDown={handleTextSearch}
        />
        <Button
          className="companiesListSearchButton"
          shape="circle"
          onClick={props.handleFullTextSearch}
          type="primary"
          icon="search"
        />
      </SearchField>
      <Button
        type="primary"
        icon="plus"
        className="addCompanyProfileButton"
        onClick={addNewCompanyButton}
      >
        Add lead
      </Button>
    </Wrap>
  );
};

CompaniesButtonsBlock.propTypes = {
  lang: PropTypes.string.isRequired,
  selectedCompanies: PropTypes.array.isRequired,
  fullTextSearchInputValue: PropTypes.string.isRequired
};

export default CompaniesButtonsBlock;
