import { useModal } from "../../../hooks/useModal";
import Modal from "../../../components/UI/ModalWindow/ModalWithHook";
import Provider, { DataContext } from "./Context";
import React, { useContext, useEffect, useRef, useState } from "react";
import { Button, Icon } from "antd";
import { Buttons, Title, Button as Submit } from "./styled";
import Form from "./Form";
import {
  getAvailableField,
  importCompanyFile
} from "../../../services/importExportApi";
import SuccessNotification from "../../../components/UI/Notifications/SuccessNotification";
import ErrorNotification from "../../../components/UI/Notifications/ErrorNotification";

export const IMPORT = "import";

function Details() {
  const ref = useRef();
  const { close, isOpen } = useModal(IMPORT);
  const { data: formData, updateData, reset } = useContext(DataContext);
  const [loading, setLoading] = useState(true);
  const [activeForm, setActiveForm] = useState("create");

  useEffect(() => {
    loadData();
  }, []);

  async function loadData() {
    const res = await getAvailableField();
    updateData({ options: res.data });
    setLoading(false);
  }

  const handleChangeFile = e => {
    e.preventDefault();
    let file = e.target.files[0];
    const formData = new FormData();
    formData.append("file", file);
    sendFileToServer(formData);
  };

  const sendFileToServer = async data => {
    const updateField =
      formData.activeTab === "companies"
        ? Object.entries(formData).reduce((acc, [key, value]) => {
            if (key.startsWith("specific_field")) {
              acc.push(value);
            }
            return acc;
          }, [])
        : [];
    const updateContacts =
      formData.activeTab === "contacts"
        ? Object.entries(formData).reduce((acc, [key, value]) => {
            if (key.startsWith("contact-field")) {
              acc.push(value);
            }
            return acc;
          }, [])
        : [];

    try {
      await importCompanyFile(data, {
        mode: activeForm,
        updateField: updateField.join(","),
        contactUpdateField: updateContacts.join(","),
        matchBy: formData.matchBy
      });
      SuccessNotification({
        text: "Task in progress"
      });
    } catch (error) {
      ErrorNotification({ text: error });
    }
    close();
  };
  function uploadFile() {
    ref.current.dispatchEvent(
      new MouseEvent("click", {
        view: window,
        bubbles: true,
        cancelable: true,
        buttons: 1
      })
    );
  }
  if (!isOpen) return null;
  return (
    <Modal
      name={IMPORT}
      width={572}
      footer={null}
      handleOnClose={() => reset()}
    >
      {loading ? (
        <>loading</>
      ) : (
        <>
          <Title>Import Excel</Title>
          <Buttons>
            <Button
              type={activeForm === "create" ? "primary" : "default"}
              size="large"
              onClick={() => setActiveForm("create")}
            >
              Upload file
            </Button>
            <Button
              type={activeForm === "upgrade" ? "primary" : "default"}
              size="large"
              onClick={() => setActiveForm("upgrade")}
            >
              Update from file
            </Button>
            <Button
              type={activeForm === "override" ? "primary" : "default"}
              size="large"
              onClick={() => setActiveForm("override")}
            >
              Override
            </Button>
          </Buttons>
          {activeForm === "create" && (
            <>
              <Submit type="primary" size="large" onClick={uploadFile}>
                Import file
              </Submit>
            </>
          )}

          {["upgrade", "override"].includes(activeForm) && (
            <Form uploadFile={uploadFile} />
          )}
          <input
            type="file"
            id="importDataSourceFile"
            onChange={handleChangeFile}
            className="inputForChooseFile"
            ref={ref}
          />
        </>
      )}
    </Modal>
  );
}

export default () => (
  <Provider>
    <Details />
  </Provider>
);
