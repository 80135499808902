import React, { Component } from "react";
import intl from "react-intl-universal";
import { Layout, Input, Spin } from "antd";
import IndustryList from "../../components/IndustryList";
import ModalWindow from "../../components/UI/ModalWindow";
import ErrorNotification from "../../components/UI/Notifications/ErrorNotification";
import SuccessNotification from "../../components/UI/Notifications/SuccessNotification";
import {
  getIndustryList,
  addNewIndustry,
  removeIndustry,
  updateIndustryItem
} from "../../services/api";

const { Footer, Content } = Layout;

class Typology extends Component {
  state = {
    choosedIndustryItemId: "",
    industryList: [],
    openAddNewItemModal: false,
    newIndustryItemName: "",
    deletedItem: {},
    showDeleteModalWindow: false,
    enteredDeletedItemValidation: "",
    errorForRemoveInput: "",
    inited: false
  };
  componentDidMount = () => {
    this.updateListIndustry();
  };
  chooseIndustryItem = item => {
    this.setState({
      choosedIndustryItemId: item
    });
  };
  addNewIndustryItem = async () => {
    const { newIndustryItemName } = this.state;
    try {
      if (newIndustryItemName !== "") {
        await addNewIndustry({ name: newIndustryItemName });
        this.handleClose();
        this.updateListIndustry();
        SuccessNotification({
          text: intl.get("successMessageForNotificationWindow")
        });
      } else {
        ErrorNotification({ text: intl.get("validationErrorMessage") });
      }
    } catch (error) {
      ErrorNotification({ text: error });
    }
  };
  heandleNewItemName = e => {
    this.setState({
      newIndustryItemName: e.target.value
    });
  };
  updateIndustryItem = async updatedItem => {
    try {
      this.setState({
        inited: false
      });
      const { choosedIndustryItemId } = this.state;
      const data = {
        id: Number(choosedIndustryItemId),
        name: updatedItem
      };
      await updateIndustryItem(data);
      this.updateListIndustry();
      SuccessNotification({
        text: intl.get("successMessageForNotificationWindow")
      });
    } catch (error) {
      ErrorNotification({ text: error });
    }
  };
  removeIndustryItem = item => {
    this.setState({
      deletedItem: item,
      showDeleteModalWindow: true
    });
  };
  heanldeRemoveIndustryItemInput = e => {
    this.setState({
      enteredDeletedItemValidation: e.target.value
    });
  };
  handleDeleteIndustryItem = async () => {
    const { enteredDeletedItemValidation, deletedItem } = this.state;
    try {
      if (enteredDeletedItemValidation === deletedItem.name) {
        await removeIndustry(deletedItem.id);
        this.updateListIndustry();
        SuccessNotification({
          text: intl.get("successMessageForNotificationWindow")
        });
      } else {
        this.setState({
          errorForRemoveInput: intl.get("removeProtectionValidationMessage")
        });
      }
    } catch (error) {
      ErrorNotification({ text: error });
    }
  };
  updateListIndustry = async () => {
    const getIndustries = await getIndustryList();
    this.setState({
      industryList: getIndustries.items || [],
      openAddNewItemModal: false,
      showDeleteModalWindow: false,
      choosedIndustryItemId: "",
      deletedItem: null,
      errorForRemoveInput: "",
      inited: true
    });
  };
  goToSegment = () => {
    const { history, lang } = this.props;
    const { choosedIndustryItemId } = this.state;
    if (choosedIndustryItemId) {
      history.push(`/${lang}/typology/${choosedIndustryItemId}/segment`);
    }
  };
  opneModalWindow = () => {
    this.setState({
      openAddNewItemModal: true
    });
  };
  handleClose = () => {
    this.setState({
      openAddNewItemModal: false,
      showDeleteModalWindow: false,
      choosedIndustryItemId: null
    });
  };
  render() {
    const {
      choosedIndustryItemId,
      industryList,
      openAddNewItemModal,
      showDeleteModalWindow,
      deletedItem,
      errorForRemoveInput,
      inited
    } = this.state;
    if (!inited) {
      return (
        <Layout className="layoutForSpinner">
          <Spin size="large" className="spinComponentStyle" />
        </Layout>
      );
    }
    return (
      <React.Fragment>
        <Layout className="removeBackGroundOnLayout">
          <Content>
            <IndustryList
              choosedIndustryItemId={choosedIndustryItemId}
              industryList={industryList}
              chooseIndustryItem={this.chooseIndustryItem}
              openModalWindowForAdd={this.opneModalWindow}
              removeIndustryItem={this.removeIndustryItem}
              updateIndustryItem={this.updateIndustryItem}
              goToNext={this.goToSegment}
            />
          </Content>
          <Footer>
            {/*
            Ignore this please couse this feature not approved 
            and client can remove new feature, 
            when we get approve for new feature i delete this block
            <Button
              type="primary"
              size={"large"}
              className="selectFiledStyle"
              onClick={this.goToSegment}
            >
              {intl.get("bottomButtonSelect")}
            </Button>*/}
          </Footer>
        </Layout>
        <ModalWindow
          open={openAddNewItemModal}
          title={intl.get("addNewIndustryItemModalTitle")}
          handleClose={this.handleClose}
          handleSubmit={this.addNewIndustryItem}
        >
          <Input
            placeholder={intl.get("addIndustryModalWindowInputPlaceholder")}
            onChange={this.heandleNewItemName}
          />
        </ModalWindow>
        <ModalWindow
          open={showDeleteModalWindow}
          title={`${intl.get("addIndustryRemoveIndustryModalWorning")} ${
            deletedItem ? deletedItem.name : ""
          }?`}
          handleClose={this.handleClose}
          handleSubmit={this.handleDeleteIndustryItem}
          okText={intl.get("addIndustryModalWindowDeleteSubmitButton")}
          cancelText={intl.get("backButton")}
        >
          <p>{intl.get("addIndustryModalWindowLabelText")}</p>
          <Input
            placeholder={intl.get("removeIndustryModalWindowInputPlaceholder")}
            onChange={this.heanldeRemoveIndustryItemInput}
          />
          {errorForRemoveInput !== "" && (
            <p style={{ color: "#F33" }}>{errorForRemoveInput}</p>
          )}
        </ModalWindow>
      </React.Fragment>
    );
  }
}

export default Typology;
