import React, { useCallback, useState } from "react";
import { Menu, Form, Button } from "antd";

import {
  getCountriesList,
  autocompleteGetItems,
  autocompleteGetItemsWithParentId,
  globalKeywordsSearch
} from "../../../services/api";

import "./CompanyFilters.scss";

import ErrorNotification from "../../../components/UI/Notifications/ErrorNotification";
import SelectWithRadio from "./SelectWithRadioFilter";
import SelectMinMax from "./SelectMinMax";

import { useFormik } from "formik";
import SelectAddressFilter from "./SelectAddressFilter";
import SelectBoolFilter from "./SelectBoolFilter";

const initialState = {
  businessModelData: [],
  businessTypeListItems: [],
  countryListItems: [],
  industryList: [],
  segmentList: [],
  categoriesList: [],
  keywordGroupList: [],
  dataSourceList: [],
  openSubMenu: []
};
const CompanyFiltersBlock = props => {
  const { editable } = props;
  const [state, setState] = useState(initialState);

  const handleChangeSubMenu = useCallback(
    openKeys => {
      let activeSubMenu = [];
      activeSubMenu.push(openKeys[openKeys.length - 1]);
      setState(s => {
        return {
          ...s,
          openSubMenu: activeSubMenu
        };
      });
    },
    [setState]
  );

  const form = useFormik({
    initialValues: {
      business_model: {
        value: [],
        rule: ""
      },
      business_type: {
        value: [],
        rule: ""
      },
      size: { value: {} },
      country: {
        value: []
      },
      industries: {},
      segments: {},
      categories: {},
      keyword_groups: {},
      data_source: {},
      states: {},
      employees_count: { value: {} },
      keyword_groups_total: { value: {} },
      confirmed: {},
      year_established: { value: {} },
      product_type: { value: [] },
      zip: { value: [] }
    },
    onSubmit: values => {
      props.handleUpdateCompanyFilters(
        { companyFilter: { ...form.values } },
        { limit: 10, offset: 0 }
      );
    }
  });

  const resetForm = () => {
    props.handleUpdateCompanyFilters(null);
    form.resetForm();
  };

  const [options, setOptions] = useState({
    states: {},
    zip: []
  });

  const loadFromAutocomplete = async (model, name = "", limit, parent_id) => {
    try {
      const data = await autocompleteGetItems({
        model,
        name,
        limit
      });
      setOptions(opt => ({ ...opt, [model]: data.data.items }));
    } catch (err) {
      ErrorNotification({ text: err });
    }
  };

  const handleGetKeywords = useCallback(
    async (model, name = "", limit, parent_id) => {
      try {
        const keyWordName = name || "";
        if (keyWordName !== "") {
          const getAllKeywordsItems = await globalKeywordsSearch(keyWordName);
          setOptions(opt => ({
            ...opt,
            [model]: getAllKeywordsItems.data.items
          }));
        }
      } catch (error) {
        ErrorNotification({ text: error });
      }
    },
    []
  );

  const loadSegmentsFromAutoComplete = async (
    model,
    name = "",
    limit,
    parent_id
  ) => {
    try {
      const data = await autocompleteGetItemsWithParentId({
        model,
        name,
        limit,
        parent_id: form.values.industries.value || []
      });
      setOptions(opt => ({ ...opt, [model]: data.data.items }));
    } catch (err) {
      ErrorNotification({ text: err });
    }
  };

  const loadCategoriesFromAutoComplete = async (
    model,
    name = "",
    limit,
    parent_id
  ) => {
    try {
      const data = await autocompleteGetItemsWithParentId({
        model,
        name,
        limit,
        parent_id: form.values.segments.value || []
      });
      setOptions(opt => ({ ...opt, [model]: data.data.items }));
    } catch (err) {
      ErrorNotification({ text: err });
    }
  };

  const loadCountries = async () => {
    try {
      const data = await getCountriesList();
      setOptions(opt => ({ ...opt, country: data.data.items }));
    } catch (err) {
      ErrorNotification({ text: err });
    }
  };

  const loadStates = async parent_id => {
    try {
      const data = await autocompleteGetItems({
        model: "states",
        parent_id,
        name: ""
      });
      setOptions(opt => ({
        ...opt,
        states: {
          ...opt.states,
          [parent_id]: data.data.items
        }
      }));
    } catch (err) {
      ErrorNotification({ text: err });
    }
  };

  const loadZip = async name => {
    try {
      const sendData = {
        model: "zip"
      };
      if (name) {
        sendData.name = name;
      }
      const data = await autocompleteGetItems(sendData);
      setOptions(opt => ({
        ...opt,
        zip: data.data.items.filter(i => i.zip)
      }));
    } catch (err) {
      ErrorNotification({ text: err });
    }
  };

  return (
    <Form
      onSubmit={e => {
        e.preventDefault();
        form.handleSubmit();
      }}
    >
      <Menu
        mode="inline"
        style={{ height: "100%" }}
        openKeys={state.openSubMenu}
        onOpenChange={handleChangeSubMenu}
      >
        <SelectWithRadio
          triggerOptionsLoad={loadFromAutocomplete.bind(
            null,
            "business_models"
          )}
          onChange={form.setFieldValue}
          options={options.business_models}
          values={{
            select: form.values.business_model.value,
            radio: form.values.business_model.rule
          }}
          label="Business model"
          sectionName="business_model"
        />
        <SelectWithRadio
          triggerOptionsLoad={loadFromAutocomplete.bind(null, "business_types")}
          onChange={form.setFieldValue}
          options={options.business_types}
          values={{
            select: form.values.business_type.value,
            radio: form.values.business_type.rule
          }}
          label="Business type"
          sectionName="business_type"
        />
        <SelectMinMax
          onChange={form.setFieldValue}
          values={{
            min: form.values.size.value.gte,
            max: form.values.size.value.lte
          }}
          label="Company size"
          sectionName="size"
        />

        <SelectAddressFilter
          triggerCountriesLoad={loadCountries}
          triggerStatesLoad={loadStates}
          triggerZipLoad={loadZip}
          onChange={form.setFieldValue}
          options={options.country}
          stateOptions={Object.values(options.states)
            .flat()
            .filter(i => form.values.country.value.includes(+i.countries_id))}
          zipOptions={options.zip.filter(i =>
            form.values.country.value.includes(+i.countries_id)
          )}
          values={{
            country: form.values.country.value || [],
            counryRadio: form.values.country.rule,
            state: form.values.states.value || [],
            stateRadio: form.values.states.rule,
            zipCode: form.values.zip.value || [],
            zipRadio: form.values.zip.rule
          }}
          label="Country"
        />

        <SelectWithRadio
          triggerOptionsLoad={loadFromAutocomplete.bind(null, "industries")}
          onChange={form.setFieldValue}
          options={options.industries}
          values={{
            select: form.values.industries.value,
            radio: form.values.industries.rule
          }}
          label="Industry"
          sectionName="industries"
        />

        <SelectWithRadio
          triggerOptionsLoad={loadSegmentsFromAutoComplete.bind(
            null,
            "segments"
          )}
          onChange={form.setFieldValue}
          options={options.segments}
          values={{
            select: form.values.segments.value,
            radio: form.values.segments.rule
          }}
          label="Segment"
          sectionName="segments"
        />

        <SelectWithRadio
          triggerOptionsLoad={loadCategoriesFromAutoComplete.bind(
            null,
            "categories"
          )}
          onChange={form.setFieldValue}
          options={options.categories}
          values={{
            select: form.values.categories.value,
            radio: form.values.categories.rule
          }}
          isSearch
          label="Categories"
          sectionName="categories"
        />
        <SelectWithRadio
          triggerOptionsLoad={handleGetKeywords.bind(null, "keyword_group")}
          onChange={form.setFieldValue}
          options={options.keyword_group}
          values={{
            select: form.values.keyword_groups.value,
            radio: form.values.keyword_groups.rule
          }}
          isSearch
          label="Keywords"
          sectionName="keyword_groups"
        />

        <SelectWithRadio
          triggerOptionsLoad={loadFromAutocomplete.bind(null, "datasources")}
          onChange={form.setFieldValue}
          options={options.datasources}
          values={{
            select: form.values.data_source.value,
            radio: form.values.data_source.rule
          }}
          isSearch
          label="Data source"
          sectionName="data_source"
        />

        <SelectMinMax
          onChange={form.setFieldValue}
          values={{
            min: form.values.employees_count.value.gte,
            max: form.values.employees_count.value.lte
          }}
          label="Number of employees"
          sectionName="employees_count"
        />
        <SelectMinMax
          onChange={form.setFieldValue}
          values={{
            min: form.values.keyword_groups_total.value.gte,
            max: form.values.keyword_groups_total.value.lte
          }}
          label="Number of keywords"
          sectionName="keyword_groups_total"
        />

        <SelectBoolFilter
          onChange={form.setFieldValue}
          values={{
            radio: form.values.confirmed.value
          }}
          label="Confirmed company"
          sectionName="confirmed"
        />

        <SelectMinMax
          onChange={form.setFieldValue}
          values={{
            min: form.values.year_established.value.gte,
            max: form.values.year_established.value.lte
          }}
          label="Year established"
          sectionName="year_established"
        />
        <SelectWithRadio
          triggerOptionsLoad={loadFromAutocomplete.bind(null, "product_types")}
          onChange={form.setFieldValue}
          options={options.product_types}
          values={{
            select: form.values.product_type.value,
            radio: form.values.product_type.rule
          }}
          label="Product type"
          sectionName="product_type"
        />

        {editable && (
          <div className="companyFiltersButtonBlock">
            <Button
              onClick={resetForm}
              className="companyFiltersButtonFilterButton"
              type="danger"
            >
              Reset
            </Button>
            <Button
              className="companyFiltersButtonSubmitFilterResulButton"
              type="primary"
              htmlType="submit"
            >
              Submit
            </Button>
          </div>
        )}
      </Menu>
    </Form>
  );
};

const CompanyFiltersFormContainer = Form.create({
  name: "companyFiltersForm",
  mapPropsToFields(props) {
    const { filter } = props;
    const fields = {};
    function iterate(obj, parentKey) {
      for (var property in obj) {
        if (obj.hasOwnProperty(property)) {
          if (typeof obj[property] == "object" && !Array.isArray(obj[property]))
            iterate(obj[property], parentKey + "." + property);
          else
            fields[parentKey + "." + property] = Form.createFormField({
              value: obj[property]
            });
        }
      }
    }
    if (filter) {
      iterate(filter, "companyFilter");
    }
    return fields;
  }
})(CompanyFiltersBlock);
export default CompanyFiltersFormContainer;
