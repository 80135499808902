/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { Component } from "react";
import { Layout, Spin, Input, Button, List, Icon } from "antd";
import intl from "react-intl-universal";
import ErrorNotification from "../../components/UI/Notifications/ErrorNotification";
import SuccessNotification from "../../components/UI/Notifications/SuccessNotification";
import {
  getAllCategories,
  addNewCategory,
  deleteCategoryItem,
  updateCategoryItem
} from "../../services/api";

import ModalWindow from "../../components/UI/ModalWindow";
import BreadcrumbComponent from "../../components/BreadcrumbComponent";
import LisItem from "../../components/UI/LisItem";

const { Content, Header, Footer } = Layout;

class Category extends Component {
  state = {
    inited: false,
    categoryList: [],
    openModalForAddNewItem: false,
    newCategoryItemInputValue: "",
    selectedCategoryItem: "",
    deletedItem: {},
    showDeleteModalWindow: false,
    errorForRemoveInput: "",
    enteredDeletedItemValidation: ""
  };
  componentDidMount = () => {
    this.updateCategoryListData();
  };
  updateCategoryListData = async () => {
    try {
      const { match } = this.props;
      const res = await getAllCategories(match.params.segmentId);
      this.setState({
        inited: true,
        categoryList: res.data.items || [],
        newCategoryItemInputValue: "",
        selectedCategoryItem: ""
      });
      this.handleClose();
    } catch (error) {
      ErrorNotification({ text: error });
    }
  };
  openModalForAddNewCategory = () => {
    this.setState({
      openModalForAddNewItem: true
    });
  };
  heandleChangeInputForNewCategoryItem = e => {
    this.setState({
      newCategoryItemInputValue: e.target.value
    });
  };
  deleteCategoryItem = async item => {
    try {
      const { deletedItem, enteredDeletedItemValidation } = this.state;
      if (enteredDeletedItemValidation === deletedItem.name) {
        await deleteCategoryItem(deletedItem.id);
        this.updateCategoryListData();
        SuccessNotification({
          text: intl.get("successMessageForNotificationWindow")
        });
      } else {
        this.setState({
          errorForRemoveInput: intl.get("removeProtectionValidationMessage")
        });
      }
    } catch (error) {
      ErrorNotification({ text: error });
    }
  };
  addNewCategory = async () => {
    try {
      const { newCategoryItemInputValue } = this.state;
      const { match } = this.props;
      if (newCategoryItemInputValue !== "") {
        const data = {
          segmentId: Number(match.params.segmentId),
          name: newCategoryItemInputValue
        };
        await addNewCategory(data);
        this.updateCategoryListData();
        SuccessNotification({
          text: intl.get("successMessageForNotificationWindow")
        });
      } else {
        ErrorNotification({ text: intl.get("validationErrorMessage") });
      }
    } catch (error) {
      ErrorNotification({ text: error });
    }
  };
  selectCategoryItem = item => {
    this.setState({
      selectedCategoryItem: item
    });
  };
  editCategoryItem = async name => {
    const { selectedCategoryItem } = this.state;
    const { match } = this.props;
    try {
      if (name !== "") {
        const data = {
          id: Number(selectedCategoryItem.id),
          segmentId: Number(match.params.segmentId),
          name: name
        };
        await updateCategoryItem(Number(selectedCategoryItem), data);
        this.updateCategoryListData();
        SuccessNotification({
          text: intl.get("successMessageForNotificationWindow")
        });
      } else {
        ErrorNotification({ text: intl.get("validationErrorMessage") });
      }
    } catch (error) {
      ErrorNotification({ text: error });
    }
  };
  handleClose = () => {
    this.setState({
      openModalForAddNewItem: false,
      deletedItem: {},
      showDeleteModalWindow: false,
      errorForRemoveInput: "",
      enteredDeletedItemValidation: ""
    });
  };
  gotToKeywords = () => {
    const { history, match, lang } = this.props;
    const { selectedCategoryItem } = this.state;
    if (selectedCategoryItem) {
      history.push(
        `/${lang}/typology/${match.params.industryId}/segment/${match.params.segmentId}/category/${selectedCategoryItem}/keywords`
      );
    }
  };
  handleRemoveItemWithProtection = item => {
    this.setState({
      deletedItem: item,
      showDeleteModalWindow: true
    });
  };
  handleChangeRemoveItemInput = e => {
    this.setState({
      enteredDeletedItemValidation: e.target.value
    });
  };
  render() {
    const { lang } = this.props;
    const {
      inited,
      categoryList,
      openModalForAddNewItem,
      selectedCategoryItem,
      deletedItem,
      showDeleteModalWindow,
      errorForRemoveInput
    } = this.state;
    if (!inited) {
      return (
        <Layout className="layoutForSpinner">
          <Spin size="large" className="spinComponentStyle" />
        </Layout>
      );
    }
    return (
      <React.Fragment>
        <Layout className="removeBackGroundOnLayout">
          <Header className="headerTextAlign">
            <BreadcrumbComponent
              lang={lang}
              matchParam={this.props.match}
              currentPlace={intl.get("breadCrumbsCategory")}
            />
          </Header>
          <Content>
            <List
              className="listOfSegment"
              itemLayout="horizontal"
              dataSource={categoryList}
              renderItem={item => (
                <LisItem
                  key={item.id}
                  handleDeleteItem={this.handleRemoveItemWithProtection}
                  listItem={item}
                  goToNext={this.gotToKeywords}
                  selectedItem={selectedCategoryItem}
                  selectListItem={this.selectCategoryItem}
                  handleEditForm={this.handleEditForm}
                  title={intl.get("categoryEditItemModalWindowTitle")}
                  updateItem={this.editCategoryItem}
                  inputPlaceHolder={intl.get(
                    "categoryEditItemNodalWindowInputPlaceholder"
                  )}
                >
                  {item.name}
                </LisItem>
              )}
            />
            <Button
              type="primary"
              size={"large"}
              className="addNewIndustryButton"
              onClick={this.openModalForAddNewCategory}
            >
              <Icon type="plus-circle" className="iconAddONButton" />
              {intl.get("categoryAddCategoryItemButton")}
            </Button>
          </Content>
          <Footer>
            {/*
            ignore this please
            <Button
              type="primary"
              size={"large"}
              className="selectButton"
              onClick={this.gotToKeywords}
            >
              {intl.get("bottomButtonSelect")}
            </Button>*/}
          </Footer>
        </Layout>
        <ModalWindow
          open={openModalForAddNewItem}
          title={intl.get("categoryAddNewModalWindowTitle")}
          handleClose={this.handleClose}
          handleSubmit={this.addNewCategory}
        >
          <Input
            placeholder={intl.get("categoryAddNewModalWindowINputPlaceholder")}
            onChange={this.heandleChangeInputForNewCategoryItem}
          />
        </ModalWindow>
        <ModalWindow
          open={showDeleteModalWindow}
          title={`${intl.get("addIndustryRemoveIndustryModalWorning")} ${
            deletedItem ? deletedItem.name : ""
          }?`}
          handleClose={this.handleClose}
          handleSubmit={this.deleteCategoryItem}
          okText={intl.get("addIndustryModalWindowDeleteSubmitButton")}
          cancelText={intl.get("backButton")}
        >
          <p>{intl.get("addIndustryModalWindowLabelText")}</p>
          <Input
            placeholder={intl.get("removeIndustryModalWindowInputPlaceholder")}
            onChange={this.handleChangeRemoveItemInput}
          />
          {errorForRemoveInput !== "" && (
            <p style={{ color: "#F33" }}>{errorForRemoveInput}</p>
          )}
        </ModalWindow>
      </React.Fragment>
    );
  }
}

export default Category;
