import React from "react";
import PropTypes from "prop-types";
import { Modal } from "antd";

const ModalWindow = props => {
  const { open = false } = props;
  return (
    <React.Fragment>
      <Modal
        title={props.title}
        visible={props.open}
        onOk={props.handleSubmit}
        onCancel={props.handleClose}
        open={open}
        {...props}
      >
        {props.children}
      </Modal>
    </React.Fragment>
  );
};

ModalWindow.propTypes = {
  title: PropTypes.string,
  open: PropTypes.bool,
  handleSubmit: PropTypes.func,
  handleClose: PropTypes.func,
  children: PropTypes.node
};

ModalWindow.defaultProps = {
  title: "",
  handleSubmit: () => {},
  handleClose: () => {}
};

export default ModalWindow;
