import React, { useState, useEffect, useCallback, useContext } from "react";
import intl from "react-intl-universal";
import { Layout, Typography, Button, Icon, Spin, Modal, Select } from "antd";

import ViewCompanyProfile from "../NewCompanyContainer/ViewCompanyProfile";
import SuccessNotification from "../../components/UI/Notifications/SuccessNotification";
import ErrorNotification from "../../components/UI/Notifications/ErrorNotification";
import {
  getChosenCompanyProfile,
  companyProfileUpdate,
  getIndustryList,
  getSegmentForCurrentIndustry,
  runScraper
} from "../../services/api";

import { exportCompaniesProfile } from "../../services/importExportApi";

import "./CompanyProfile.scss";
import Topology from "./Topology";
import { ReportNameAndStatus } from "../Report/ReportNameAndStatus";
import { AuthContext } from "../../controllers/Auth";
import { Tile } from "./styled";

const { Content, Header, Footer } = Layout;

const initialState = {
  companyProfile: {},
  openAddIndustryModal: false,
  openAddSegmentModal: false,
  industryList: [],
  segmentList: [],
  chosenIndustryId: undefined,
  downLoadProfileStatus: false
};

const CompanyProfile = props => {
  const [state, setState] = useState(initialState);
  const { user } = useContext(AuthContext);

  const isRoleUser = user?.role?.name === "user";

  const getCompanyProfile = useCallback(async () => {
    const { match } = props;
    try {
      const companyProfile = await getChosenCompanyProfile(
        match.params.companyId
      );
      const getIndustryItemsFromDB = await getIndustryList();
      setState(state => {
        return {
          ...state,
          companyProfile: companyProfile.data || [],
          industryList: getIndustryItemsFromDB.items || [],
          inited: true
        };
      });
    } catch (error) {
      ErrorNotification({ text: error });
    }
  }, [props]);

  useEffect(() => {
    getCompanyProfile();
  }, [getCompanyProfile]);

  const handleUpdateCompanyProfile = useCallback(
    async data => {
      try {
        const { match } = props;
        await companyProfileUpdate(match.params.companyId, data);
        SuccessNotification({
          text: intl.get("successMessageForNotificationWindow")
        });
        getCompanyProfile();
      } catch (error) {
        ErrorNotification({ text: error });
      }
    },
    [getCompanyProfile, props]
  );

  const handleOpenAddIndustryModal = () => {
    setState(state => {
      return {
        ...state,
        openAddIndustryModal: true
      };
    });
  };

  const handleOpenAddSegmentModal = industryId => {
    setState(state => {
      return {
        ...state,
        chosenIndustryId: industryId,
        openAddSegmentModal: true
      };
    });
    handleGetSegmentDataFromDB(industryId);
  };

  const handleGetSegmentDataFromDB = useCallback(
    async indId => {
      try {
        const getSegmentListFromDB = await getSegmentForCurrentIndustry(indId);
        setState(state => {
          return {
            ...state,
            segmentList: getSegmentListFromDB.data.items || []
          };
        });
      } catch (error) {
        ErrorNotification({ text: error });
      }
    },
    [setState]
  );

  const handleClose = () => {
    setState(state => {
      return {
        ...state,
        openAddSegmentModal: false,
        openAddIndustryModal: false
      };
    });
  };

  const handleChangeIndustrySelect = useCallback(
    itemId => {
      setState(state => {
        return {
          ...state,
          chosenIndustryId: itemId
        };
      });
    },
    [setState]
  );

  const handleChangeSegmentSelect = useCallback(
    itemId => {
      setState(state => {
        return {
          ...state,
          chosenSegmentId: itemId
        };
      });
    },
    [setState]
  );

  const handleConfirmAddIndustryItem = useCallback(() => {
    let companyData = state.companyProfile;
    companyData.industries.push({ id: Number(state.chosenIndustryId) });
    handleUpdateCompanyProfile(companyData).then(() => {
      handleClose();
    });
  }, [
    handleUpdateCompanyProfile,
    state.chosenIndustryId,
    state.companyProfile
  ]);

  const handleConfirmAddSegmentItem = useCallback(() => {
    let companyData = state.companyProfile;
    companyData.industries = companyData.industries.map(item =>
      item.id === state.chosenIndustryId
        ? {
            ...item,
            [item.segments]: item.segments.push(Number(state.chosenSegmentId))
          }
        : { ...item }
    );
    handleUpdateCompanyProfile(companyData).then(() => {
      handleClose();
    });
  }, [
    handleUpdateCompanyProfile,
    state.chosenIndustryId,
    state.chosenSegmentId,
    state.companyProfile
  ]);

  const handleDownloadCompanyProfile = useCallback(async () => {
    try {
      setState(state => {
        return {
          ...state,
          downLoadProfileStatus: true
        };
      });
      const data = {
        selected: []
      };
      data.selected.push(Number(props.match.params.companyId));
      await exportCompaniesProfile(data).then(result => {
        setState(state => {
          return {
            ...state,
            downLoadProfileStatus: false
          };
        });
      });
    } catch (error) {
      ErrorNotification({ text: error });
    }
  }, [props.match.params.companyId]);

  const handleRunScraperButton = useCallback(async () => {
    try {
      const data = {
        id: []
      };
      data.id.push(Number(state.companyProfile.id));
      await runScraper(data).then(() => {
        SuccessNotification({ text: "Success" });
      });
    } catch (error) {
      ErrorNotification({ text: error });
    }
  }, [state.companyProfile]);

  if (!state.inited) {
    return (
      <Layout className="layoutForSpinner">
        <Spin size="large" className="spinComponentStyle" />
      </Layout>
    );
  }

  return (
    <>
      <Layout className="removeBackGroundOnLayout">
        <Header className="headerStyle companyProfileHeader">
          {props.report && props.report.name && (
            <ReportNameAndStatus report={props.report} />
          )}
          <Typography.Title className="pageTitle">
            {state.companyProfile.name}
            {isRoleUser && <Tile>Lead</Tile>}
          </Typography.Title>
          <div className="labelForTitle">
            <span className="addCompanyDateRow">
              <Icon className="companyProfileDateIcon" type="calendar" />
              <span className="addCompanyProfileLoadingDate">
                {intl.get("addCompanyProfilePageLoadingDate")}
              </span>
              <span className="addCompanyProfileDate">
                {new Date(state.companyProfile.created_at).toLocaleDateString(
                  "en-US"
                )}
              </span>
              <Button
                loading={state.downLoadProfileStatus}
                size={"large"}
                className="companyProfileDownloadProfileButton"
                onClick={handleDownloadCompanyProfile}
              >
                {intl.get("companyProfileDownloadProfileButton")}
              </Button>
            </span>
          </div>
        </Header>
        <Content className="companyInfoBlockItemsList">
          <ViewCompanyProfile
            handleUpdateCompanyProfile={handleUpdateCompanyProfile}
            companyData={state.companyProfile}
            isRoleUser={isRoleUser}
          />
          <Topology
            type={"company"}
            companyId={props.match.params.companyId}
            handleOpenAddIndustryModal={handleOpenAddIndustryModal}
            handleOpenAddSegmentModal={handleOpenAddSegmentModal}
          />
        </Content>
        {!isRoleUser && (
          <Footer>
            {props.next ? (
              <div>
                <Button
                  size={"large"}
                  className="companyProfileMatchReportButton"
                  onClick={props.next}
                >
                  {intl.get("companyProfileMatchReportButton")}
                </Button>
              </div>
            ) : (
              <div className="companyProfileDownloadReportButtons">
                <Button
                  size={"large"}
                  className="companyProfileDownloadReportButton"
                  onClick={handleRunScraperButton}
                >
                  {intl.get("addNewCompanyButtonRunScraper")}
                </Button>
              </div>
            )}
          </Footer>
        )}
      </Layout>
      <Modal
        title="Chose industry item"
        visible={state.openAddIndustryModal}
        onOk={handleConfirmAddIndustryItem}
        onCancel={handleClose}
      >
        <div>
          <Select
            showSearch
            className="addWordItemToCompanyKeywordSelect"
            placeholder="Select a industry"
            optionFilterProp="children"
            onChange={handleChangeIndustrySelect}
            filterOption={(input, option) =>
              option.props.children
                .toLowerCase()
                .indexOf(input.toLowerCase()) >= 0
            }
          >
            {state.industryList.map(industryItem => (
              <Select.Option key={industryItem.id} value={industryItem.id}>
                {industryItem.name}
              </Select.Option>
            ))}
          </Select>
        </div>
      </Modal>
      <Modal
        title="Chose segment item"
        visible={state.openAddSegmentModal}
        onOk={handleConfirmAddSegmentItem}
        onCancel={handleClose}
      >
        <div>
          <Select
            showSearch
            className="addWordItemToCompanyKeywordSelect"
            placeholder="Select a segment"
            optionFilterProp="children"
            onChange={handleChangeSegmentSelect}
            filterOption={(input, option) =>
              option.props.children
                .toLowerCase()
                .indexOf(input.toLowerCase()) >= 0
            }
          >
            {state.segmentList.map(segmentItem => (
              <Select.Option key={segmentItem.id} value={segmentItem.id}>
                {segmentItem.name}
              </Select.Option>
            ))}
          </Select>
        </div>
      </Modal>
    </>
  );
};

CompanyProfile.propTypes = {};

export default CompanyProfile;
