import React, { useCallback } from "react";
import CompanyProfile from "../CompanyProfile";
import { confirmCompanyProfile } from "../../services/api";
import ErrorNotification from "../../components/UI/Notifications/ErrorNotification";

const Company = ({ report, next }) => {
  const handleNext = useCallback(async () => {
    try {
      await confirmCompanyProfile(+report.id);
      next();
    } catch (text) {
      ErrorNotification({ text });
    }
  }, [next, report]);
  return (
    <CompanyProfile
      report={report}
      next={handleNext}
      match={{ params: { companyId: +report.company_id } }}
    />
  );
};

export default Company;
