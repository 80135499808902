import React from "react";
import PropTypes from "prop-types";
import { Radio, Menu } from "antd";

const { SubMenu } = Menu;

const SelectBoolFilter = props => {
  const { label, subMenuKey, onChange, values, sectionName, ...other } = props;

  const radioOptions = [
    { value: true, name: "Confirmed" },
    { value: false, name: "Not confirmed" }
  ];
  const handleChange = (field, value) => {
    onChange(`${sectionName}.${field}`, value);
  };

  return (
    <SubMenu
      className="subMenuItem"
      key={subMenuKey}
      {...other}
      title={<span>{label}:</span>}
    >
      <Radio.Group
        onChange={e => handleChange("value", e.target.value)}
        className="companiesFiltersRadioButtonsGroup"
        value={values.radio}
      >
        {radioOptions.map(({ value, name }) => (
          <Radio
            key={value}
            className="companiesFiltersRadioButton"
            value={value}
          >
            {name}
          </Radio>
        ))}
      </Radio.Group>
    </SubMenu>
  );
};

SelectBoolFilter.propTypes = {
  label: PropTypes.string.isRequired,
  editable: PropTypes.bool,
  options: PropTypes.array,
  subMenuKey: PropTypes.string,
  onChange: PropTypes.func,
  sectionName: PropTypes.string.isRequired,
  values: PropTypes.object
};
SelectBoolFilter.defaultProps = {
  editable: true,
  options: [],
  subMenuKey: "Key",
  sectionName: "Name",
  label: "Sub menu label",
  onChange: () => null,
  values: {
    select: {},
    radio: ""
  }
};

export default SelectBoolFilter;
