import React, { useEffect, useState } from "react";
import { Button } from "antd";
import { getExcelLogs } from "../../../services/api";
import moment from "moment";
import Details, { DETAILS } from "./Modal";
import { useModal } from "../../../hooks/useModal";
import { List } from "./styled";

const limit = 20;

function ExcelLogs() {
  const { open } = useModal(DETAILS);
  const [loading, setLoading] = useState(true);
  const [list, setList] = useState([]);
  const [offset, setOffset] = useState(0);
  const [hasMore, setHasMore] = useState(false);

  useEffect(() => {
    loadData();
  }, [offset]);

  async function loadData() {
    setLoading(true);
    const data = await getExcelLogs(limit, offset);
    setList(prev => [...prev, ...data.data.items]);

    setLoading(false);

    setHasMore(data.data.total > limit + offset && data.data.total > limit);
  }

  function onLoadMore() {
    setOffset(prev => limit + prev);
  }
  const loadMore = hasMore ? (
    <div
      style={{
        textAlign: "center",
        marginTop: 12,
        height: 32,
        lineHeight: "32px"
      }}
    >
      <Button onClick={onLoadMore} disabled={loading}>
        loading more
      </Button>
    </div>
  ) : null;

  return (
    <>
      <h1>Excel error logs</h1>
      <List
        loading={loading}
        itemLayout="horizontal"
        loadMore={loadMore}
        dataSource={list}
        renderItem={item => (
          <List.Item
            actions={[
              <a key="list-loadmore-more" onClick={() => open({ id: item.id })}>
                View
              </a>
            ]}
          >
            {moment(item.date).format()}
          </List.Item>
        )}
      />
      <Details />
    </>
  );
}

export default ExcelLogs;
