import styled from "styled-components";

import { Layout as _Layout } from "antd";
const { Content: _Content } = _Layout;

export const Content = styled(_Content)`
  display: flex;
  justify-content: left;
  .ant-table-body {
    border: 1px solid #e8e8e8;
    border-bottom-width: 0;
  }
  .ant-table-footer {
    border: 1px solid #e8e8e8;
    border-top-width: 0;
  }
`;
