import { useState } from "react";
import * as React from "react";

export const DataContext = React.createContext({});

export const Steps = {
  STEP_1: 0,
  STEP_2: 1,
  STEP_3: 2
};

function DataProvider(props) {
  const [prevStep, setPrevStep] = useState([]);
  const [step, setStep] = useState(Steps.STEP_1);
  const [data, updateData] = useState();

  function handleSetStep(nextStep, data) {
    if (data) {
      updateData(prev => ({ ...prev, ...data }));
    }
    setPrevStep(prev => [...prev, step]);
    setStep(nextStep);
  }
  function handlePrevStep() {
    const nextStep = prevStep.pop();
    setStep(nextStep || Steps.STEP_1);
  }

  function handleReset() {
    setStep(Steps.STEP_1);
    setPrevStep([]);
    updateData(prev => ({ options: prev.options }));
  }
  return (
    <DataContext.Provider
      value={{
        step,
        setStep: handleSetStep,
        data,
        updateData,
        setPrevStep: handlePrevStep,
        reset: handleReset
      }}
    >
      {props.children}
    </DataContext.Provider>
  );
}
export default DataProvider;
