import React from "react";
import PropTypes from "prop-types";
import intl from "react-intl-universal";
import { Typography, Row, Col, List, Select, Icon, Button } from "antd";

import "./AddLanguagePage.scss";

const { Title } = Typography;
const { Option } = Select;

const AddLanguage = props => {
  return (
    <React.Fragment>
      <div className="titleWrapper">
        <div>
          <Row className="dataSourceRow">
            <Col className="columnForListDataSource">
              <div>
                <Title className="columnTitle">
                  {intl.get("addLanguageActiveLanguage")}
                </Title>
                <List
                  className="choseedCountryList"
                  itemLayout="horizontal"
                  dataSource={props.choosedCountries}
                  renderItem={item => (
                    <List.Item className="listItemStyle" key={item.name}>
                      {item.name}
                      <Icon
                        type="close"
                        className="closeIconColor"
                        onClick={() => {
                          props.removeLanguageFromDB(item);
                        }}
                      />
                    </List.Item>
                  )}
                />
              </div>
            </Col>
            <Col className="columnWithAddForm">
              <Title className="columnTitle">
                {intl.get("addLangiageAddNewLanguage")}
              </Title>
              <Select
                showSearch
                className="addLanguageSelect"
                placeholder={intl.get("addLanguageSelectPlaceholder")}
                optionFilterProp="children"
                onChange={props.onChangeSelector}
              >
                {props.countryList.map((item, index) => (
                  <Option key={index} value={item}>
                    {item}
                  </Option>
                ))}
              </Select>
              <Button
                type="primary"
                className="addLanguageStyleButton"
                onClick={props.addLanguegeToDB}
              >
                {intl.get("addButtonName")}
              </Button>
            </Col>
          </Row>
        </div>
      </div>
    </React.Fragment>
  );
};

AddLanguage.propTypes = {
  countryList: PropTypes.arrayOf(PropTypes.any),
  onChangeSelector: PropTypes.func.isRequired,
  choosedCountries: PropTypes.arrayOf(PropTypes.any),
  addLanguegeToDB: PropTypes.func.isRequired,
  removeLanguageFromDB: PropTypes.func.isRequired
};

AddLanguage.defaultProps = {
  countryList: null,
  choosedCountries: null
};

export default AddLanguage;
