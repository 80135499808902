import React, { useEffect, useState, useCallback } from "react";
import { Form, Select, Icon, Button, Input } from "antd";
import { getCountriesList, autocompleteGetItems } from "../../services/api";
import { useFormik } from "formik";
import ErrorNotification from "../../components/UI/Notifications/ErrorNotification/ErrorNotification";

const createEmptyAddr = () => ({
  country: { id: "", name: "" },
  state: { id: "", name: "" },
  zip: "",
  address: "",
  id: Math.random()
});

const AddAdressFormik = ({
  initialData = [],
  handleSetValues,
  handleCloseModalWindow
}) => {
  const [availableCountries, setAvailableCountries] = useState([]);
  const [availableStates, setAvailableStates] = useState({});

  const onSubmit = values => {
    const data = {
      address: values.address.map(addr => ({
        address: addr.address,
        countries_id: (addr.country && addr.country.id) || null,
        zip: addr.zip,
        state_id: (addr.state && addr.state.id) || null,
        country: addr.country,
        state: addr.state
      }))
    };

    handleSetValues(data);
    handleCloseModalWindow();
  };

  const form = useFormik({
    initialValues: {
      address: [createEmptyAddr()]
    },
    onSubmit: onSubmit
  });

  const getCountries = async () => {
    try {
      const getAllCountryListItems = await getCountriesList();
      setAvailableCountries(getAllCountryListItems.data.items);
    } catch (err) {
      ErrorNotification({ text: err });
    }
  };

  useEffect(() => {
    getCountries();
  }, []);

  const loadAvailableStates = useCallback(async parent_id => {
    try {
      const states = await autocompleteGetItems({
        model: "states",
        name: "",
        parent_id
      });
      setAvailableStates(s => ({
        ...s,
        [parent_id]: states.data.items
      }));
    } catch (err) {
      ErrorNotification({ text: err });
    }
  }, []);

  useEffect(() => {
    const addresses = [];
    initialData.forEach(addr => {
      addresses.push({
        country: {
          id: (addr.country && addr.country.id) || "",
          name: (addr.country && addr.country.name) || ""
        },
        state: {
          id: (addr.state && addr.state.id) || "",
          name: (addr.state && addr.state.name) || ""
        },
        zip: addr.zip,
        address: addr.address,
        id: addr.id || Math.random()
      });
    });
    Promise.all(
      addresses.map(
        addr =>
          addr.country &&
          addr.country.id &&
          !availableStates[addr.country.id] &&
          loadAvailableStates(addr.country.id)
      )
    );
    form.setFieldValue("address", addresses);
    // eslint-disable-next-line
  }, []);

  const onCountryChange = (i, value) => {
    const [id, name] = value.split(".");
    form.setFieldValue(`address.${i}.country`, { id, name });
    form.setFieldValue(`address.${i}.state`, { id: "", name: "" });
    loadAvailableStates(id);
  };

  const onStateChange = (i, value) => {
    const [id, name] = value.split(".");
    form.setFieldValue(`address.${i}.state`, { id, name });
  };

  const removeAddress = i =>
    form.setFieldValue(
      "address",
      form.values.address.filter((_, ind) => ind !== i)
    );

  return (
    <Form onSubmit={form.handleSubmit}>
      {form.values.address.map((addr, i) => (
        <Form.Item key={addr.id} required={false}>
          <Select
            className="arrayInputComponent"
            showSearch
            optionFilterProp="children"
            value={addr.country.name || undefined}
            placeholder="Country"
            onChange={onCountryChange.bind(null, i)}
          >
            {availableCountries.map((item, index) => (
              <Select.Option key={index} value={`${item.id}.${item.name}`}>
                {item.name}
              </Select.Option>
            ))}
          </Select>
          {form.values.address.length > 1 && (
            <Icon
              className="removeStringIconButton"
              type="minus-circle-o"
              onClick={removeAddress.bind(null, i)}
            />
          )}
          <Select
            className="arrayInputComponent"
            showSearch
            placeholder="State"
            optionFilterProp="children"
            value={addr.state.name || undefined}
            onChange={onStateChange.bind(null, i)}
          >
            {addr.country.id &&
              availableStates[addr.country.id] &&
              availableStates[addr.country.id].map(item => (
                <Select.Option key={item.id} value={`${item.id}.${item.name}`}>
                  {item.name}
                </Select.Option>
              ))}
          </Select>

          <Input
            className="arrayInputComponent"
            placeholder="Zip"
            name={`address.${i}.zip`}
            onChange={form.handleChange}
            value={addr.zip}
          />
          <Input
            className="arrayInputComponent"
            placeholder="Address"
            name={`address.${i}.address`}
            onChange={form.handleChange}
            value={addr.address}
          />
        </Form.Item>
      ))}
      <Form.Item>
        <Button
          className="submitButtonOnForm"
          onClick={() =>
            form.setFieldValue("address", [
              ...form.values.address,
              createEmptyAddr()
            ])
          }
          type="dashed"
        >
          <Icon type="plus" /> Add field
        </Button>
      </Form.Item>
      <Form.Item>
        <Button className="submitButtonOnForm" type="primary" htmlType="submit">
          Submit
        </Button>
      </Form.Item>
    </Form>
  );
};

export default AddAdressFormik;
