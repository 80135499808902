import { notification } from "antd";
const ErrorNotification = ({ text }) => {
  const getText =
    typeof text === "string"
      ? text
      : `${(text.response &&
          text.response.data &&
          text.response.data.message) ||
          "Error"}`;

  notification["error"]({
    message: "Error",
    description: getText,
    duration: 4
  });
};

export default ErrorNotification;
