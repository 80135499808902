import React, { useEffect, useState } from "react";
import { FormItem, Input, Radio, Select } from "formik-antd";
import { autocompleteGetItems } from "../../../services/api";
import { useFormikContext } from "formik";
import { FieldWrap, RadioLabel } from "./styled";

const { Option } = Select;

const AddressField = ({ index, countries }) => {
  const { values: _values, setFieldValue } = useFormikContext();
  const values = _values[`address_${index}`] || {};
  const [states, setStates] = useState([]);
  useEffect(() => {
    if (values?.country) {
      autocompleteGetItems({
        model: "states",
        parent_id: values.country
      }).then(({ data }) => {
        setStates(data.items);
        setFieldValue(`address_${index}.state`, undefined);
      });
    } else if (states.length) {
      setStates([]);
      setFieldValue(`address_${index}.state`, undefined);
    }
  }, [values?.country]);

  return (
    <FieldWrap>
      <RadioLabel>
        <Radio.Group
          name={`address_${index}.radio`}
          options={[
            { label: "Add", value: "add" },
            { label: "Delete", value: "delete" }
          ]}
        />
      </RadioLabel>
      <FormItem name={`address_${index}.country`} label="Add country">
        <Select
          name={`address_${index}.country`}
          placeholder="Country"
          allowClear
        >
          {countries.map(item => (
            <Option key={item.id} value={item.id}>
              {item.name}
            </Option>
          ))}
        </Select>
      </FormItem>
      <FormItem name={`address_${index}.state`} label="Add state">
        <Select name={`address_${index}.state`} placeholder="State" allowClear>
          {states?.map(item => (
            <Option key={item.id} value={item.id}>
              {item.name}
            </Option>
          ))}
        </Select>
      </FormItem>
      <FormItem name={`address_${index}.zip`} label="Add postal code">
        <Input name={`address_${index}.zip`} allowClear />
      </FormItem>
      <FormItem name={`address_${index}.address`} label="Add street">
        <Input name={`address_${index}.address`} allowClear />
      </FormItem>
    </FieldWrap>
  );
};
export default AddressField;
