import React, { Component } from "react";
import { Layout, Spin, Input, Button, Icon, List } from "antd";
import intl from "react-intl-universal";
import ErrorNotification from "../../components/UI/Notifications/ErrorNotification";
import SuccessNotification from "../../components/UI/Notifications/SuccessNotification";
import {
  getSegmentForCurrentIndustry,
  addNewSegment,
  deleteSegmentItem,
  updateSegmentItem
} from "../../services/api";
import ModalWindow from "../../components/UI/ModalWindow";
import LisItem from "../../components/UI/LisItem";
import BreadcrumbComponent from "../../components/BreadcrumbComponent";

import "./SegmentList.scss";

const { Content, Header, Footer } = Layout;

class Segment extends Component {
  state = {
    inited: false,
    segmentList: [],
    openModalForAddNewItem: false,
    newSegmentItemInputValue: "",
    selectedSegmentItem: "",
    changedSegmentName: "",
    deletedItem: {},
    showDeleteModalWindow: false,
    errorForRemoveInput: "",
    enteredDeletedItemValidation: ""
  };
  componentDidMount = () => {
    this.updateSegmentData();
  };
  updateSegmentData = async () => {
    try {
      const { match } = this.props;
      const res = await getSegmentForCurrentIndustry(match.params.industryId);
      this.setState({
        inited: true,
        segmentList: res.data.items || [],
        changedSegmentName: "",
        selectedSegmentItem: ""
      });
      this.handleClose();
    } catch (error) {
      ErrorNotification({ text: error });
    }
  };
  openModalForAddNewSegment = () => {
    this.setState({
      openModalForAddNewItem: true
    });
  };
  heandleChangeInputForNewSegmentItem = e => {
    this.setState({
      newSegmentItemInputValue: e.target.value
    });
  };
  deleteSegmentItem = async item => {
    try {
      const { deletedItem, enteredDeletedItemValidation } = this.state;
      if (enteredDeletedItemValidation === deletedItem.name) {
        await deleteSegmentItem(deletedItem.id);
        this.updateSegmentData();
        SuccessNotification({
          text: intl.get("successMessageForNotificationWindow")
        });
      } else {
        this.setState({
          errorForRemoveInput: intl.get("removeProtectionValidationMessage")
        });
      }
    } catch (error) {
      ErrorNotification({ text: error });
    }
  };
  addNewSegment = async () => {
    try {
      const { newSegmentItemInputValue } = this.state;
      const { match } = this.props;
      if (newSegmentItemInputValue !== "") {
        const data = {
          industryId: Number(match.params.industryId),
          name: newSegmentItemInputValue
        };
        await addNewSegment(data);
        this.updateSegmentData();
        SuccessNotification({
          text: intl.get("successMessageForNotificationWindow")
        });
      } else {
        ErrorNotification({ text: intl.get("validationErrorMessage") });
      }
    } catch (error) {
      ErrorNotification({ text: error });
    }
  };
  selectSegmentItem = item => {
    this.setState({
      selectedSegmentItem: item
    });
  };
  editSegmentItem = async name => {
    const { selectedSegmentItem } = this.state;
    try {
      if (name !== "") {
        const data = {
          id: Number(selectedSegmentItem),
          name: name
        };
        await updateSegmentItem(data);
        this.updateSegmentData();
        SuccessNotification({
          text: intl.get("successMessageForNotificationWindow")
        });
      } else {
        ErrorNotification({ text: intl.get("validationErrorMessage") });
      }
    } catch (error) {
      ErrorNotification({ text: error });
    }
  };
  handleClose = () => {
    this.setState({
      openModalForAddNewItem: false,
      deletedItem: {},
      showDeleteModalWindow: false,
      errorForRemoveInput: ""
    });
  };
  gotToCategory = () => {
    const { history, match, lang } = this.props;
    const { selectedSegmentItem } = this.state;
    if (selectedSegmentItem) {
      history.push(
        `/${lang}/typology/${match.params.industryId}/segment/${selectedSegmentItem}/category`
      );
    }
  };
  handleRemoveItemWithProtection = item => {
    this.setState({
      deletedItem: item,
      showDeleteModalWindow: true
    });
  };
  handleChangeRemoveItemInput = e => {
    this.setState({
      enteredDeletedItemValidation: e.target.value
    });
  };
  render() {
    const { lang } = this.props;
    const {
      inited,
      segmentList,
      openModalForAddNewItem,
      selectedSegmentItem,
      showDeleteModalWindow,
      deletedItem,
      errorForRemoveInput
    } = this.state;
    if (!inited) {
      return (
        <Layout className="layoutForSpinner">
          <Spin size="large" className="spinComponentStyle" />
        </Layout>
      );
    }
    return (
      <React.Fragment>
        <Layout className="removeBackGroundOnLayout">
          <Header className="headerTextAlign">
            <BreadcrumbComponent
              lang={lang}
              matchParam={this.props.match}
              currentPlace={intl.get("breadCrumbsSegment")}
            />
          </Header>
          <Content>
            <React.Fragment>
              <List
                className="listOfSegment"
                itemLayout="horizontal"
                dataSource={segmentList}
                renderItem={item => (
                  <LisItem
                    key={item.id}
                    handleDeleteItem={this.handleRemoveItemWithProtection}
                    listItem={item}
                    goToNext={this.gotToCategory}
                    selectedItem={selectedSegmentItem}
                    selectListItem={this.selectSegmentItem}
                    title={intl.get("editSegmentModalWindowTitle")}
                    updateItem={this.editSegmentItem}
                    inputPlaceHolder={intl.get("editSegmentINputPlaceholder")}
                  >
                    {item.name}
                  </LisItem>
                )}
              />
              <Button
                type="primary"
                size={"large"}
                className="addNewIndustryButton"
                onClick={this.openModalForAddNewSegment}
              >
                <Icon type="plus-circle" className="iconAddONButton" />
                {intl.get("addSegmentButtonName")}
              </Button>
            </React.Fragment>
          </Content>
          <Footer>
            {/* 
           ignore this please
           <Button
              type="primary"
              size={"large"}
              className="selectButton"
              onClick={this.gotToCategory}
            >
              {intl.get("bottomButtonSelect")}
           </Button>*/}
          </Footer>
        </Layout>
        <ModalWindow
          open={openModalForAddNewItem}
          title={intl.get("addNewSegmentModalTitle")}
          handleClose={this.handleClose}
          handleSubmit={this.addNewSegment}
        >
          <Input
            placeholder={intl.get("addNewSegmentInputPlaceholder")}
            onChange={this.heandleChangeInputForNewSegmentItem}
          />
        </ModalWindow>
        <ModalWindow
          open={showDeleteModalWindow}
          title={`${intl.get("addIndustryRemoveIndustryModalWorning")} ${
            deletedItem ? deletedItem.name : ""
          }?`}
          handleClose={this.handleClose}
          handleSubmit={this.deleteSegmentItem}
          okText={intl.get("addIndustryModalWindowDeleteSubmitButton")}
          cancelText={intl.get("backButton")}
        >
          <p>{intl.get("addIndustryModalWindowLabelText")}</p>
          <Input
            placeholder={intl.get("removeIndustryModalWindowInputPlaceholder")}
            onChange={this.handleChangeRemoveItemInput}
          />
          {errorForRemoveInput !== "" && (
            <p style={{ color: "#F33" }}>{errorForRemoveInput}</p>
          )}
        </ModalWindow>
      </React.Fragment>
    );
  }
}

export default Segment;
