import React from "react";
import { Form, Input, Button } from "antd";

const BusinessSizeForm = props => {
  const handleSubmit1 = e => {
    e.preventDefault();
    props.form.validateFields((err, values) => {
      if (!err) {
        props.handleSetValues(values);
        props.handleCloseModalWindow();
      }
    });
  };
  return (
    <Form onSubmit={handleSubmit1} className="companyProfileAddCompanyForm">
      <Form.Item>
        {props.form.getFieldDecorator(props.chosenFiledForEdit, {
          initialValue: props.initialData,
          rules: props.validationRules
        })(<Input type="number" />)}
      </Form.Item>
      <Form.Item>
        <Button
          className="submitFormButtonInputForm"
          type="primary"
          htmlType="submit"
        >
          Submit
        </Button>
      </Form.Item>
    </Form>
  );
};

const WrappedDynamicFieldSet = Form.create({ name: "dynamic_form_item" })(
  BusinessSizeForm
);
export default WrappedDynamicFieldSet;
