import React from "react";
import NavBar from "../../NavBar";
import "./PageWrapper.scss";

const PageWrapper = props => {
  return (
    <>
      <NavBar {...props} />
      <div className="pageWrapper">{props.children}</div>
    </>
  );
};

export default PageWrapper;
