import isEmpty from "lodash.isempty";

export const filterArraysOfCountries = (countryList, choosedCountryList) => {
  if (!isEmpty(countryList)) {
    const filteredCountryArray = countryList.filter(countryItem => {
      const findDuplicates = choosedCountryList.filter(
        chosedItem => chosedItem.name === countryItem
      );
      return isEmpty(findDuplicates);
    });
    return filteredCountryArray;
  }
  return [];
};
