import styled from "styled-components";
import { Steps as _Steps } from "antd";

export const Steps = styled(_Steps)`
  margin-top: 16px;
  margin-bottom: 16px;
`;
export const RadioWrap = styled.div`
  .ant-radio-group {
    margin-top: 36px;
  }
`;
