import React, { useState } from "react";
import Modal from "../../components/UI/ModalWindow/ModalWithHook";
import { Form, Input, Select } from "antd";

import ErrorNotification from "../../components/UI/Notifications/ErrorNotification";
import { useModal } from "../../hooks/useModal";
import { copyReportApi } from "../../services/api";
const { Option } = Select;

export const REPORT_COPY = "report_copy";

const options = [
  { value: "2", label: "Provide prospect details" },
  { value: "4", label: "Prospect taxonomy" },
  { value: "5", label: "Filter companies to match" }
];

function ModalCopy({ form }) {
  const { getFieldDecorator } = form;
  const [loading, setLoading] = useState(false);
  const { data, close } = useModal(REPORT_COPY);

  async function handleSubmit(e) {
    e.preventDefault();
    const { id, onSubmit } = data;
    form.validateFieldsAndScroll((err, { name, status }) => {
      if (!err) {
        setLoading(true);
        copyReportApi(id, { name, status_id: Number(status) })
          .then(() => {
            onSubmit();
            form.resetFields();
            close();
          })
          .catch(text => ErrorNotification({ text }))
          .finally(() => setLoading(false));
      }
    });
  }
  function onClose() {
    form.resetFields();
  }
  return (
    <Modal
      name={REPORT_COPY}
      handleOnClose={onClose}
      okButtonProps={{
        form: "copyReport",
        key: "submit",
        htmlType: "submit",
        loading: loading
      }}
    >
      <Form id="copyReport" onSubmit={handleSubmit}>
        <Form.Item label="New name" name="name">
          {getFieldDecorator("name", { rules: [{ required: true }] })(
            <Input />
          )}
        </Form.Item>
        <Form.Item name="status" label="State to which report should be copied">
          {getFieldDecorator("status", { rules: [{ required: true }] })(
            <Select rules={[{ required: true }]}>
              {options.map(i => (
                <Option key={i.value} value={i.value}>
                  {i.label}
                </Option>
              ))}
            </Select>
          )}
        </Form.Item>
      </Form>
    </Modal>
  );
}
export default Form.create({ name: "copyReport" })(ModalCopy);
