import React from "react";
import PropTypes from "prop-types";
import { Slider, Menu, Form, InputNumber } from "antd";

const { SubMenu } = Menu;

const SelectMinMax = props => {
  const {
    options,
    editable,
    label,
    subMenuKey,
    onChange,
    sectionName,
    values,
    triggerOptionsLoad,
    ...other
  } = props;

  const handleChange = (field, value) => {
    onChange(`${sectionName}.${field}`, value);
  };
  return (
    <SubMenu
      className="subMenuItem"
      key={subMenuKey}
      {...other}
      title={<span>{label}:</span>}
    >
      <Form.Item label="Min">
        <Slider
          onChange={value => handleChange("value.gte", value)}
          value={values.min}
          min={0}
          max={10000}
          disabled={!editable}
        />
        <InputNumber
          min={0}
          value={values.min}
          max={10000}
          onChange={value => handleChange("value.gte", value)}
          style={{ marginLeft: 16 }}
          disabled={!editable}
        />
      </Form.Item>
      <Form.Item label="Max">
        <Slider
          min={0}
          max={10000}
          value={values.max}
          onChange={value => handleChange("value.lte", value)}
          disabled={!editable}
        />
        <InputNumber
          min={0}
          value={values.max}
          max={10000}
          style={{ marginLeft: 16 }}
          disabled={!editable}
          onChange={value => handleChange("value.lte", value)}
        />
      </Form.Item>
    </SubMenu>
  );
};

SelectMinMax.propTypes = {
  label: PropTypes.string.isRequired,
  editable: PropTypes.bool,
  options: PropTypes.array,
  subMenuKey: PropTypes.string,
  onChange: PropTypes.func,
  sectionName: PropTypes.string.isRequired,
  values: PropTypes.object,
  triggerOptionsLoad: PropTypes.func
};
SelectMinMax.defaultProps = {
  editable: true,
  options: [],
  subMenuKey: "Key",
  sectionName: "Name",
  label: "Sub menu label",
  onChange: () => null,
  triggerOptionsLoad: () => null,
  values: {
    select: {},
    radio: ""
  }
};

export default SelectMinMax;
