import React, { useState, useCallback, useEffect } from "react";
import PropTypes from "prop-types";
import intl from "react-intl-universal";
import { List, Icon, Card, Input } from "antd";
import ModalWindow from "../../UI/ModalWindow";

import "./ListItem.scss";
const ListItem = props => {
  const {
    listItem,
    selectListItem,
    handleDeleteItem,
    updateItem,
    title,
    inputPlaceHolder
  } = props;
  const [state, setState] = useState({
    editItem: listItem.name || "",
    openEditModal: false
  });
  const handleChooseItem = useCallback(() => {
    if (props.synonymItem) {
      selectListItem(listItem);
    } else {
      selectListItem(listItem.id);
    }
  }, [props.synonymItem, selectListItem, listItem]);
  const handleRemoveListItem = useCallback(() => {
    handleDeleteItem(listItem);
  }, [handleDeleteItem, listItem]);

  const handleOpenEditWindow = useCallback(() => {
    setState(s => {
      return {
        ...s,
        openEditModal: true
      };
    });
  }, [setState]);
  const handleCloseEditWindow = useCallback(() => {
    setState(s => {
      return {
        ...s,
        openEditModal: false
      };
    });
  }, [setState]);
  const handleChangeItemName = useCallback(
    e => {
      e.persist();
      setState(s => {
        return {
          ...s,
          editItem: e.target.value
        };
      });
    },
    [setState]
  );
  const saveEditedItem = useCallback(() => {
    setState(s => {
      const { editItem } = s;
      if (editItem !== "" && editItem !== listItem.name) {
        updateItem(editItem);
        return { ...s, openEditModal: false };
      } else {
        return { ...s, openEditModal: false };
      }
    });
  }, [updateItem, listItem, setState]);
  const handleDoubleClick = useCallback(() => {
    props.goToNext();
  }, [props]);

  useEffect(() => {
    if (listItem.name) setState(s => ({ ...s, editItem: listItem.name }));
  }, [listItem.name]);

  const { editItem, openEditModal } = state;
  const { handleEditForm, synonymItem } = props;
  return (
    <React.Fragment>
      <Card
        className="cardListItem"
        onClick={handleChooseItem}
        onDoubleClick={handleDoubleClick}
      >
        <List.Item
          actions={[
            <Icon
              className="listItemRemoveButtonIcon"
              type="edit"
              onClick={synonymItem ? handleEditForm : handleOpenEditWindow}
            />,
            <Icon
              className="listItemRemoveButtonIcon"
              type="close-circle"
              onClick={handleRemoveListItem}
            />
          ]}
          className="listItemRow"
        >
          <React.Fragment>
            <div className="listItemContentBlock">
              <div className="listItemBlock">
                <Icon
                  type="check-circle"
                  theme="filled"
                  style={{
                    paddingTop: "5px",
                    paddingRight: "5px",
                    color:
                      props.selectedItem === props.listItem.id ||
                      props.synonymItem
                        ? "#2F80ED"
                        : ""
                  }}
                />
                <p className="listItemText">{props.children}</p>
              </div>
              {props.synonymItem && (
                <React.Fragment>
                  <div className="listItemBlock">
                    <Icon type="picture" className="listItemImagesStyle" />
                    <p className="listItemText">
                      {props.listItem.is_synonym
                        ? intl.get("listItemSynonymIsSynonym")
                        : intl.get("listItemSynonymIsTranslation")}
                    </p>
                  </div>
                  {props.listItem.language && (
                    <div className="listItemBlock">
                      <Icon type="picture" className="listItemImagesStyle" />
                      <p className="listItemText">
                        {props.listItem.language.name}
                      </p>
                    </div>
                  )}
                  <div className="listItemBlock">
                    <Icon type="picture" className="listItemImagesStyle" />
                    <p className="listItemText">
                      {props.listItem.case_sensitive
                        ? intl.get("listItemSensetiveCase")
                        : intl.get("listItemInsensetiveCase")}
                    </p>
                  </div>
                </React.Fragment>
              )}
              {!props.synonymItem && props.listItem.segmentCount && (
                <>
                  <div className="listItemBlock">
                    <Icon type="picture" className="listItemImagesStyle" />
                    <p className="listItemText">
                      {props.listItem.segmentCount}{" "}
                      {intl.get("industryListSegmentItemCountLabel")}
                    </p>
                  </div>
                </>
              )}
              {!props.synonymItem && props.listItem.categoriesCount >= 0 && (
                <>
                  <div className="listItemBlock">
                    <Icon type="picture" className="listItemImagesStyle" />
                    <p className="listItemText">
                      {props.listItem.categoriesCount}{" "}
                      {intl.get("listItemCategoriesItem")}
                    </p>
                  </div>
                  <div className="listItemBlock">
                    <Icon type="picture" className="listItemImagesStyle" />
                    <p className="listItemText">
                      {props.listItem.keywordGroupCount}{" "}
                      {intl.get("listItemKeywodrsItem")}
                    </p>
                  </div>
                </>
              )}
              {!props.synonymItem && props.listItem.keywordsCount && (
                <div className="listItemBlock">
                  <Icon type="picture" className="listItemImagesStyle" />
                  <p className="listItemText">
                    {props.listItem.keywordsCount}{" "}
                    {intl.get("listItemKeywodrsItem")}
                  </p>
                </div>
              )}
            </div>
          </React.Fragment>
        </List.Item>
      </Card>
      <ModalWindow
        open={openEditModal}
        title={title}
        handleClose={handleCloseEditWindow}
        handleSubmit={saveEditedItem}
      >
        <Input
          placeholder={inputPlaceHolder}
          onChange={handleChangeItemName}
          value={editItem}
        />
      </ModalWindow>
    </React.Fragment>
  );
};

ListItem.propTypes = {
  synonymItem: PropTypes.bool,
  listItem: PropTypes.objectOf(PropTypes.any),
  handleDeleteItem: PropTypes.func.isRequired,
  selectedItem: PropTypes.string,
  selectListItem: PropTypes.func.isRequired,
  handleEditForm: PropTypes.func,
  goToNext: PropTypes.func
};

ListItem.defaultProps = {
  synonymItem: false,
  listItem: {},
  handleEditForm: () => {},
  selectedItem: "",
  goToNext: () => {}
};

export default ListItem;
