import styled from "styled-components";

export const Wrap = styled.div`
  display: flex;
  justify-content: flex-start;
  align-items: flex-end;
  margin: 20px 0;
  height: 48px;
  input {
    width: 287px;
  }
  .action__submit {
    margin: 0 12px;
  }
`;
export const Label = styled.label`
  > p {
    text-align: left;
  }
`;
export const SearchField = styled.div`
  position: relative;
  margin-right: 12px;
  input::placeholder {
    text-align: left;
  }
  button {
    position: absolute;
    z-index: 2;
    right: 0;
    border-radius: 4px;
    margin: 0;
  }
`;
