import React, { createContext, useContext, useState } from "react";

const ModalContext = createContext({
  state: {},
  actions: {
    openModal: () => void 0,
    closeModal: () => void 0
  }
});

export function ModalsProvider(props) {
  const [modalsState, setModalsState] = useState(useContext(ModalContext));

  function openModal(key, data) {
    setModalsState({
      ...modalsState,
      state: {
        ...modalsState.state,
        [key]: {
          open: true,
          data
        }
      }
    });
  }

  function closeModal(key) {
    const modal = modalsState.state[key];

    if (modal) {
      setModalsState({
        ...modalsState,
        state: {
          ...modalsState.state,
          [key]: {
            ...modal,
            open: false
          }
        }
      });

      setTimeout(() => {
        delete modalsState.state[key];
        setModalsState(modalsState);
      }, 300);
    }
  }

  return (
    <ModalContext.Provider
      value={{
        ...modalsState,
        actions: {
          openModal,
          closeModal
        }
      }}
      {...props}
    />
  );
}

export function useModal(name) {
  const modalContext = useContext(ModalContext);

  function open(data) {
    modalContext.actions.openModal(name, data);
  }

  function close() {
    modalContext.actions.closeModal(name);
  }

  const modalState = modalContext.state[name];

  return {
    isOpen: modalState ? modalState.open : false,
    isClosing: modalState && !modalState.open,
    data: modalState ? modalState.data : undefined,
    open,
    close
  };
}
