import Axios from "axios";
import config from "../config.json";
import FileDownload from "js-file-download";
import qs from "qs";

let axios = Axios.create({
  baseURL: config.importExport,
  timeout: 15000
});

export const setupImportExportAxiosInstance = (
  accessToken,
  tryUpdateTokens
) => {
  axios = Axios.create({
    baseURL: config.importExport,
    timeout: 15000,
    headers: {
      Authorization: `Bearer ${accessToken}`
    }
  });
  axios.interceptors.response.use(
    response => response,
    async error => {
      const status = error.response ? error.response.status : null;
      if (status === 401) {
        const accessToken = await tryUpdateTokens();
        if (accessToken) {
          error.config.headers["Authorization"] = `Bearer ${accessToken}`;
          return axios.request(error.config);
        }
      }
      return Promise.reject(error);
    }
  );
};

//import /export data-source
export const exportDataSource = async () => {
  axios.defaults.headers = {
    ...axios.defaults.headers,
    "Content-Type": "application/json"
  };
  const res = await axios.get(`/export/datasources`);
  FileDownload(res.data, "dataSource.csv");
};

export const importDataSourceList = async data => {
  axios.defaults.headers = {
    ...axios.defaults.headers,
    "Content-Type": "multipart/form-data"
  };
  const res = await axios.post(`/import/datasources`, data);
  return res;
};

//keywords import/export api's
export const exportKeywordGroups = async id => {
  axios.defaults.headers = {
    ...axios.defaults.headers,
    "Content-Type": "application/json"
  };
  const res = await axios.get(`/export/keywords/${id}`);
  FileDownload(res.data, "keywords.csv");
};

export const importKeywords = async (id, data) => {
  axios.defaults.headers = {
    ...axios.defaults.headers,
    "Content-Type": "multipart/form-data"
  };
  const res = await axios.post(`/import/keywords/${id}`, data);
  return res;
};

//companies import/export api's
export const importCompanyFile = async (data, query) => {
  axios.defaults.headers = {
    ...axios.defaults.headers,
    "Content-Type": "multipart/form-data"
  };
  const queryUrl = qs.stringify(query, { addQueryPrefix: true });
  const res = await axios.post(`/import/company${queryUrl}`, data);
  return res;
};

export const exportCompaniesProfile = async data => {
  axios.defaults.timeout = 900000;
  axios.defaults.headers = {
    ...axios.defaults.headers,
    "Content-Type": "application/json"
  };
  const res = await axios.post(`/export/companies/selected?excel=true`, data, {
    responseType: "blob"
  });
  FileDownload(res.data, "companies.xlsx");
};

export const exportAllCompaniesWithFilter = async filters => {
  axios.defaults.timeout = 900000;
  axios.defaults.headers = {
    ...axios.defaults.headers,
    "Content-Type": "application/json"
  };
  const res = await axios.post(`/export/companies?excel=true`, filters, {
    responseType: "blob"
  });
  FileDownload(res.data, "companies.xlsx");
};

export const exportCompaniesOnReport = async reportId => {
  axios.defaults.timeout = 900000;
  axios.defaults.headers = {
    ...axios.defaults.headers,
    "Content-Type":
      "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet"
  };
  const res = await axios.get(`/export/report/${reportId}?excel=true`, {
    responseType: "blob"
  });
  FileDownload(res.data, "report.xlsx");
};

export const exportFullReport = async reportId => {
  axios.defaults.timeout = 900000;
  axios.defaults.headers = {
    ...axios.defaults.headers,
    "Content-Type":
      "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet"
  };
  const res = await axios.get(`/export/full_report/${reportId}`, {
    responseType: "blob"
  });
  FileDownload(res.data, "full_report.xlsx");
};

export const getAvailableField = async () => {
  const res = await axios.get("/import/availableField");
  return res;
};
