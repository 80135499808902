import * as React from "react";
import { Formik } from "formik";
import { SubmitButton, Input, FormItem, Select } from "formik-antd";
import { Form } from "./styled";
import { Button } from "antd";
import CustomField from "./CustomField";
import AddressField from "./AddressField";
import KeywordsField from "./KeywordsField";
import useIniData from "./initialDataHook";
import { useState } from "react";
import makeID from "../../../utils/getID";
import { dataAdapter } from "./dataAdapter";
import { Buttons } from "./styled";
const { Option } = Select;

const SampleForm = ({ count, onSubmit, onClose }) => {
  const [data, loading] = useIniData();
  const [dataSource, addDataSource] = useState(() => [makeID()]);
  const [languages, addLanguage] = useState(() => [makeID()]);
  const [addresses, addAddress] = useState(() => [makeID()]);
  const [businessModels, addBusinessModels] = useState(() => [makeID()]);
  const [productType, addProductType] = useState(() => [makeID()]);
  if (loading) return <p>loading...</p>;

  async function handleOnSubmit(values, actions) {
    await onSubmit(dataAdapter(values));
    actions.resetForm();
    return {};
  }
  function onCancel() {
    onClose();
  }
  return (
    <>
      <h1>
        Bulk changes to the <b>{count}</b> companies selected
      </h1>
      <Formik
        initialValues={{
          newsletter: false
        }}
        onSubmit={handleOnSubmit}
        render={({ values }) => (
          <Form>
            <FormItem
              name="business_type"
              label="Replace business type"
              className="bigger"
            >
              <Select name="business_type" allowClear>
                {data.businessType.map(i => (
                  <Option value={i.id} key={i.id}>
                    {i.name}
                  </Option>
                ))}
              </Select>
            </FormItem>

            <h3>Replace business model</h3>
            {businessModels.map((id, key) => (
              <CustomField
                prefix="businessModel"
                key={id}
                index={key}
                data={data.businessModel}
              />
            ))}
            <Button
              type="link"
              onClick={() => addBusinessModels(prev => [...prev, makeID()])}
            >
              + Data business model
            </Button>
            <FormItem
              name="company_size"
              label="Replace company size"
              className="bigger"
            >
              <Input name="company_size" type="number" allowClear />
            </FormItem>
            <FormItem
              name="employees_count"
              label="Replace number of employees"
              className="bigger"
            >
              <Input name="employees_count" type="number" allowClear />
            </FormItem>
            <h3>Product Type</h3>
            {productType.map((id, key) => (
              <CustomField
                prefix="productType"
                findBy="product_types"
                isAutocomplete
                key={id}
                index={key}
                data={data.productType}
              />
            ))}
            <Button
              type="link"
              onClick={() => addProductType(prev => [...prev, makeID()])}
            >
              + Data Product type
            </Button>

            <h3>Data Source (customer)</h3>
            {dataSource.map((id, key) => (
              <CustomField
                prefix="dataSource"
                findBy="datasources"
                isAutocomplete
                key={id}
                index={key}
                data={data.dataSources}
              />
            ))}
            <Button
              type="link"
              onClick={() => addDataSource(prev => [...prev, makeID()])}
            >
              + Data Source
            </Button>
            <h3>Language</h3>
            {languages.map((id, key) => (
              <CustomField
                prefix="language"
                key={id}
                index={key}
                data={data.languages}
              />
            ))}
            <Button
              onClick={() => addLanguage(prev => [...prev, makeID()])}
              type="link"
            >
              + Language
            </Button>
            <h3>Address</h3>
            {addresses.map((id, key) => (
              <AddressField
                values={values[`address_${key}`]}
                key={id}
                index={key}
                countries={data.countries}
              />
            ))}
            <Button
              onClick={() => addAddress(prev => [...prev, makeID()])}
              type="link"
            >
              + Address
            </Button>
            <h3>Keywords</h3>
            <KeywordsField industry={data.industry} />
            <Buttons>
              <Button disabled={false} size="large" onClick={onCancel}>
                Cancel
              </Button>
              <SubmitButton type="primary" disabled={false} size="large">
                Apply
              </SubmitButton>
            </Buttons>
          </Form>
        )}
      />
    </>
  );
};
export default SampleForm;
