import React from "react";
import ReactDOM from "react-dom";
import App from "./App";
import * as serviceWorker from "./serviceWorker";
import initLocale from "./locales";
import "antd/dist/antd.less";

const lang = "en";
initLocale(lang).then(() => {
  ReactDOM.render(<App lang={lang} />, document.getElementById("root"));
});

//TODO: Before release should be chaged to register
serviceWorker.unregister();
