import React, { useContext } from "react";
import { Form, Button, Input } from "antd";
import intl from "react-intl-universal";
import { withRouter } from "react-router-dom";
import { Typography } from "antd";
import { AuthContext } from "../../controllers/Auth";
import LogoBlock from "../../components/UI/LogoBlock";
import "./Login.scss";
const { Title } = Typography;

const Login = props => {
  const { doLogin } = useContext(AuthContext);
  const signIn = async e => {
    e.preventDefault();
    props.form.validateFieldsAndScroll((err, values) => {
      if (!err) {
        const credentials = {
          login: values.username,
          password: values.password
        };
        doLogin(credentials);
      }
    });
  };
  const { getFieldDecorator } = props.form;
  return (
    <>
      <LogoBlock />
      <div className="loginPageWrapper">
        <Form onSubmit={signIn} className="loginForm">
          <div className="inputGroup">
            <div className="titleWrapper">
              <Title className="pageTitle">{intl.get("loginPageTitle")}</Title>
            </div>
            <div className="inputWrapper">
              <Form.Item>
                <label htmlFor="email" className="inputLabel">
                  {intl.get("emailLabelForLoginInput")}
                </label>
                {getFieldDecorator("username", {
                  rules: [
                    { required: true, message: intl.get("loginPageEmailError") }
                  ]
                })(
                  <Input
                    placeholder="example@domain.sample"
                    style={{ height: "45px" }}
                    id="email"
                    type="email"
                  />
                )}
              </Form.Item>
            </div>
            <Form.Item>
              <label htmlFor="password" className="inputLabel">
                {intl.get("loginPagePasswordFiledLabel")}
              </label>
              {getFieldDecorator("password", {
                rules: [
                  {
                    required: true,
                    message: intl.get("loginPagePasswordError")
                  }
                ]
              })(
                <Input
                  id="password"
                  className="input"
                  placeholder="********"
                  style={{ height: "45px" }}
                  type="password"
                />
              )}
            </Form.Item>
          </div>
          <Button
            htmlType="submit"
            type="primary"
            className="nextBtn"
            size={"large"}
          >
            {intl.get("loginPageLoginButton")}
          </Button>
        </Form>
      </div>
    </>
  );
};

export default withRouter(Form.create({ name: "login" })(Login));
