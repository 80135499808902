import React from "react";
import { Form, Input, Icon, Button } from "antd";

let id = 0;

class DynamicFieldSet extends React.Component {
  componentDidMount = () => {
    const { resetFields } = this.props.form;
    resetFields();
    id = 0;
    if (this.props.initialData && Array.isArray(this.props.initialData)) {
      for (let index = 0; index < this.props.initialData.length; index++) {
        this.add();
      }
    }
  };
  remove = k => {
    const { form } = this.props;
    const keys = form.getFieldValue("keys");
    if (keys.length === 1) {
      return;
    }
    form.setFieldsValue({
      keys: keys.filter(key => key !== k)
    });
  };

  add = () => {
    const { form } = this.props;
    const keys = form.getFieldValue("keys");
    const nextKeys = keys.concat(id++);
    form.setFieldsValue({
      keys: nextKeys
    });
  };

  handleSubmit = e => {
    e.preventDefault();
    const { validateFields, resetFields } = this.props.form;
    validateFields((err, values) => {
      if (!err) {
        const { keys } = values;
        const data = {
          [this.props.chosenFiledForEdit]: keys.map(
            key => values[this.props.chosenFiledForEdit][key]
          )
        };
        this.props.handleSetValues(data);
        id = 0;
        resetFields();
        this.props.handleCloseModalWindow();
      }
    });
  };

  render() {
    const { getFieldDecorator, getFieldValue } = this.props.form;
    getFieldDecorator("keys", { initialValue: [] });
    const keys = getFieldValue("keys");
    const formItems = keys.map((k, index) => (
      <Form.Item required={false} key={k}>
        <div>
          {getFieldDecorator(`${this.props.chosenFiledForEdit}[${k}]`, {
            validateTrigger: ["onChange", "onBlur"],
            rules: this.props.validationRules,
            initialValue:
              this.props.initialData && this.props.initialData[k]
                ? this.props.initialData[k]
                : undefined
          })(
            <Input
              /*type={
                this.props.chosenFiledForEdit === "phones" ? "number" : "text"
              }*/
              className="arrayInputComponent"
            />
          )}
          {keys.length > 1 ? (
            <Icon
              className="removeStringIconButton"
              type="minus-circle-o"
              onClick={() => this.remove(k)}
            />
          ) : null}
        </div>
      </Form.Item>
    ));
    return (
      <Form onSubmit={this.handleSubmit}>
        {formItems}
        <Form.Item>
          <Button
            className="submitButtonOnForm"
            type="dashed"
            onClick={() => {
              this.add();
            }}
          >
            <Icon type="plus" /> Add field
          </Button>
        </Form.Item>
        <Form.Item>
          <Button
            className="submitButtonOnForm"
            type="primary"
            htmlType="submit"
          >
            Submit
          </Button>
        </Form.Item>
      </Form>
    );
  }
}

const WrappedDynamicFieldSet = Form.create({ name: "dynamic_form_item" })(
  DynamicFieldSet
);
export default WrappedDynamicFieldSet;
