import React, { useCallback, useState, useEffect } from "react";
import PropTypes from "prop-types";
import { Card, Icon, List } from "antd";
import { getSegmentItemById } from "../../../services/api";

const initialState = {
  sourceListItems: []
};

const SegmentBlockItem = props => {
  const [state, setState] = useState(initialState);

  const getItem = useCallback(async () => {
    const newSourceList = [];
    for (const industryItem of props.content) {
      if (industryItem.segments)
        for await (const segmentItem of industryItem.segments) {
          const getSegmentItem = await getSegmentItemById(segmentItem);
          newSourceList.push(getSegmentItem.data);
        }
    }
    setState(s => {
      return {
        ...s,
        sourceListItems: newSourceList
      };
    });
  }, [setState, props]);
  useEffect(() => {
    if (props.content) {
      getItem();
    }
  }, [getItem, props]);
  return (
    <>
      <Card
        size="small"
        className="companyInfoBlockItemCard"
        headStyle={{
          borderBottom: "none"
        }}
        title={
          <>
            <div className="cardTitleForCompanyIfoBlock">
              <div className="imageIconBlockWithCircle">
                <div className="companyInfoBlockCircle" />
                <Icon type="picture" className="infoBlockIcon" />
              </div>
              <p className="cardCompanyInfoBlockTitle">{props.title}:</p>
            </div>
          </>
        }
      >
        <div className="contentForCarItem">
          {props.content && (
            <List
              className="companyProfileBlockItemList"
              dataSource={state.sourceListItems}
              bordered={false}
              renderItem={item => (
                <List.Item
                  className="companyProfileItemBlockListItem"
                  key={item.id}
                >
                  <p className="companyProfileBlockItemString">{item.name}</p>
                </List.Item>
              )}
            />
          )}
        </div>
      </Card>
    </>
  );
};

SegmentBlockItem.propTypes = {
  title: PropTypes.string.isRequired,
  openEditModalWindow: PropTypes.func,
  editableItem: PropTypes.object.isRequired
};

SegmentBlockItem.defaultProps = {
  openEditModalWindow: () => {}
};

export default SegmentBlockItem;
