import styled from "styled-components";

export const Tile = styled.div`
  font-size: 14px;
  line-height: 18px;
  display: inline-flex;
  align-items: center;
  color: #2975ea;
  font-weight: normal;
  background: #f1f4f8;
  border: 1px solid #c9daf5;
  box-sizing: border-box;
  border-radius: 20px;
  padding: 7px 16px;
  position: relative;
  top: -20px;
  right: -14px;
`;
