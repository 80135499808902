import React from "react";
import ReactQuill from "react-quill";
import { Button } from "antd";
import "react-quill/dist/quill.snow.css";
import ErrorNotification from "../../components/UI/Notifications/ErrorNotification";
import SuccessNotification from "../../components/UI/Notifications/SuccessNotification";

import { saveCustomFieldOnReport } from "../../services/api";

class CustomInformationField extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      text: props.initialValue
    };
  }
  handleChange = value => {
    this.setState(s => ({ ...s, text: value }));
  };
  handleSaveCustomInformationField = async () => {
    try {
      const { text } = this.state;
      const { reportId } = this.props;
      if (text !== "") {
        await saveCustomFieldOnReport(reportId, { info: text }).then(() => {
          SuccessNotification({ text: "Saved" });
        });
      }
    } catch (error) {
      ErrorNotification({ text: error });
    }
  };
  render() {
    const modules = {
      toolbar: [
        [{ header: [1, 2, false] }],
        ["bold", "italic", "underline", "strike", "blockquote"],
        [
          { list: "ordered" },
          { list: "bullet" },
          { indent: "-1" },
          { indent: "+1" }
        ],
        ["link", "image"],
        ["clean"]
      ]
    };

    const formats = [
      "header",
      "bold",
      "italic",
      "underline",
      "strike",
      "blockquote",
      "list",
      "bullet",
      "indent",
      "link",
      "image"
    ];
    return (
      <div className="textEditor">
        <ReactQuill
          theme="snow"
          modules={modules}
          formats={formats}
          value={this.state.text}
          onChange={this.handleChange}
          style={{
            height: 200
          }}
        ></ReactQuill>
        <div className="editorTabButtonsBlock">
          <Button
            size={"large"}
            className="report-export"
            onClick={this.handleSaveCustomInformationField}
          >
            Save
          </Button>
        </div>
      </div>
    );
  }
}

export default CustomInformationField;
