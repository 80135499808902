import React from "react";
import intl from "react-intl-universal";
import { Typography, List } from "antd";
import WordItem from "./WordItem";
import CompanyTopWordsBlock from "./CompanyTopWordsBlock";
import CompanyProfileTypology from "./CompanyProfileTypology";

const Topology = ({
  editable,
  companyId,
  handleOpenAddIndustryModal,
  handleOpenAddSegmentModal,
  type,
  prospect
}) => {
  return (
    <>
      <div>
        <Typography.Title className="pageTitle">
          {intl.get("companyProfileKeywordsTitleBlock")}
        </Typography.Title>
        <div>
          <WordItem companyId={companyId} type={type} editable={editable} />
        </div>
      </div>
      <div className="companyProfileTopologyBlock">
        <Typography.Title className="pageTitle">
          {intl.get("companyProfileKeywordsTopologyTitleBlock")}
        </Typography.Title>
        <div>
          <CompanyProfileTypology
            handleOpenAddIndustryModal={handleOpenAddIndustryModal}
            handleOpenAddSegmentModal={handleOpenAddSegmentModal}
            companyId={companyId}
            type={type}
            editable={editable}
          />
        </div>
        <div className="companyProfileTopKeywordsBlock">
          <Typography.Title className="pageTitle">
            {intl.get("companyProfileTopKeywordsTitleBlock")}
          </Typography.Title>
          <div>
            <CompanyTopWordsBlock companyId={companyId} type={type} />
          </div>
        </div>
        {type === "taxonomy" && (
          <>
            <Typography.Title className="pageTitle">
              {intl.get("companyProfileProspectBlock")}
            </Typography.Title>
            <div>
              <List
                dataSource={prospect}
                bordered
                renderItem={item => (
                  <List.Item>
                    <Typography.Text>{item.url}</Typography.Text>
                  </List.Item>
                )}
              />
            </div>
          </>
        )}
      </div>
    </>
  );
};

Topology.defaultProps = {
  type: "company",
  editable: true
};

export default Topology;
