import React, { useContext, useEffect, useState } from "react";
import { NavLink } from "react-router-dom";
import intl from "react-intl-universal";
import { Dropdown, Button } from "antd";

import { AuthContext } from "../../controllers/Auth";
import LogoBlock from "../UI/LogoBlock";
import "./NavBar.scss";

const NAV_LINKS = [
  {
    url: "/companies",
    caption: "Companies"
  },
  {
    url: "/typology",
    caption: "Typology"
  },
  {
    url: "/reports",
    caption: "Reporting"
  },
  {
    url: "/keywordSearch",
    caption: "Keywords Search"
  }
];

const SETTINGS_LINKS = lang => {
  return (
    <div className="setting_drop_down">
      <NavLink
        key={`language`}
        to={`/${lang}/language`}
        className="nav_link_settings"
      >
        <span className="navbar__item ">Add Language</span>
      </NavLink>
      <NavLink
        key={`data-source`}
        to={`/${lang}/data-source`}
        className="nav_link_settings"
      >
        <span className="navbar__item ">Add Data Source</span>
      </NavLink>
      <NavLink
        key={`excel`}
        to={`/${lang}/logs/excel`}
        className="nav_link_settings"
      >
        <span className="navbar__item ">Excel error logs</span>
      </NavLink>
    </div>
  );
};

const NavBar = props => {
  const { doLogout, user } = useContext(AuthContext);
  const [scrolledClass, setScrolledClass] = useState("");
  const signOut = async () => {
    doLogout();
  };

  function onScroll() {
    if (window.scrollY > 0 && !scrolledClass) {
      setScrolledClass("scrolled");
    } else if (window.scrollY === 0) {
      setScrolledClass("");
    }
  }

  useEffect(() => {
    window.addEventListener("scroll", onScroll);
    return () => {
      window.removeEventListener("scroll", onScroll);
    };
  }, []);
  const isRoleUser = user?.role?.name === "user";

  return (
    <>
      <nav className={`navigationContainer ${scrolledClass}`}>
        <div className="navbar">
          {/*<Button
            type="primary"
            className="backButtonItem"
            size={"large"}
            onClick={() => {
              props.history.goBack();
            }}
          >
            {intl.get("backButton")}
          </Button>*/}
          <div className="logoHeaderBlock">
            <LogoBlock />
          </div>
          {isRoleUser ? (
            <NavLink
              to={`/${props.lang}/leads`}
              className="navbar__item"
              activeClassName="activeNavBarItem"
            >
              Leads
            </NavLink>
          ) : (
            <>
              {NAV_LINKS.map(link => (
                <NavLink
                  key={link.url}
                  to={`/${props.lang}${link.url}`}
                  className="navbar__item"
                  activeClassName="activeNavBarItem"
                >
                  {link.caption}
                </NavLink>
              ))}
              <Dropdown overlay={SETTINGS_LINKS(props.lang)}>
                <span className="navbar__item ">Settings</span>
              </Dropdown>
              {["super_admin", "admin"].includes(user?.role?.name) && (
                <NavLink
                  to={`/${props.lang}/users`}
                  className="navbar__item"
                  activeClassName="activeNavBarItem"
                >
                  Users
                </NavLink>
              )}
            </>
          )}
        </div>
        <div>
          <span className="header-userinfo">
            {user?.first_name} {user?.company?.name || user?.company}
          </span>
          <Button
            type="primary"
            className="logoutButton"
            size="large"
            onClick={signOut}
          >
            {intl.get("logoutButton")}
          </Button>
        </div>
      </nav>
      <div />
    </>
  );
};

export default NavBar;
