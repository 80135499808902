import React, { useState, useEffect, useCallback, useContext } from "react";
import { useParams, Redirect } from "react-router-dom";
import Loader from "../../components/Loader";
import ErrorNotification from "../../components/UI/Notifications/ErrorNotification";
import { getReportDetails } from "../../services/api";
import { LangContext } from "../../controllers/Lang";
import Company from "./Company";
import ProspectDetails from "./ProspectDetails";
import Taxonomy from "./Taxonomy";
import Filter from "./Filter";
import CompletedReport from "./CompletedReport";
import "./Report.scss";

const Report = () => {
  const { lang } = useContext(LangContext);
  const { id } = useParams();
  const [loaded, setLoaded] = useState(false);
  const [report, setReport] = useState({ items: [], total: 0 });

  const load = useCallback(async () => {
    setLoaded(false);
    try {
      const response = await getReportDetails(id);
      setReport(response.data);
    } catch (text) {
      ErrorNotification({ text });
    }
    setLoaded(true);
  }, [setReport, id]);

  useEffect(() => {
    load();
  }, [load]);

  if (!loaded) {
    return <Loader />;
  }

  let component = null;
  switch (report.status_id) {
    case 1:
      //Company profile
      component = <Company next={load} report={report} />;
      break;
    case 2:
      //Provide prospect details
      component = <ProspectDetails next={load} report={report} />;
      break;
    case 3:
      //Prospect scraping details
      component = <Redirect to={`/${lang}/reports`} />;
      break;
    case 4:
      //Prospect taxonomy
      component = <Taxonomy next={load} report={report} />;
      break;
    case 5:
      //Filter companies to match
      component = <Filter next={load} report={report} lang={lang} />;
      break;
    case 6:
      //Matching in progress
      component = <Redirect to={`/${lang}/reports`} />;
      break;
    case 7:
      //Completed
      component = <CompletedReport report={report} lang={lang} />;
      break;
    default:
      component = <Redirect to={`/${lang}/reports`} />;
  }
  return component;
};

export default Report;
