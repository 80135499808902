import React, { useState, useEffect, useCallback } from "react";
import { Form, Button, Select } from "antd";
import { autocompleteGetItems } from "../../services/api";
import debounce from "lodash.debounce";
import ErrorNotification from "../../components/UI/Notifications/ErrorNotification";

const ProductType = props => {
  const { chosenFiledForEdit, fieldId } = props;
  const [productType, setProductType] = useState(
    props.initialData
      ? {
          value: props.initialData.id || props.initialData.value,
          name: props.initialData.name
        }
      : {}
  );
  const [productTypeOptions, setProductTypeOptions] = useState([]);

  useEffect(() => {
    triggerOptionsLoad(chosenFiledForEdit, "", 80);
  }, []);

  const triggerOptionsLoad = async (model, name = "", limit) => {
    try {
      const opts = await autocompleteGetItems({
        model,
        name,
        limit
      });
      setProductTypeOptions(opts.data.items);
    } catch (err) {
      ErrorNotification({ text: err });
    }
  };

  const searchProductType = useCallback(
    debounce(
      search => triggerOptionsLoad(chosenFiledForEdit, search, 80),
      1200
    ),
    [setProductTypeOptions]
  );

  const handleSubmit = e => {
    e.preventDefault();
    const data = {};
    if (productType) {
      data[chosenFiledForEdit] = productType;
      data[fieldId] = String(productType.value);
    } else {
      data[chosenFiledForEdit] = [];
      data[fieldId] = undefined;
    }
    props.handleSetValues(data);
    props.handleCloseModalWindow();
  };
  function onChange(val, option) {
    const { name, value } = option.props;
    setProductType({ name, value });
  }
  return (
    <Form onSubmit={handleSubmit}>
      <Form.Item>
        <Select
          value={productType?.value}
          optionFilterProp="name"
          filterOption={false}
          onSearch={searchProductType}
          onChange={onChange}
          onSelect={triggerOptionsLoad.bind(null, chosenFiledForEdit, "", 80)}
          showSearch
          defaultActiveFirstOption={false}
          showArrow={false}
        >
          {productTypeOptions.map((item, index) => (
            <Select.Option
              key={index}
              name={item.name || item.value}
              value={item.id}
            >
              {item.name || item.value}
            </Select.Option>
          ))}
        </Select>
      </Form.Item>
      <Form.Item>
        <Button
          className="submitFormButtonInputForm"
          type="primary"
          htmlType="submit"
        >
          Submit
        </Button>
      </Form.Item>
    </Form>
  );
};

const WrappedDynamicFieldSet = Form.create({ name: "qualification" })(
  ProductType
);
export default WrappedDynamicFieldSet;
