import React, { useState, useEffect, useCallback, useContext } from "react";
import { Button, Layout, Pagination, Icon } from "antd";
import { useHistory } from "react-router-dom";
import intl from "react-intl-universal";
import Loader from "../../components/Loader";
import ErrorNotification from "../../components/UI/Notifications/ErrorNotification";
import { getReports, deleteReport } from "../../services/api";
import { LangContext } from "../../controllers/Lang";
import ModalWindow from "../../components/UI/ModalWindow";
import "./Reports.scss";
import ModalCopy, { REPORT_COPY } from "./ModalCopy";
import { useModal } from "../../hooks/useModal";
const { Content } = Layout;

const ReportButton = ({ id, status, lang }) => {
  const history = useHistory();

  const navigate = useCallback(() => history.push(`/${lang}/reports/${id}`), [
    id,
    history,
    lang
  ]);
  return (
    <Icon
      className="reportsEditButton"
      type="edit"
      onClick={status.id !== 3 || status.id !== 6 ? navigate : () => {}}
    />
  );
};

const initialNav = { offset: 0, limit: 5, current: 1 };

const Reports = () => {
  const { lang } = useContext(LangContext);
  const history = useHistory();
  const [loaded, setLoaded] = useState(false);
  const [modal, setModal] = useState({ openModal: false, removedItemId: "" });
  const [nav, setNav] = useState(initialNav);
  const [reports, setReports] = useState({ items: [], total: 0 });
  const { open } = useModal(REPORT_COPY);

  async function load() {
    setLoaded(false);
    try {
      const response = await getReports(nav);
      setReports(response.data);
    } catch (text) {
      ErrorNotification({ text });
    }
    setLoaded(true);
  }

  const addReport = useCallback(() => {
    history.push(`/${lang}/reports/new`);
  }, [history, lang]);

  function changePage(page, size) {
    setNav({
      offset: size * (page - 1),
      limit: 5,
      current: page
    });
  }

  const handleReportDelete = useCallback(async () => {
    await deleteReport(modal.removedItemId).then(() => {
      setModal(() => ({
        openModal: false,
        removedItemId: ""
      }));
    });
    load(nav);
  }, [load, modal.removedItemId, nav]);

  const handleOpenModalWindow = useCallback(r => {
    setModal(s => ({ openModal: true, removedItemId: r.id }));
  }, []);

  function toInitialPage() {
    if (initialNav === nav) {
      load(nav);
    } else {
      setNav(initialNav);
    }
  }
  useEffect(() => {
    load(nav);
  }, [nav]);

  return (
    <Layout className="reports">
      <Content>
        {!loaded ? (
          <Loader />
        ) : (
          <>
            {reports.items.map(r => (
              <div className="reports-item" key={r.id}>
                <div className="reports-item-col">
                  <img
                    alt="Status"
                    src={`/images/${
                      r.status.id !== 3 && r.status.id !== 6
                        ? "wait"
                        : r.status.id === 7
                        ? "done"
                        : "load"
                    }.png`}
                  />
                  {r.name}
                </div>
                <div className="reports-item-col">
                  <img alt="Status" src={`/images/pic.png`} />
                  <strong>{intl.get("statusReport")}</strong>: {r.status.name}
                </div>
                <div className="reports-item-btn">
                  <Button
                    onClick={() => open({ id: r.id, onSubmit: toInitialPage })}
                    type="link"
                  >
                    Copy
                  </Button>
                  <ReportButton id={r.id} status={r.status} lang={lang} />
                  <Icon
                    className="companiesListConfirmButton"
                    type="close-circle"
                    onClick={handleOpenModalWindow.bind(this, r)}
                  />
                </div>
              </div>
            ))}
            {reports.total > 5 && (
              <Pagination
                className="reportsListPagination"
                current={nav.current}
                total={reports.total}
                pageSize={5}
                onChange={changePage}
              />
            )}
          </>
        )}
        <Button
          type="primary"
          size={"large"}
          className="reports-add"
          onClick={addReport}
        >
          <Icon type="plus-circle" className="iconAddONButton" />
          {intl.get("reportsAdd")}
        </Button>
      </Content>
      <ModalWindow
        title={intl.get("warningRemoveItemModalTitle")}
        visible={modal.openModal}
        onOk={handleReportDelete}
        onCancel={() => {
          setModal(s => ({ ...s, openModal: false }));
        }}
      />
      <ModalCopy />
    </Layout>
  );
};

export default Reports;
