import React from "react";
import PropTypes from "prop-types";
import { Card, Icon, List } from "antd";
const CountryBlockItem = props => {
  return (
    <>
      <Card
        size="small"
        className="companyInfoBlockItemCard"
        headStyle={{
          borderBottom: "none"
        }}
        title={
          <>
            <div className="cardTitleForCompanyIfoBlock">
              <div className="imageIconBlockWithCircle">
                <div className="companyInfoBlockCircle" />
                <Icon type="picture" className="infoBlockIcon" />
              </div>
              <p className="cardCompanyInfoBlockTitle">{props.title}:</p>
            </div>
          </>
        }
      >
        <div className="contentForCarItem">
          {props.content && (
            <List
              className="companyProfileBlockItemList"
              dataSource={[
                ...new Set(
                  props.content.map(c => (c.country ? c.country.name : ""))
                )
              ]}
              bordered={false}
              renderItem={(item, i) => (
                <List.Item
                  className="companyProfileItemBlockListItem"
                  key={item}
                >
                  <p className="companyProfileBlockItemString">{item}</p>
                </List.Item>
              )}
            />
          )}
        </div>
      </Card>
    </>
  );
};

CountryBlockItem.propTypes = {
  title: PropTypes.string.isRequired,
  openEditModalWindow: PropTypes.func,
  editableItem: PropTypes.object.isRequired
};

CountryBlockItem.defaultProps = {
  openEditModalWindow: () => {}
};

export default CountryBlockItem;
