import React from "react";
import PropTypes from "prop-types";
import { Card, Icon } from "antd";
import intl from "react-intl-universal";

const BusinessTypeBlockItem = props => {
  const handleOpenModalWindow = () => {
    props.openEditModalWindow(props.editableItem);
  };

  return (
    <>
      <Card
        size="small"
        className="companyInfoBlockItemCard"
        headStyle={{
          borderBottom: "none"
        }}
        title={
          <>
            <div className="cardTitleForCompanyIfoBlock">
              <div className="imageIconBlockWithCircle">
                <div className="companyInfoBlockCircle" />
                <Icon type="picture" className="infoBlockIcon" />
              </div>
              <p className="cardCompanyInfoBlockTitle">{props.title}:</p>
            </div>
          </>
        }
        extra={
          <p className="infoBlockActionItem" onClick={handleOpenModalWindow}>
            {intl.get("companyInfoBlockActionEdit")}
          </p>
        }
      >
        <div className="contentForCarItem">
          {props.content && (
            <p className="companyProfileBlockItemString">
              {props.content?.name}
            </p>
          )}
        </div>
      </Card>
    </>
  );
};

BusinessTypeBlockItem.propTypes = {
  title: PropTypes.string.isRequired,
  openEditModalWindow: PropTypes.func,
  editableItem: PropTypes.object.isRequired
};

BusinessTypeBlockItem.defaultProps = {
  openEditModalWindow: () => {}
};

export default BusinessTypeBlockItem;
