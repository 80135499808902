import React, { useState, useCallback, useEffect } from "react";
import { Layout, Spin, Input, Button, List, Modal } from "antd";

import ErrorNotification from "../../components/UI/Notifications/ErrorNotification";
import LisItem from "./LisItem";
import Synonyms from "./Synonyms";
import { globalKeywordsSearch, getAllSynonyms } from "../../services/api";

import "./GlobalKeywordSearch.scss";

const { Content } = Layout;

const initialState = {
  inited: false,
  keywordsList: [],
  searchInput: "",
  chosenKeywordItemId: "",
  openKeywordsModalWindow: false,
  synonymsList: [],
  chosenKeywordItem: null
};

const GlobalKeywordSearch = props => {
  const [state, setState] = useState(initialState);

  const handleGetKeywords = useCallback(
    async keyWordName => {
      try {
        const keywordName = keyWordName || "";
        const getAllKeywordsItems = await globalKeywordsSearch(keywordName);
        setState(state => {
          return {
            ...state,
            keywordsList: getAllKeywordsItems.data.items || [],
            inited: true
          };
        });
      } catch (error) {
        ErrorNotification({ text: error });
      }
    },
    [setState]
  );

  useEffect(() => {
    handleGetKeywords();
  }, [handleGetKeywords]);

  const handleChangeSearchInput = useCallback(
    e => {
      e.persist();
      setState(state => {
        return {
          ...state,
          searchInput: e.target.value
        };
      });
    },
    [setState]
  );

  const handleSearchButton = useCallback(() => {
    setState(state => {
      return {
        ...state,
        inited: false
      };
    });
    handleGetKeywords(state.searchInput);
  }, [handleGetKeywords, state.searchInput]);

  const chooseKeywordItem = useCallback(
    value => {
      setState(state => {
        return {
          ...state,
          chosenKeywordItemId: value.id,
          chosenKeywordItem: value
        };
      });
    },
    [setState]
  );
  const handleClose = useCallback(() => {
    setState(state => {
      return {
        ...state,
        openKeywordsModalWindow: false
      };
    });
  }, [setState]);

  const getSynonymsForChosedKeyword = useCallback(
    async keywordId => {
      try {
        const res = await getAllSynonyms(
          state.chosenKeywordItemId || keywordId
        );
        setState(state => {
          return {
            ...state,
            openKeywordsModalWindow: true,
            synonymsList: res.data.items || []
          };
        });
      } catch (error) {
        ErrorNotification({ text: error });
      }
    },
    [state, setState]
  );

  const handleOpenSynonymsListModal = useCallback(
    keywordId => {
      getSynonymsForChosedKeyword(keywordId);
    },
    [getSynonymsForChosedKeyword]
  );

  if (!state.inited) {
    return (
      <Layout className="layoutForSpinner">
        <Spin size="large" className="spinComponentStyle" />
      </Layout>
    );
  }
  function handleTextSearch(e) {
    if (e.key === "Enter") {
      handleSearchButton();
    }
  }
  return (
    <>
      <Layout className="removeBackGroundOnLayout">
        <Content>
          <div className="globalKeywordSearchButtonsBlock">
            <div className="searchInputsBlock">
              <Input
                value={state.searchInput}
                className="globalKeywordSearchSearchInput"
                onChange={handleChangeSearchInput}
                onKeyDown={handleTextSearch}
              />
              <Button
                className="globalKeywordSearchSearchButton"
                shape="circle"
                onClick={handleSearchButton}
                type="primary"
                icon="search"
              />
            </div>
          </div>
          <div className="globalKeywordsListBlock">
            <List
              itemLayout="horizontal"
              pagination={{
                position: "bottom",
                pageSize: 15
              }}
              dataSource={state.keywordsList}
              renderItem={item => (
                <LisItem
                  key={item.id}
                  lang={props.lang}
                  updateKeywordList={handleSearchButton}
                  handleDeleteItem={() => {}}
                  listItem={item}
                  goToNext={handleOpenSynonymsListModal}
                  selectedItem={state.chosenKeywordItemId}
                  selectListItem={chooseKeywordItem}
                >
                  {item.name}
                </LisItem>
              )}
            />
          </div>
        </Content>
      </Layout>
      <Modal
        visible={state.openKeywordsModalWindow}
        className="modalWindowForKeywords"
        onCancel={handleClose}
        footer={null}
      >
        <Synonyms
          getSynonymsForChosedKeyword={getSynonymsForChosedKeyword}
          keywordCategory={state.chosenKeywordItemId}
          match={props.match}
          chosenKeywordItem={state.chosenKeywordItem}
          keywordGroupName={
            state.chosenKeywordItem ? state.chosenKeywordItem.name : ""
          }
          synonymsList={state.synonymsList}
          onCancel={handleClose}
        />
      </Modal>
    </>
  );
};
export default GlobalKeywordSearch;
