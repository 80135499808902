import React, { Component } from "react";
import { Spin, Layout } from "antd";
import intl from "react-intl-universal";
import AddDataSource from "../../components/AddDataSource";
import ErrorNotification from "../../components/UI/Notifications/ErrorNotification";
import SuccessNotification from "../../components/UI/Notifications/SuccessNotification";
import {
  getDataSources,
  addDataSource,
  removeDataSource
} from "../../services/api";
import {
  exportDataSource,
  importDataSourceList
} from "../../services/importExportApi";

class AddDataSourceContainer extends Component {
  state = {
    inputValue: "",
    inited: false,
    savedDataSource: [],
    total: 0,
    currentPage: 1
  };
  componentDidMount = () => {
    this.updateDataSourceList(10, 0);
  };
  handleChangeInput = e => {
    this.setState({ inputValue: e.target.value });
  };
  saveDataSource = async () => {
    const { inputValue } = this.state;
    try {
      if (inputValue !== "") {
        await addDataSource({ value: inputValue });
        this.setState({
          inited: false
        });
        SuccessNotification({
          text: intl.get("successMessageForNotificationWindow")
        });
        this.updateDataSourceList(10, 0);
      }
    } catch (error) {
      ErrorNotification({ text: error });
    }
  };
  removeDataSource = async item => {
    try {
      await removeDataSource(item.id);
      this.setState({
        inited: false
      });
      SuccessNotification({
        text: intl.get("successMessageForNotificationWindow")
      });
      this.updateDataSourceList(10, 0);
    } catch (error) {
      ErrorNotification({ text: error });
    }
  };
  updateDataSourceList = async (limit, offset) => {
    try {
      const allDataSources = await getDataSources({ limit, offset });
      this.setState({
        savedDataSource: allDataSources.items || [],
        currentPage: offset / limit + 1,
        total: allDataSources.total || 0,
        inited: true,
        inputValue: ""
      });
    } catch (error) {
      ErrorNotification({ text: error });
    }
  };
  handleExportDataSource = async () => {
    try {
      await exportDataSource();
    } catch (error) {
      SuccessNotification({
        text: intl.get("successMessageForNotificationWindow")
      });
    }
  };
  handleChangeUploadButton = async uploadFile => {
    try {
      await importDataSourceList(uploadFile);
      SuccessNotification({
        text: intl.get("successMessageForNotificationWindow")
      });
      this.updateDataSourceList(10, 0);
    } catch (error) {
      ErrorNotification({ text: error });
    }
  };
  render() {
    const { inited, savedDataSource, total, currentPage } = this.state;
    if (!inited) {
      return (
        <Layout className="layoutForSpinner">
          <Spin size="large" className="spinComponentStyle" />
        </Layout>
      );
    }
    return (
      <React.Fragment>
        <AddDataSource
          dataSourceTotal={total}
          paginationPage={currentPage}
          dataSourceList={savedDataSource}
          heandleChangeInput={this.handleChangeInput}
          handlePageChange={this.updateDataSourceList}
          heandleSaveButton={this.saveDataSource}
          handleExportDataSource={this.handleExportDataSource}
          heandleRemoveDataSource={this.removeDataSource}
          handleChangeUploadButton={this.handleChangeUploadButton}
        />
      </React.Fragment>
    );
  }
}

export default AddDataSourceContainer;
