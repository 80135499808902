import React, { useEffect, useState } from "react";
import { Button, Select } from "antd";
import Modal from "../../components/UI/ModalWindow/ModalWithHook";
import { useModal } from "../../hooks/useModal";
import { getReports, updateReportAccess } from "../../services/api";
import { Item, Label } from "./styled";
import ErrorNotification from "../../components/UI/Notifications/ErrorNotification";

const { Option } = Select;
export const USER_CHANGE = "userChange";

function Details({ refetch }) {
  const { close, isOpen, data } = useModal(USER_CHANGE);
  const [reports, setReports] = useState();
  const [selected, setSelected] = useState({});
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    getReports({ offset: 0, limit: 500 })
      .then(res => {
        setReports(res.data?.items);
      })
      .catch(err => ErrorNotification({ text: err }))
      .finally(() => {
        setLoading(false);
      });
  }, []);

  useEffect(() => {
    if (isOpen) {
      const selected = {};
      data.reports.forEach(report => {
        const item = reports.find(i => i.id === report.id);
        if (item) {
          selected[report.id] = item;
        }
      });
      setSelected(selected);
    }
  }, [isOpen]);

  if (!isOpen) return null;

  async function handleChange(id) {
    const item = reports.find(i => i.id === id);
    try {
      await updateReportAccess(id, {
        access: true,
        user_id: data.id
      });
      setSelected(prev => ({ ...prev, [id]: item }));
    } catch (error) {
      ErrorNotification({ text: error });
    }
  }
  async function handleRemoveReport(id) {
    try {
      await updateReportAccess(id, {
        access: false,
        user_id: data.id
      });
      const { [id]: val, ...rest } = selected;
      setSelected(rest);
    } catch (error) {
      ErrorNotification({ text: error });
    }
  }
  function onclose() {
    refetch();
    close();
  }
  return (
    <Modal
      name={USER_CHANGE}
      handleSubmit={onclose}
      handleOnClose={onclose}
      width={1000}
    >
      <>
        <h1>{data?.name || "User"}’s Access to the reports</h1>
        <Label>
          <span>Access to the Reports</span>
          <Select
            showSearch
            loading={loading}
            placeholder="Choose report"
            onChange={handleChange}
            style={{ width: "100%", marginBottom: "18px" }}
            value={false}
            filterOption={(input, option) =>
              option.props.children
                .toLowerCase()
                .indexOf(input.toLowerCase()) >= 0
            }
          >
            {reports.map(({ id, name }) =>
              !selected[id] ? (
                <Option key={id} value={id}>
                  {name}
                </Option>
              ) : null
            )}
          </Select>
        </Label>
        {Object.values(selected).map(i => (
          <Item>
            {i.name}{" "}
            <Button onClick={() => handleRemoveReport(i.id)}>
              revoke access
            </Button>
          </Item>
        ))}
      </>
    </Modal>
  );
}
export default Details;
