import styled from "styled-components";
import { Button as _Button } from "antd";

export const Title = styled.h3`
  font-weight: 500;
  font-size: 14px;
  line-height: 16px;

  color: #4a5360;
  padding-bottom: 24px;
`;
export const SelectWrap = styled.div`
  padding-bottom: 8px;
  padding-top: 8px;
`;
