import React from "react";
import PropTypes from "prop-types";
import { Card, Icon, List } from "antd";
import intl from "react-intl-universal";
import "./CompanyInfoBlock.scss";

function CompanyInfoItemBlock(props) {
  const handleOpenModalWindow = () => {
    props.openEditModalWindow(props.editableItem);
  };
  return (
    <>
      <Card
        size="small"
        className="companyInfoBlockItemCard"
        headStyle={{
          borderBottom: "none"
        }}
        title={
          <>
            <div className="cardTitleForCompanyIfoBlock">
              <div className="imageIconBlockWithCircle">
                <div className="companyInfoBlockCircle" />
                <Icon type="picture" className="infoBlockIcon" />
              </div>
              <p className="cardCompanyInfoBlockTitle">{props.title}:</p>
            </div>
          </>
        }
        extra={
          <p className="infoBlockActionItem" onClick={handleOpenModalWindow}>
            {intl.get("companyInfoBlockActionEdit")}
          </p>
        }
      >
        <div className="contentForCarItem">
          {Array.isArray(props.content) ? (
            <>
              {props.editableItem.filedName === "address" ? (
                <>
                  <List
                    dataSource={props.content}
                    bordered={false}
                    renderItem={(item, index) => (
                      <List.Item
                        className="companyProfileItemBlockListItem"
                        key={index}
                      >
                        <p className="companyProfileBlockItemString">
                          {item.address}
                        </p>
                      </List.Item>
                    )}
                  />
                </>
              ) : (
                <>
                  <List
                    className="companyProfileBlockItemList"
                    dataSource={props.content}
                    bordered={false}
                    renderItem={(item, index) => (
                      <List.Item
                        className="companyProfileItemBlockListItem"
                        key={index}
                      >
                        <p className="companyProfileBlockItemString">{item}</p>
                      </List.Item>
                    )}
                  />
                </>
              )}
            </>
          ) : (
            <>
              {props.editableItem.filedName === "is_client" ? (
                <>
                  {typeof props.content === "boolean" && props.content
                    ? "Yes"
                    : "No"}
                </>
              ) : (
                <p className="companyProfileBlockItemString">{props.content}</p>
              )}
            </>
          )}
        </div>
      </Card>
    </>
  );
}

CompanyInfoItemBlock.propTypes = {
  title: PropTypes.string.isRequired,
  openEditModalWindow: PropTypes.func,
  editableItem: PropTypes.object.isRequired
};

CompanyInfoItemBlock.defaultProps = {
  openEditModalWindow: () => {}
};

export default CompanyInfoItemBlock;
