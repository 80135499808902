import { useEffect, useState } from "react";
import {
  autocompleteGetItems,
  getBusinessTypeList,
  getLanguageList,
  getCountriesList,
  getIndustryList,
  getBusinessModelList
} from "../../../services/api";

function useIniData() {
  const [loading, setLoading] = useState(true);
  const [data, setData] = useState();

  useEffect(() => {
    Promise.all([
      getBusinessTypeList(),
      autocompleteGetItems({
        model: "datasources",
        name: "",
        limit: 5
      }),
      getLanguageList(),
      getCountriesList(),
      getIndustryList(),
      getBusinessModelList(),
      autocompleteGetItems({
        model: "product_types",
        name: "",
        limit: 5
      })
    ])
      .then(res => {
        setData({
          businessType: res[0].data.items,
          dataSources: res[1].data.items,
          languages: res[2].items,
          countries: res[3].data.items,
          industry: res[4].items,
          businessModel: res[5].data.items,
          productType: res[6].data.items
        });
        setLoading(false);
      })
      .catch(err => console.error({ err }));
  }, []);
  return [data, loading];
}

export default useIniData;
