import React, { useCallback } from "react";
import intl from "react-intl-universal";
import { Typography } from "antd";
import { confirmTaxonomy } from "../../services/api";
import Topology from "../CompanyProfile/Topology";
import ErrorNotification from "../../components/UI/Notifications/ErrorNotification";
import { ReportNameAndStatus } from "./ReportNameAndStatus";

const Taxonomy = ({ report, next }) => {
  const handleNext = useCallback(async () => {
    try {
      await confirmTaxonomy(report.id);
      next();
    } catch (text) {
      ErrorNotification({ text });
    }
  }, [next, report.id]);
  return (
    <>
      {report && report.name && <ReportNameAndStatus report={report} />}
      <Typography.Title>{intl.get("reportsTaxonomy")}</Typography.Title>
      <Topology
        type="taxonomy"
        companyId={+report.id}
        prospect={report.prospect}
      />
      <div className={"reports-next"} onClick={handleNext}>
        {intl.get("reportNext")}
      </div>
    </>
  );
};

export default Taxonomy;
