import React from "react";

import { Layout, Spin } from "antd";

const Loader = () => {
  return (
    <Layout className="layoutForSpinner">
      <Spin size="large" className="spinComponentStyle" />
    </Layout>
  );
};

export default Loader;
