import React, { useState, useCallback, useEffect } from "react";
import intl from "react-intl-universal";
import {
  Layout,
  Spin,
  Collapse,
  Icon,
  Button,
  Modal,
  Select,
  InputNumber
} from "antd";
import { useParams } from "react-router";
import isEmpty from "lodash.isempty";

import {
  getCategoriesListItemsForCompanyByIds,
  getKeywordsListForCompanyByIds,
  getIndustryListForCompanyById,
  getSegmentListForCompanyByIds,
  removeKeywordItemFromCompany,
  getCategoriesListItemsForTaxonomyByIds,
  getKeywordsListForTaxonomyByIds,
  getIndustryListForTaxonomyById,
  getSegmentListForTaxonomyByIds,
  removeKeywordItemFromTaxonomy,
  getAllKeywordGroup,
  addKeywordGroupToCompany,
  addKeywordItemInTaxonomy
} from "../../../services/api";

import ErrorNotification from "../../../components/UI/Notifications/ErrorNotification";
import KeywordListItem from "./KeywordListItem";
import HeaderForPanel from "./HeaderForPanel";
import "./CompanyProfileTypology.scss";

const { Panel } = Collapse;

const CompanyProfileTypology = props => {
  const { id } = useParams();
  const [state, setState] = useState({
    industryList: [],
    segmentList: [],
    keywordList: [],
    inited: false,
    industryTabId: "",
    openModalWindow: false,
    keywordListItems: [],
    chosenKeywordId: undefined,
    activeIndustryKey: ""
  });

  const handleChangeIndustry = useCallback(
    async key => {
      if (key) {
        try {
          const getSegmentsForChosenIndustry = await (props.type === "taxonomy"
            ? getSegmentListForTaxonomyByIds
            : getSegmentListForCompanyByIds)(props.companyId, key);
          setState(state => {
            return {
              ...state,
              industryTabId: key,
              activeIndustryKey: key,
              segmentList: getSegmentsForChosenIndustry.data.items || []
            };
          });
        } catch (error) {
          ErrorNotification({ text: error });
        }
      }
    },
    [setState, props]
  );

  const handleChangeSegment = key => {
    if (key) findCategoryForCurrentSegment(key);
  };

  const findCategoryForCurrentSegment = useCallback(
    async segmentId => {
      try {
        const categoryList = await (props.type === "taxonomy"
          ? getCategoriesListItemsForTaxonomyByIds
          : getCategoriesListItemsForCompanyByIds)(props.companyId, segmentId);
        setState(state => {
          return {
            ...state,
            categoryList: categoryList.data.items || []
          };
        });
      } catch (error) {
        ErrorNotification({ text: error });
      }
    },
    [props]
  );

  const handleChangeCategory = key => {
    if (key) findKeywordsForCurrentSegment(key);
  };

  const findKeywordsForCurrentSegment = useCallback(
    async categoryId => {
      try {
        const keywordsList = await (props.type === "taxonomy"
          ? getKeywordsListForTaxonomyByIds
          : getKeywordsListForCompanyByIds)(props.companyId, categoryId);
        setState(state => {
          return {
            ...state,
            activeCategoryId: categoryId,
            keywordList: keywordsList.data.items || []
          };
        });
      } catch (error) {
        ErrorNotification({ text: error });
      }
    },
    [props]
  );

  const handleGetIndustryListForCurrentCompany = useCallback(async () => {
    try {
      setState(state => {
        return {
          ...state,
          inited: false
        };
      });
      await (props.type === "taxonomy"
        ? getIndustryListForTaxonomyById
        : getIndustryListForCompanyById)(props.companyId).then(result => {
        setState(state => {
          return {
            ...state,
            industryList: result.data.items || [],
            inited: true
          };
        });
      });
    } catch (error) {
      ErrorNotification({ text: error });
    }
  }, [setState, props]);

  useEffect(() => {
    handleGetIndustryListForCurrentCompany();
  }, [handleGetIndustryListForCurrentCompany]);

  const handleRemoveKeywordItem = useCallback(
    async itemId => {
      try {
        await (props.type === "taxonomy"
          ? removeKeywordItemFromTaxonomy
          : removeKeywordItemFromCompany)(itemId);

        await findKeywordsForCurrentSegment(state.activeCategoryId);
      } catch (error) {
        ErrorNotification({ text: error });
      }
    },
    [props.type, findKeywordsForCurrentSegment, state.activeCategoryId]
  );
  const handleGetKeywordForChosenCategory = useCallback(async () => {
    try {
      const getAllKeywordFromDB = await getAllKeywordGroup(
        state.activeCategoryId
      );
      setState(state => {
        return {
          ...state,
          keywordListItems: getAllKeywordFromDB.data.items || []
        };
      });
    } catch (error) {
      ErrorNotification({ text: error });
    }
  }, [state, setState]);
  const handleOpenModalWindow = useCallback(() => {
    setState(state => {
      return {
        ...state,
        openModalWindow: true
      };
    });
    handleGetKeywordForChosenCategory();
  }, [handleGetKeywordForChosenCategory]);

  const handleClose = useCallback(() => {
    setState(state => {
      return {
        ...state,
        openModalWindow: false,
        keywordSemantic: "",
        keywordCount: "",
        chosenKeywordId: undefined
      };
    });
  }, [setState]);

  const handleChangeKeywordSelect = useCallback(
    itemId => {
      setState(state => {
        return {
          ...state,
          chosenKeywordId: itemId
        };
      });
    },
    [setState]
  );

  const confirmModalAddKeywordToCompany = useCallback(async () => {
    try {
      const data = {
        semantic: Number(state.keywordSemantic),
        count: Number(state.keywordCount),
        keyword_groups_id: Number(state.chosenKeywordId)
      };

      if (props.type === "taxonomy") {
        data.report_id = Number(id);
      } else {
        data.company_id = Number(props.companyId);
      }

      await (props.type === "taxonomy"
        ? addKeywordItemInTaxonomy
        : addKeywordGroupToCompany)(data);

      handleClose();
      await findKeywordsForCurrentSegment(state.activeCategoryId);
    } catch (error) {
      ErrorNotification({ text: error });
    }
  }, [
    state.keywordSemantic,
    state.keywordCount,
    props.type,
    state.chosenKeywordId,
    state.activeCategoryId,
    props.companyId,
    handleClose,
    findKeywordsForCurrentSegment,
    id
  ]);

  const handleAddNewSegmentToCompanyTopology = useCallback(() => {
    props.handleOpenAddSegmentModal(state.industryTabId);
    setState(state => {
      return {
        ...state,
        activeIndustryKey: "0101010"
      };
    });
  }, [props, state.industryTabId]);

  if (!state.inited) {
    return (
      <Layout className="layoutForSpinnerCompanyProfileTopologyBlock">
        <Spin
          size="large"
          className="spinComponentForCompanyProfileTopologyBlock"
        />
      </Layout>
    );
  }

  return (
    <>
      <Collapse
        accordion
        activeKey={state.activeIndustryKey}
        onChange={handleChangeIndustry}
        className="companyProfileTypologyPanel"
      >
        {state.industryList.map((industryItem, index) => (
          <Panel
            className="companyProfileTypologyPanelStyle"
            key={industryItem.id}
            header={
              <HeaderForPanel
                headerFor={"industry"}
                name={industryItem.name}
                segmentsCount={industryItem.segmentCount}
                categoriesCount={industryItem.categoryCount}
                keywordsCount={industryItem.keyword_groupsCount}
              />
            }
          >
            <Collapse
              accordion
              onChange={handleChangeSegment}
              className="companyProfileTypologyPanel"
            >
              {!isEmpty(state.segmentList)
                ? state.segmentList.map((segmentItem, index) => (
                    <Panel
                      className="companyProfileTypologyPanelStyle"
                      header={
                        <HeaderForPanel
                          headerFor={"segment"}
                          name={segmentItem.name}
                          segmentsCount={0}
                          categoriesCount={segmentItem.categoryCount}
                          keywordsCount={segmentItem.keyword_groupsCount}
                        />
                      }
                      key={segmentItem.id}
                    >
                      <Collapse
                        accordion
                        onChange={handleChangeCategory}
                        className="companyProfileTypologyPanel"
                      >
                        {!isEmpty(state.categoryList)
                          ? state.categoryList.map((categoryItem, index) => (
                              <Panel
                                className="companyProfileTypologyPanelStyle"
                                header={
                                  <HeaderForPanel
                                    headerFor={"category"}
                                    name={categoryItem.name}
                                    segmentsCount={0}
                                    categoriesCount={0}
                                    keywordsCount={
                                      categoryItem.keyword_groupsCount
                                    }
                                  />
                                }
                                key={categoryItem.id}
                              >
                                {!isEmpty(state.keywordList)
                                  ? state.keywordList.map(
                                      (keywordItem, index) => (
                                        <KeywordListItem
                                          type={props.type}
                                          handleRemoveItem={
                                            handleRemoveKeywordItem
                                          }
                                          editable={props.editable}
                                          item={keywordItem}
                                          key={keywordItem.id}
                                        />
                                      )
                                    )
                                  : null}
                                <Button
                                  type="primary"
                                  size={"large"}
                                  className="addNewIndustryButtonOnCompanyProfile"
                                  onClick={handleOpenModalWindow}
                                >
                                  <Icon
                                    type="plus-circle"
                                    className="iconAddONButton"
                                  />
                                  {intl.get("keywordsAddNewButtonName")}
                                </Button>
                              </Panel>
                            ))
                          : null}
                      </Collapse>
                      {/*<Button
                      type="primary"
                      size={"large"}
                      className="addNewIndustryButtonOnCompanyProfile"
                      onClick={() => { }}
                    >
                      <Icon type="plus-circle" className="iconAddONButton" />
                      {intl.get("categoryAddCategoryItemButton")}
                    </Button>*/}
                    </Panel>
                  ))
                : null}
            </Collapse>
            {props.type === "company" && (
              <Button
                type="primary"
                size={"large"}
                className="addNewIndustryButtonOnCompanyProfile"
                onClick={handleAddNewSegmentToCompanyTopology}
              >
                <Icon type="plus-circle" className="iconAddONButton" />
                {intl.get("addSegmentButtonName")}
              </Button>
            )}
          </Panel>
        ))}
      </Collapse>
      {props.type === "company" && (
        <Button
          type="primary"
          size={"large"}
          className="addNewIndustryButtonOnCompanyProfile"
          onClick={props.handleOpenAddIndustryModal}
        >
          <Icon type="plus-circle" className="iconAddONButton" />
          {intl.get("addIndustry")}
        </Button>
      )}
      <Modal
        title="Chose keyword item"
        visible={state.openModalWindow}
        onOk={confirmModalAddKeywordToCompany}
        onCancel={handleClose}
      >
        <div>
          <span>Choose keyword:</span>
          <Select
            showSearch
            className="addWordItemToCompanyKeywordSelect"
            placeholder="Select a segment"
            optionFilterProp="children"
            value={state.chosenKeywordId}
            onChange={handleChangeKeywordSelect}
            filterOption={(input, option) =>
              option.props.children
                .toLowerCase()
                .indexOf(input.toLowerCase()) >= 0
            }
          >
            {state.keywordListItems.map(keywordItem => (
              <Select.Option key={keywordItem.id} value={keywordItem.id}>
                {keywordItem.name}
              </Select.Option>
            ))}
          </Select>
          <span>Enter count:</span>
          <InputNumber
            className="addDataSourceInput"
            placeholder="Add count"
            value={state.keywordCount}
            onChange={value => {
              setState(state => {
                return {
                  ...state,
                  keywordCount: value
                };
              });
            }}
          />
          <span>Enter semantic:</span>
          <InputNumber
            className="addDataSourceInput"
            placeholder="Add semantic"
            value={state.keywordSemantic}
            onChange={value => {
              setState(state => {
                return {
                  ...state,
                  keywordSemantic: value
                };
              });
            }}
          />
        </div>
      </Modal>
    </>
  );
};

export default CompanyProfileTypology;
