import React, { useCallback } from "react";
import PropTypes from "prop-types";
import { Select, Radio, Menu } from "antd";
import debounce from "lodash.debounce";

const { SubMenu } = Menu;

const SelectWithRadio = props => {
  const {
    options,
    editable,
    label,
    subMenuKey,
    onChange,
    sectionName,
    values,
    triggerOptionsLoad,
    isSearch,
    tags,
    ...other
  } = props;

  const radioOptions = [
    { value: "is", name: "And" },
    { value: "contains", name: "Or" },
    { value: "not_contains", name: "Not contain" }
  ];
  const handleChange = (field, value) => {
    if (field === "value") {
      triggerOptionsLoad("", 80);
    }
    onChange(`${sectionName}.${field}`, value);
  };

  const onSearch = useCallback(
    debounce(search => {
      triggerOptionsLoad(search, 80);
    }, 500),
    []
  );

  return (
    <SubMenu
      className="subMenuItem"
      key={subMenuKey}
      {...other}
      title={<span>{label}:</span>}
    >
      <Select
        mode={tags ? "tags" : "multiple"}
        onFocus={
          !isSearch ? triggerOptionsLoad : () => triggerOptionsLoad("", 80)
        }
        value={values.select}
        filterOption={!isSearch}
        optionFilterProp="name"
        className="12"
        onSearch={str => isSearch && onSearch(str)}
        disabled={!editable}
        onChange={value => {
          handleChange("value", value);
        }}
      >
        {options.map((item, index) => (
          <Select.Option
            key={index}
            name={item.name || item.value}
            value={Number(item.id)}
          >
            {sectionName === "keyword_groups" ? (
              <div>
                <span>{item.name || item.value}</span>
                <p className="companyFiltersTopologyPath">
                  {item.industry_name +
                    "/" +
                    item.segment_name +
                    "/" +
                    item.category_name}
                </p>
              </div>
            ) : (
              item.name || item.value
            )}
          </Select.Option>
        ))}
      </Select>
      <Radio.Group
        onChange={e => handleChange("rule", e.target.value)}
        className="companiesFiltersRadioButtonsGroup"
        disabled={!editable}
        value={values.radio}
      >
        {radioOptions.map(({ value, name }) => (
          <Radio
            key={value}
            className="companiesFiltersRadioButton"
            value={value}
          >
            {name}
          </Radio>
        ))}
      </Radio.Group>
    </SubMenu>
  );
};

SelectWithRadio.propTypes = {
  label: PropTypes.string.isRequired,
  editable: PropTypes.bool,
  options: PropTypes.array,
  subMenuKey: PropTypes.string,
  onChange: PropTypes.func,
  sectionName: PropTypes.string.isRequired,
  values: PropTypes.object
};
SelectWithRadio.defaultProps = {
  editable: true,
  options: [],
  subMenuKey: "Key",
  sectionName: "Name",
  label: "Sub menu label",
  onChange: () => null,
  values: {
    select: {},
    radio: ""
  }
};

export default SelectWithRadio;
