import React from "react";
import { Form, Select, Button } from "antd";
import { getLanguageList } from "../../services/api";

class DynamicFieldSet extends React.Component {
  state = {
    dataSourceList: []
  };
  componentDidMount = async () => {
    try {
      const getAllDataSourceItems = await getLanguageList();
      this.setState({
        dataSourceList: getAllDataSourceItems.items || []
      });
    } catch (error) {
      console.log(error);
    }
  };

  handleSubmit = e => {
    e.preventDefault();
    this.props.form.validateFields((err, values) => {
      if (!err) {
        const data = {
          [this.props.chosenFiledForEdit]: values[this.props.chosenFiledForEdit]
        };
        this.props.handleSetValues(data);
        this.props.handleCloseModalWindow();
      }
    });
  };

  render() {
    const { dataSourceList } = this.state;
    const { getFieldDecorator } = this.props.form;
    return (
      <Form onSubmit={this.handleSubmit}>
        <Form.Item>
          {getFieldDecorator(`${this.props.chosenFiledForEdit}`, {
            validateTrigger: ["onChange", "onBlur"],
            rules: this.props.validationRules,
            initialValue: this.props.initialData
              ? this.props.initialData
              : undefined
          })(
            <Select
              className="arrayInputComponent"
              mode="multiple"
              style={{ width: "100%" }}
            >
              {dataSourceList.map((item, index) => (
                <Select.Option key={index} value={Number(item.id)}>
                  {item.name}
                </Select.Option>
              ))}
            </Select>
          )}
        </Form.Item>
        <Form.Item>
          <Button
            className="submitButtonOnForm"
            type="primary"
            htmlType="submit"
          >
            Submit
          </Button>
        </Form.Item>
      </Form>
    );
  }
}

const WrappedDynamicFieldSet = Form.create({ name: "dynamic_form_item" })(
  DynamicFieldSet
);
export default WrappedDynamicFieldSet;
