import React, {
  createContext,
  useContext,
  useState,
  useCallback,
  useEffect
} from "react";
import { Spin, Layout } from "antd";
import { useHistory } from "react-router";
import { login, check, refresh, me } from "../services/auth";
import { setup } from "../services/api";
import { setupAuth } from "../services/auth";
import { setupImportExportAxiosInstance } from "../services/importExportApi";
import { LangContext } from "./Lang";
import ErrorNotification from "../components/UI/Notifications/ErrorNotification";

const AUTH_STATE = {
  accessToken: null,
  tryUpdateTokens: () => {},
  doLogin: credentials => {},
  doLogout: () => {}
};

export const AuthContext = createContext(AUTH_STATE);

const getStoredData = () => {
  const emptyData = {
    accessToken: null,
    refreshToken: null
  };
  try {
    return JSON.parse(localStorage.getItem("auth")) || emptyData;
  } catch (e) {
    return emptyData;
  }
};

const setStoredData = (tokens, setTokens, requestErrorHandler) => {
  if (typeof requestErrorHandler === "function") {
    setup(tokens.accessToken, requestErrorHandler);
    setupImportExportAxiosInstance(tokens.accessToken, requestErrorHandler);
  }
  setTokens(tokens);
  try {
    localStorage.setItem("auth", JSON.stringify(tokens));
  } catch (e) {
    console.log(
      "Your browser doesn't support storage, we use it for auto-login feature"
    );
  }
};

export const AuthController = ({ children }) => {
  const { lang } = useContext(LangContext);
  const history = useHistory();
  const [inited, setInited] = useState(false);
  const [{ accessToken, refreshToken }, setTokens] = useState(getStoredData());
  const [user, setUser] = useState({});
  const tryUpdateTokens = useCallback(async () => {
    let accessToken;
    try {
      const tokens = (await refresh(refreshToken)).data;
      setStoredData(tokens, setTokens);
      accessToken = tokens.accessToken;
    } catch (e) {
      history.replace(`/${lang}/login`);
    }
    return accessToken;
  }, [refreshToken, setTokens, history, lang]);

  const doLogin = useCallback(
    async credentials => {
      try {
        const data = (await login(credentials)).data;
        setStoredData(data, setTokens, tryUpdateTokens);
        history.replace(
          data.user.role.name === "user"
            ? `/${lang}/leads`
            : `/${lang}/language`
        );
      } catch (error) {
        ErrorNotification({
          text: error
        });
      }
    },
    [setTokens, tryUpdateTokens, lang, history]
  );

  const doLogout = useCallback(() => {
    const tokens = {
      accessToken: null,
      refreshToken: null
    };
    setStoredData(tokens, setTokens, tryUpdateTokens);
    history.replace(`/${lang}/login`);
  }, [setTokens, history, tryUpdateTokens, lang]);

  useEffect(() => {
    if (accessToken) {
      (async () => {
        try {
          const valid = (await check(accessToken)).data;
          if (!valid) {
            await tryUpdateTokens();
          }
        } catch (e) {
          doLogout();
        }

        setup(accessToken, tryUpdateTokens);
        setupAuth(accessToken, tryUpdateTokens);
        setupImportExportAxiosInstance(accessToken, tryUpdateTokens);
        await me()
          .then(res => {
            setUser(res.data);
            setInited(true);
          })
          .catch(e => {
            doLogout();
          });
      })();
    } else {
      setInited(true);
    }
  }, [accessToken, doLogout, tryUpdateTokens]);

  if (!inited) {
    return (
      <Layout className="layoutForSpinner">
        <Spin size="large" className="spinComponentStyle" />
      </Layout>
    );
  }

  return (
    <AuthContext.Provider
      value={{
        accessToken,
        tryUpdateTokens,
        doLogin,
        doLogout,
        user
      }}
    >
      {children}
    </AuthContext.Provider>
  );
};
