import React, { useCallback } from "react";
import PropTypes from "prop-types";
import { Select, Radio, Menu, Form } from "antd";
import debounce from "lodash.debounce";

const { SubMenu } = Menu;

const SelectAddressFilter = props => {
  const {
    options,
    stateOptions,
    zipOptions,
    label,
    subMenuKey,
    onChange,
    values,
    triggerCountriesLoad,
    triggerStatesLoad,
    triggerZipLoad,
    ...other
  } = props;

  const radioOptions = [
    { value: "is", name: "And" },
    { value: "contains", name: "Or" },
    { value: "not_contains", name: "Not contain" }
  ];

  const handleChange = (section, field, value) => {
    if (section === "country" && field === "value") {
      triggerStatesLoad(value[value.length - 1]);
      triggerZipLoad(value[value.length - 1]);
    }
    onChange(`${section}.${field}`, value);
  };

  const searchZip = useCallback(
    debounce(search => triggerZipLoad(search), 1200),
    [triggerZipLoad]
  );

  return (
    <SubMenu
      className="subMenuItem"
      key={subMenuKey}
      {...other}
      title={<span>{label}:</span>}
    >
      <>
        <Form.Item label="Country">
          <Select
            mode="multiple"
            onFocus={triggerCountriesLoad}
            value={values.country}
            optionFilterProp="name"
            className="12"
            onChange={value => handleChange("country", "value", value)}
          >
            {options.map((item, index) => (
              <Select.Option
                key={index}
                value={Number(item.id)}
                name={item.name || item.value}
              >
                {item.name || item.value}
              </Select.Option>
            ))}
          </Select>
          <Radio.Group
            onChange={e => handleChange("country", "rule", e.target.value)}
            className="companiesFiltersRadioButtonsGroup"
            value={values.counryRadio}
          >
            {radioOptions.map(({ value, name }) => (
              <Radio
                key={value}
                className="companiesFiltersRadioButton"
                value={value}
              >
                {name}
              </Radio>
            ))}
          </Radio.Group>
        </Form.Item>
        {values.country && values.country.length !== 0 && (
          <>
            <Form.Item label="State">
              <Select
                mode="multiple"
                value={values.state}
                optionFilterProp="name"
                className="12"
                onChange={value => handleChange("states", "value", value)}
              >
                {stateOptions.map((item, index) => (
                  <Select.Option
                    key={index}
                    name={item.name || item.value}
                    value={Number(item.id)}
                  >
                    {item.name || item.value}
                  </Select.Option>
                ))}
              </Select>
              <Radio.Group
                onChange={e => handleChange("states", "rule", e.target.value)}
                className="companiesFiltersRadioButtonsGroup"
                value={values.stateRadio}
              >
                {radioOptions.map(({ value, name }) => (
                  <Radio
                    key={value}
                    className="companiesFiltersRadioButton"
                    value={value}
                  >
                    {name}
                  </Radio>
                ))}
              </Radio.Group>
            </Form.Item>
            <Form.Item label="Zipcode">
              <Select
                mode="multiple"
                value={values.zipCode}
                onSearch={searchZip}
                filterOption={false}
                className="12"
                onChange={value => handleChange("zip", "value", value)}
              >
                {zipOptions.map((item, index) => (
                  <Select.Option
                    key={index}
                    name={item.name || item.value}
                    value={Number(item.id)}
                  >
                    {item.zip}
                  </Select.Option>
                ))}
              </Select>
              <Radio.Group
                onChange={e => handleChange("zip", "rule", e.target.value)}
                className="companiesFiltersRadioButtonsGroup"
                value={values.zipRadio}
              >
                {radioOptions.map(({ value, name }) => (
                  <Radio
                    key={value}
                    className="companiesFiltersRadioButton"
                    value={value}
                  >
                    {name}
                  </Radio>
                ))}
              </Radio.Group>
            </Form.Item>
          </>
        )}
      </>
    </SubMenu>
  );
};

SelectAddressFilter.propTypes = {
  label: PropTypes.string.isRequired,
  editable: PropTypes.bool,
  options: PropTypes.array,
  stateOptions: PropTypes.array,
  subMenuKey: PropTypes.string,
  onChange: PropTypes.func,
  sectionName: PropTypes.string.isRequired,
  values: PropTypes.object
};
SelectAddressFilter.defaultProps = {
  editable: true,
  options: [],
  stateOptions: [],
  subMenuKey: "Key",
  sectionName: "Name",
  label: "Sub menu label",
  onChange: () => null,
  values: {
    country: [],
    state: []
  }
};

export default SelectAddressFilter;
