import React from "react";
import { Button, Radio, Form } from "antd";
import intl from "react-intl-universal";

const AddIsSwathlyClient = props => {
  const handleSubmit1 = e => {
    e.preventDefault();
    props.form.validateFields((err, values) => {
      if (!err) {
        props.handleSetValues(values);
        props.handleCloseModalWindow();
      }
    });
  };
  return (
    <Form onSubmit={handleSubmit1} className="companyProfileAddCompanyForm">
      <Form.Item>
        {props.form.getFieldDecorator(props.chosenFiledForEdit, {
          initialValue: props.initialData ? props.initialData : undefined
        })(
          <Radio.Group className="companyProfileAddIsClientField">
            <Radio value={true}>
              {intl.get("companyProfileIsSwathlyClientYes")}
            </Radio>
            <Radio value={false}>
              {intl.get("companyProfileIsSwathlyClientNo")}
            </Radio>
          </Radio.Group>
        )}
      </Form.Item>
      <Form.Item>
        <Button className="submitButtonOnForm" type="primary" htmlType="submit">
          Submit
        </Button>
      </Form.Item>
    </Form>
  );
};

const IsSwathlyClientForm = Form.create({ name: "isClientForm" })(
  AddIsSwathlyClient
);
export default IsSwathlyClientForm;
