import { useModal } from "../../../hooks/useModal";
import Modal from "../../../components/UI/ModalWindow/ModalWithHook";
import React, { useEffect, useState } from "react";
import { Table } from "antd";
import { getExcelLogsItem } from "../../../services/api";

export const DETAILS = "Details";
const limit = 20;

const columns = [
  {
    title: "Company Name",
    dataIndex: "company",
    render: company => <span>{company?.name || "-"}</span>
  },
  {
    title: "Log",
    dataIndex: "message"
  }
];

function Details() {
  const { data, close } = useModal(DETAILS);
  const [loading, setLoading] = useState(true);
  const [list, setList] = useState([]);
  const [offset, setOffset] = useState(0);
  const [pagination, setPagination] = useState({
    defaultPageSize: limit,
    total: 200
  });

  useEffect(() => {
    if (data?.id) {
      loadData();
    } else {
      setList([]);
      setOffset(0);
    }
  }, [data?.id, offset]);

  async function loadData() {
    setLoading(true);
    const res = await getExcelLogsItem(data?.id, limit, offset);
    setPagination(prev => ({
      ...prev,
      total: res.data.total,
      current: Math.floor((limit + offset) / limit)
    }));
    setList(res.data.items);
    setLoading(false);
  }

  function handleTableChange(pagination) {
    setOffset((pagination.current - 1) * limit);
  }
  return (
    <Modal name={DETAILS} handleSubmit={() => close()} width={1000}>
      <Table
        rowKey={record => record.id}
        columns={columns}
        dataSource={list}
        loading={loading}
        pagination={pagination}
        onChange={handleTableChange}
      />
    </Modal>
  );
}
export default Details;
