import React, { useState, useEffect, useCallback } from "react";
import { List } from "antd";
import ErrorNotification from "../../../components/UI/Notifications/ErrorNotification";
import {
  getTopWordsForCurrentCompany,
  getTopWordsForCurrentTaxonomy
} from "../../../services/api";

import "./TopKeywordsBlock.scss";

const initialState = {
  topWordsList: []
};

const TopWordsBlock = props => {
  const [state, setState] = useState(initialState);

  const handleGetTopWords = useCallback(async () => {
    try {
      let getTopWordsItemsForCurrentCompany;
      if (props.type === "company") {
        getTopWordsItemsForCurrentCompany = await getTopWordsForCurrentCompany(
          props.companyId
        );
      }
      if (props.type === "taxonomy") {
        getTopWordsItemsForCurrentCompany = await getTopWordsForCurrentTaxonomy(
          props.companyId
        );
      }
      if (getTopWordsItemsForCurrentCompany) {
        setState(state => {
          return {
            ...state,
            topWordsList: getTopWordsItemsForCurrentCompany.data.items || []
          };
        });
      }
    } catch (error) {
      ErrorNotification({ text: error });
    }
  }, [props.type, props.companyId]);

  useEffect(() => {
    handleGetTopWords();
  }, [handleGetTopWords]);

  return (
    <>
      <List
        grid={{
          gutter: 8,
          xs: 1,
          sm: 2,
          md: 3,
          lg: 3,
          xl: 3,
          xxl: 3
        }}
        dataSource={state.topWordsList}
        renderItem={item => (
          <List.Item key={item.id} className="topKeywordsListItem">
            <span className="topKeywordsItemListItemTitle">{item.name}</span>
            <List
              className="topKeywordsListItemsWithBackGround"
              dataSource={item.keyword_groups}
              renderItem={(item, index) => (
                <List.Item className="topKeywordsListItem" key={item.id}>
                  <div
                    className={
                      index === 0
                        ? "firstListItemTopKeywords"
                        : index === 1
                        ? "secondListItemTopKeywords"
                        : "thirdListItemTopKeywords"
                    }
                  >
                    <span>{item.name}</span>
                    <span>{item.count}</span>
                  </div>
                </List.Item>
              )}
            />
          </List.Item>
        )}
      />
    </>
  );
};

export default TopWordsBlock;
