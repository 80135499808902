import React from "react";
import { Button, Input, Form } from "antd";

const InputComponent = props => {
  const handleSubmit1 = e => {
    e.preventDefault();
    props.form.validateFields((err, values) => {
      if (!err) {
        props.handleSetValues(values);
        props.handleCloseModalWindow();
      }
    });
  };
  return (
    <Form onSubmit={handleSubmit1} className="companyProfileAddCompanyForm">
      <Form.Item>
        {props.form.getFieldDecorator(props.chosenFiledForEdit, {
          initialValue: props.initialData,
          rules: props.validationRules
        })(
          props.chosenFiledForEdit === "description" ? (
            <Input.TextArea rows={4} />
          ) : (
            <Input
              type={
                props.chosenFiledForEdit === "employees_count"
                  ? "number"
                  : "text"
              }
            />
          )
        )}
      </Form.Item>
      <Form.Item>
        <Button
          className="submitFormButtonInputForm"
          type="primary"
          htmlType="submit"
        >
          Submit
        </Button>
      </Form.Item>
    </Form>
  );
};

const InputField = Form.create({ name: "someform" })(InputComponent);
export default InputField;
