import React, { useEffect, useContext } from "react";
import { HashRouter } from "react-router-dom";
import { Switch, Route, Redirect } from "react-router";
import { ConfigProvider } from "antd";
import enUS from "antd/es/locale/en_US";
import { AuthController } from "./controllers/Auth";
import { LangController, LangContext } from "./controllers/Lang";
import Login from "./router/Login";
import LanguagePage from "./router/LanguagePage";
import DataSource from "./router/DataSource";
import Typology from "./router/Typology";
import Segment from "./router/Segment";
import Category from "./router/Category";
import Companies from "./router/Companies";
import Leads from "./router/Leads";
import NewCompanyContainer from "./router/NewCompanyContainer";
import Keywords from "./router/Keywords";
import PageWrapperLayout from "./components/UI/PageWrapperLayout";
import PageNotFound from "./router/PageNotFound";
import CompanyProfile from "./router/CompanyProfile/CompanyProfile";
import GlobalKeywordSearch from "./router/GlobalKeywordSearch";
import Reports from "./router/Reports";
import NewReport from "./router/NewReport";
import Report from "./router/Report";
import ExcelLogs from "./router/Logs/Excel";
import Users from "./router/Users";
import CreateUser from "./router/Users/Create";
import "./App.scss";
import { ModalsProvider } from "./hooks/useModal";

function App({ lang }) {
  return (
    <LangController>
      <HashRouter>
        <ModalsProvider>
          <AuthController>
            <Switch>
              <Route
                path={`/:lang(en)`}
                render={({
                  match: {
                    params: { lang }
                  }
                }) => <Providers lang={lang} />}
              />
              <Route
                exact
                path="/"
                render={() => <Redirect to={`${lang}/login`} />}
              />
              <Route path="*">
                <PageNotFound />
              </Route>
            </Switch>
          </AuthController>
        </ModalsProvider>
      </HashRouter>
    </LangController>
  );
}

function Providers({ lang }) {
  const { setLang } = useContext(LangContext);
  useEffect(() => {
    document.querySelector("html").setAttribute("lang", lang);
    setLang(lang);
  }, [lang, setLang]);

  return (
    <ConfigProvider locale={enUS}>
      <Switch>
        <Route exact path={`/${lang}/login`} component={Login} />
        <Route
          exact
          path={`/${lang}/language`}
          render={props => (
            <PageWrapperLayout
              children={<LanguagePage />}
              lang={lang}
              {...props}
            />
          )}
        />
        <Route
          exact
          path={`/${lang}/data-source`}
          render={props => (
            <PageWrapperLayout
              children={<DataSource />}
              lang={lang}
              {...props}
            />
          )}
        />
        <Route
          exact
          path={`/${lang}/companies`}
          render={props => (
            <PageWrapperLayout
              children={<Companies {...props} lang={lang} />}
              lang={lang}
              {...props}
            />
          )}
        />
        <Route
          exact
          path={`/${lang}/leads`}
          render={props => (
            <PageWrapperLayout
              children={<Leads {...props} lang={lang} />}
              lang={lang}
              {...props}
            />
          )}
        />
        <Route
          exact
          path={`/${lang}/addNewCompany`}
          render={props => (
            <PageWrapperLayout
              children={<NewCompanyContainer {...props} lang={lang} />}
              lang={lang}
              {...props}
            />
          )}
        />
        <Route
          exact
          path={`/${lang}/typology`}
          render={props => (
            <PageWrapperLayout
              children={<Typology {...props} lang={lang} />}
              lang={lang}
              {...props}
            />
          )}
        />
        <Route
          exact
          path={`/${lang}/typology/:industryId/segment`}
          render={props => (
            <PageWrapperLayout
              children={<Segment {...props} lang={lang} />}
              lang={lang}
              {...props}
            />
          )}
        />
        <Route
          exact
          path={`/${lang}/typology/:industryId/segment/:segmentId/category`}
          render={props => (
            <PageWrapperLayout
              children={<Category {...props} lang={lang} />}
              lang={lang}
              {...props}
            />
          )}
        />
        <Route
          exact
          path={`/${lang}/typology/:industryId/segment/:segmentId/category/:categoryId/keywords`}
          render={props => (
            <PageWrapperLayout
              children={<Keywords {...props} lang={lang} />}
              lang={lang}
              {...props}
            />
          )}
        />
        <Route
          exact
          path={`/${lang}/companyProfile/:companyId`}
          render={props => (
            <PageWrapperLayout
              children={<CompanyProfile {...props} lang={lang} />}
              lang={lang}
              {...props}
            />
          )}
        />
        <Route
          exact
          path={`/${lang}/keywordSearch`}
          render={props => (
            <PageWrapperLayout
              children={<GlobalKeywordSearch {...props} lang={lang} />}
              lang={lang}
              {...props}
            />
          )}
        />
        <Route
          exact
          path={`/${lang}/reports`}
          render={props => (
            <PageWrapperLayout
              children={<Reports {...props} lang={lang} />}
              lang={lang}
              {...props}
            />
          )}
        />
        <Route
          exact
          path={`/${lang}/reports/new`}
          render={props => (
            <PageWrapperLayout
              children={<NewReport {...props} lang={lang} />}
              lang={lang}
              {...props}
            />
          )}
        />
        <Route
          exact
          path={`/${lang}/reports/:id`}
          render={props => (
            <PageWrapperLayout
              children={<Report {...props} lang={lang} />}
              lang={lang}
              {...props}
            />
          )}
        />
        <Route
          exact
          path={`/${lang}/logs/excel`}
          render={props => (
            <PageWrapperLayout
              children={<ExcelLogs {...props} lang={lang} />}
              lang={lang}
              {...props}
            />
          )}
        />
        <Route
          exact
          path={`/${lang}/users`}
          render={props => (
            <PageWrapperLayout
              children={<Users {...props} lang={lang} />}
              lang={lang}
              {...props}
            />
          )}
        />
        <Route
          exact
          path={`/${lang}/users/create`}
          render={props => (
            <PageWrapperLayout
              children={<CreateUser {...props} lang={lang} />}
              lang={lang}
              {...props}
            />
          )}
        />
      </Switch>
    </ConfigProvider>
  );
}

export default App;
