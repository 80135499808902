import React, { useEffect } from "react";
import { Form, Icon, Button, Input } from "antd";
import { useFormik } from "formik";
import { Table } from "antd";

const createEmptyAddr = () => ({
  name: "",
  title: "",
  email: "",
  phone: "",
  linkedin_link: "",
  facebook_link: "",
  twitter_link: ""
});

const AddContactsFormik = props => {
  const {
    initialData = [],
    handleSetValues,
    handleCloseModalWindow,
    companyName,
    companyId
  } = props;

  const onSubmit = values => {
    const data = {
      persons: values.persons.map(addr => ({
        name: addr.name || undefined,
        title: addr.title || undefined,
        email: addr.email || undefined,
        phone: addr.phone || undefined,
        linkedin_link: addr.linkedin_link || undefined,
        facebook_link: addr.facebook_link || undefined,
        twitter_link: addr.twitter_link || undefined
      }))
    };

    handleSetValues(data);
    handleCloseModalWindow();
  };

  const form = useFormik({
    initialValues: {
      persons: [createEmptyAddr()]
    },
    onSubmit: onSubmit
  });

  useEffect(() => {
    const persons = [];
    (initialData || []).forEach(addr => {
      persons.push({
        id: addr.id || undefined,
        company_id: addr.company_id || undefined,
        name: addr.name || undefined,
        title: addr.title || undefined,
        email: addr.email || undefined,
        phone: addr.phone || undefined,
        linkedin_link: addr.linkedin_link || undefined,
        facebook_link: addr.facebook_link || undefined,
        twitter_link: addr.twitter_link || undefined
      });
    });

    form.setFieldValue("persons", persons);
    // eslint-disable-next-line
  }, []);

  const removeAddress = i =>
    form.setFieldValue(
      "persons",
      form.values.persons.filter((_, ind) => ind !== i)
    );

  const columns = [
    {
      title: "Company ID",
      key: "company_id",
      render: () => <span>{companyId}</span>
    },
    {
      title: "Company name",
      key: "company_name",
      render: () => <span>{companyName}</span>
    },
    {
      title: "Contact ID",
      key: "id",
      dataIndex: "id",
      render: id => <span>{id}</span>
    },
    {
      title: "Contact name",
      key: "name",
      dataIndex: "name",
      render: (name, _, i) => (
        <Input
          className="arrayInputComponent"
          placeholder="name"
          name={`persons.${i}.name`}
          onChange={form.handleChange}
          value={name}
        />
      )
    },
    {
      title: "Title",
      key: "title",
      dataIndex: "title",
      render: (name, _, i) => (
        <Input
          className="arrayInputComponent"
          placeholder="Title"
          name={`persons.${i}.title`}
          onChange={form.handleChange}
          value={name}
        />
      )
    },
    {
      title: "Email",
      key: "email",
      dataIndex: "email",
      render: (name, _, i) => (
        <Input
          className="arrayInputComponent"
          placeholder="Email"
          name={`persons.${i}.email`}
          onChange={form.handleChange}
          value={name}
        />
      )
    },
    {
      title: "Contact phone",
      key: "phone",
      dataIndex: "phone",
      render: (name, _, i) => (
        <Input
          className="arrayInputComponent"
          placeholder="Phone"
          name={`persons.${i}.phone`}
          onChange={form.handleChange}
          value={name}
        />
      )
    },
    {
      title: "Linkedin URL",
      key: "linkedin_link",
      dataIndex: "linkedin_link",
      render: (name, _, i) => (
        <Input
          className="arrayInputComponent"
          placeholder="Linkedin"
          name={`persons.${i}.linkedin_link`}
          onChange={form.handleChange}
          value={name}
        />
      )
    },
    {
      title: "FB",
      key: "facebook_link",
      dataIndex: "facebook_link",
      render: (name, _, i) => (
        <Input
          className="arrayInputComponent"
          placeholder="Facebook"
          name={`persons.${i}.facebook_link`}
          onChange={form.handleChange}
          value={name}
        />
      )
    },
    {
      title: "Twitter",
      key: "twitter_link",
      dataIndex: "twitter_link",
      render: (name, _, i) => (
        <Input
          className="arrayInputComponent"
          placeholder="Twitter"
          name={`persons.${i}.twitter`}
          onChange={form.handleChange}
          value={name}
        />
      )
    },
    {
      title: "Actions",
      key: "actions",
      render: (text, _, i) => (
        <Icon
          className="removeStringIconButton"
          type="minus-circle-o"
          onClick={removeAddress.bind(null, i)}
        />
      )
    }
  ];
  return (
    <>
      <Form onSubmit={form.handleSubmit} className="contacts-modal__wrap">
        {!!form.values.persons.length && (
          <Table
            columns={columns}
            dataSource={form.values.persons}
            pagination={false}
          />
        )}
        <Form.Item>
          <Button
            className="submitButtonOnForm"
            onClick={() =>
              form.setFieldValue("persons", [
                ...form.values.persons,
                createEmptyAddr()
              ])
            }
            type="dashed"
          >
            <Icon type="plus" /> Add field
          </Button>
        </Form.Item>
        <Form.Item>
          <Button
            className="submitButtonOnForm"
            type="primary"
            htmlType="submit"
          >
            Submit
          </Button>
        </Form.Item>
      </Form>
    </>
  );
};

export default AddContactsFormik;
