import React from "react";
import { Typography } from "antd";
export const ReportNameAndStatus = ({ report }) => {
  return (
    <>
      <Typography.Title className="report-name-status">
        Report name: <b>{report.name}</b>
      </Typography.Title>
      <Typography.Title className="report-name-status">
        Report status: <b>{report.status && report.status.name}</b>
      </Typography.Title>
    </>
  );
};
