import React, { useState, useCallback, useEffect } from "react";
import { Layout, Tabs, Button } from "antd";
import intl from "react-intl-universal";
import Loader from "../../components/Loader";
import ViewCompanyProfile from "../NewCompanyContainer/ViewCompanyProfile";
import ErrorNotification from "../../components/UI/Notifications/ErrorNotification";
import SuccessNotification from "../../components/UI/Notifications/SuccessNotification";
import Topology from "../CompanyProfile/Topology";
import Companies from "../Companies";
import {
  getChosenCompanyProfile,
  companyProfileUpdate
} from "../../services/api";
import {
  exportCompaniesOnReport,
  exportFullReport
} from "../../services/importExportApi";
import { ReportNameAndStatus } from "./ReportNameAndStatus";
import CustomInformationForCompletedReport from "./CustomInformationForCompletedReport";

const { TabPane } = Tabs;

const { Content } = Layout;

//TODO: filter adapt for match results
const CompletedReport = ({ report, lang }) => {
  const [loaded, setLoaded] = useState(false);
  const [data, setData] = useState();
  const [tab, setTab] = useState("1");
  const [loadingExportStatus, setLoadingExportStatus] = useState(false);
  const [loadingExportReportStatus, setLoadingExportReportStatus] = useState(
    false
  );
  const handleExport = useCallback(async () => {
    try {
      setLoadingExportReportStatus(true);
      await exportFullReport(report.id).then(() => {
        setLoadingExportReportStatus(false);
        SuccessNotification({ text: "Report was downloaded" });
      });
    } catch (error) {
      ErrorNotification({ text: error });
    }
  }, [report.id]);

  const handleExportCompanies = useCallback(async () => {
    try {
      setLoadingExportStatus(true);
      await exportCompaniesOnReport(report.id).then(() => {
        SuccessNotification({ text: "Report was downloaded" });
      });
      setLoadingExportStatus(false);
    } catch (error) {
      ErrorNotification({ text: error });
      setLoadingExportStatus(false);
    }
  }, [report.id]);

  const handleUpdateCompanyProfile = useCallback(
    async data => {
      try {
        await companyProfileUpdate(report.company_id, data);
        SuccessNotification({
          text: intl.get("successMessageForNotificationWindow")
        });
        const companyProfile = await getChosenCompanyProfile(report.company_id);
        setData(companyProfile.data);
      } catch (error) {
        ErrorNotification({ text: error });
      }
    },
    [report.company_id]
  );

  useEffect(() => {
    setLoaded(false);
    (async () => {
      try {
        if (tab === "1") {
          const companyProfile = await getChosenCompanyProfile(
            report.company_id
          );
          setData(companyProfile.data);
        }
      } catch (text) {
        ErrorNotification({ text });
      }
      setLoaded(true);
    })();
  }, [tab, report.company_id]);

  const handleTab = useCallback(tab => setTab(tab), [setTab]);
  return (
    <Layout>
      <Content className="report-final">
        {report && report.name && <ReportNameAndStatus report={report} />}
        <Tabs
          onChange={handleTab}
          type="card"
          activeKey={tab}
          tabBarStyle={{ textAlign: "left" }}
        >
          <TabPane tab={intl.get("reportTab1")} key="1" />
          <TabPane tab={intl.get("reportTab2")} key="2" />
          <TabPane tab={intl.get("reportTab3")} key="3" />
          <TabPane tab={intl.get("reportTab4")} key="4" />
        </Tabs>
        {!loaded && <Loader />}
        {loaded && tab === "1" && (
          <div className="companyInfoBlockItemsList">
            <ViewCompanyProfile
              handleUpdateCompanyProfile={handleUpdateCompanyProfile}
              companyData={data}
            />
            <Topology
              editable={false}
              type="company"
              companyId={data.id}
              prospect={report.prospect}
            />
          </div>
        )}
        {loaded && tab === "2" && (
          <Topology
            editable={false}
            type="taxonomy"
            companyId={+report.id}
            prospect={report.prospect}
          />
        )}
        {loaded && tab === "3" && (
          <>
            <Button
              loading={loadingExportStatus}
              size={"large"}
              className="companyProfileDownloadProfileButton"
              onClick={handleExportCompanies}
            >
              {intl.get("reportExportCompanies")}
            </Button>
            <Companies
              type="report"
              lang={lang}
              id={+report.id}
              filter={report.filter_archive}
            />
          </>
        )}
        {loaded && tab === "4" && (
          <CustomInformationForCompletedReport
            reportId={report.id}
            initialValue={report.info || ""}
          />
        )}
      </Content>
      <Button
        loading={loadingExportReportStatus}
        size={"large"}
        className="report-export"
        onClick={handleExport}
      >
        {intl.get("reportExport")}
      </Button>
    </Layout>
  );
};

export default CompletedReport;
