import { DataContext, Steps } from "../../Context";
import React, { useContext } from "react";
import { Form, FormItem, Select } from "formik-antd";
import { Formik } from "formik";
import { Button as Submit } from "../../styled";
const { Option } = Select;

const validate = values => {
  const errors = {};
  if (!values.matchBy) errors.matchBy = "This field is required";
  return errors;
};

function Step1() {
  const { setStep, data, updateData } = useContext(DataContext);
  function handleOnSubmit(val) {
    updateData(prev => ({ ...prev, ...val }));
    setStep(Steps.STEP_2);
  }
  return (
    <Formik
      initialValues={{ matchBy: "", ...data }}
      onSubmit={handleOnSubmit}
      render={() => (
        <Form>
          <FormItem name="matchBy" label="Match company by" className="bigger">
            <Select name="matchBy">
              {data.options.matchField.map(i => (
                <Option value={i.field} key={i.field}>
                  {i.name}
                </Option>
              ))}
            </Select>
          </FormItem>
          <Submit type="primary" htmlType="submit" size="large">
            Next
          </Submit>
        </Form>
      )}
      validate={validate}
    />
  );
}
export default Step1;
