import * as React from "react";
import { Spin } from "antd";
import debounce from "lodash.debounce";
import { useState } from "react";
import { autocompleteGetItems } from "../../../../services/api";
import { FormItem, Select } from "formik-antd";

const { Option } = Select;

function AutoComplete({ name, findBy, iniData = [] }) {
  const [state, setState] = useState({
    data: iniData.length
      ? iniData.map(i => ({ value: i.id, text: i.value || i.name }))
      : [],
    value: [],
    fetching: false
  });
  const fetchUser = debounce(_fetch, 500);

  function handleChange(value) {
    setState({
      value,
      data: [],
      fetching: false
    });
  }

  function _fetch(value) {
    setState(prev => ({ ...prev, data: [], fetching: true }));
    autocompleteGetItems({
      model: findBy,
      name: value,
      offset: 0,
      limit: 20
    }).then(body => {
      const data = body.data.items.map(i => ({
        text: i.value || i.name,
        value: i.id
      }));
      setState(prev => ({ ...prev, data, fetching: false }));
    });
  }
  const { data, value, fetching } = state;

  return (
    <FormItem name={name} className="bigger">
      <Select
        name={name}
        placeholder="Type to find a needed record"
        showSearch
        labelInValue
        value={value}
        notFoundContent={fetching ? <Spin size="small" /> : null}
        filterOption={false}
        onSearch={fetchUser}
        onChange={handleChange}
        style={{ width: "100%" }}
      >
        {data.map(d => (
          <Option key={d.value}>{d.text}</Option>
        ))}
      </Select>
    </FormItem>
  );
}
export default AutoComplete;
