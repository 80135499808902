function toStr(item) {
  return typeof item === "number" ? String(item) : item;
}

function reducer(data) {
  return Object.keys(data).reduce(
    (acc, item) => {
      if (item.startsWith("businessModel_")) {
        const { radio, select } = data[item];
        if (radio && select) {
          if (radio === "add") acc.business_model.onCreate.push(toStr(select));
          if (radio === "delete")
            acc.business_model.onDelete.push(toStr(select));
        }
      }
      if (item.startsWith("productType_")) {
        const { radio, select } = data[item];
        if (radio && select) {
          if (radio === "add")
            acc.product_type.onCreate.push(toStr(select.key));
          if (radio === "delete")
            acc.product_type.onDelete.push(toStr(select.key));
        }
      }
      if (item.startsWith("dataSource_")) {
        const { radio, select } = data[item];
        if (radio && select) {
          if (radio === "add") acc.datasources.onCreate.push(toStr(select.key));
          if (radio === "delete")
            acc.datasources.onDelete.push(toStr(select.key));
        }
      }
      if (item.startsWith("language_")) {
        const { radio, select } = data[item];
        if (radio && select) {
          if (radio === "add") acc.languages.onCreate.push(toStr(select));
          if (radio === "delete") acc.languages.onDelete.push(toStr(select));
        }
      }
      if (item.startsWith("address_")) {
        const { radio, country, state, zip, address } = data[item];
        if (radio) {
          if (radio === "add")
            acc.addresses.onCreate.push({
              address: address,
              countries_id: toStr(country),
              state_id: toStr(state),
              zip: toStr(zip)
            });
          if (radio === "delete")
            acc.addresses.onDelete.push({
              address: toStr(address),
              countries_id: toStr(country),
              state_id: toStr(state),
              zip: toStr(zip)
            });
        }
      }
      return acc;
    },
    {
      datasources: { onDelete: [], onCreate: [] },
      languages: { onDelete: [], onCreate: [] },
      addresses: { onDelete: [], onCreate: [] },
      business_model: { onDelete: [], onCreate: [] },
      product_type: { onDelete: [], onCreate: [] }
    }
  );
}

export function dataAdapter(values) {
  const res = {
    keywords: {
      onCreate: [
        {
          id: toStr(values.keyword),
          industry_id: toStr(values.industry),
          segment_id: toStr(values.segment)
        }
      ]
    },
    business_type_id: toStr(values.business_type),
    size: toStr(values.company_size),
    employees_count: toStr(values.employees_count),
    ...reducer(values)
  };

  return res;
}
