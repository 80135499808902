import React, { useContext } from "react";
import Step1 from "./Step1";
import Step2 from "./Step2";
import Step3 from "./Step3";
import { DataContext, Steps as EnumSteps } from "../Context";
import { Steps } from "./styled";
const { Step } = Steps;

const steps = [
  {
    content: "First-content"
  },
  {
    content: "Second-content"
  },
  {
    content: "Last-content"
  }
];

function Form({ uploadFile }) {
  const { step } = useContext(DataContext);

  return (
    <div>
      <Steps current={step} size="small">
        {steps.map(item => (
          <Step key={item.content} />
        ))}
      </Steps>
      {step === EnumSteps.STEP_1 && <Step1 />}
      {step === EnumSteps.STEP_2 && <Step2 uploadFile={uploadFile} />}
      {step === EnumSteps.STEP_3 && <Step3 uploadFile={uploadFile} />}
    </div>
  );
}
export default Form;
