import React, { useCallback } from "react";
import Companies from "../Companies";
import { matchCompanies } from "../../services/api";
import ErrorNotification from "../../components/UI/Notifications/ErrorNotification";
import { ReportNameAndStatus } from "./ReportNameAndStatus";

const Filter = ({ report, next, lang }) => {
  const handleNext = useCallback(
    async filters => {
      try {
        await matchCompanies(report.id, {
          filters: filters ? filters.companyFilter : {}
        });
        next();
      } catch (text) {
        ErrorNotification({ text });
      }
    },
    [next, report]
  );
  return (
    <>
      {report && report.name && <ReportNameAndStatus report={report} />}
      <Companies
        type="match"
        lang={lang}
        next={handleNext}
        match={{ params: { companyId: +report.company_id } }}
      />
    </>
  );
};

export default Filter;
