import React from "react";
import PropTypes from "prop-types";
import { Radio, Input, Button, Select, Form } from "antd";
import intl from "react-intl-universal";

function EditSynonymItemForm(props) {
  const { getFieldDecorator } = props.form;
  const { handleSubmit, editableItem, handleClose } = props;
  const handleSubmitForm = e => {
    e.preventDefault();
    props.form.validateFields((err, values) => {
      if (!err) {
        const data = {
          name: values.synonymName,
          caseSensitive: values.caseSensetive,
          isSynonym: values.itemType,
          languageId: Number(values.chooseLanguage)
        };
        handleSubmit(data);
        handleClose();
      }
    });
  };
  return (
    <>
      <Form onSubmit={handleSubmitForm}>
        <div className="inputBlockForNewSynonym">
          <div className="newSynonymINput">
            <Form.Item>
              {getFieldDecorator("synonymName", {
                rules: [
                  {
                    required: true,
                    message: `${intl.get("synonymFormValidationName")}`
                  }
                ],
                initialValue: editableItem.name
              })(
                <Input
                  placeholder={intl.get("synonymAddItemFiledPlaceholder")}
                />
              )}
            </Form.Item>
            <div className="addNewSynonymRadioBlock">
              <Form.Item>
                {getFieldDecorator("caseSensetive", {
                  rules: [
                    {
                      required: true,
                      message: `${intl.get("synonymFormValidationChooseCase")}`
                    }
                  ],
                  initialValue: editableItem.case_sensitive
                })(
                  <Radio.Group>
                    <Radio value={true}>
                      {intl.get("synonymRadioCaseSensetive")}
                    </Radio>
                    <Radio value={false}>
                      {intl.get("synonymRadioCaseInsensetive")}
                    </Radio>
                  </Radio.Group>
                )}
              </Form.Item>
              <Form.Item>
                {getFieldDecorator("itemType", {
                  rules: [
                    {
                      required: true,
                      message: `${intl.get("synonymFormValidationType")}`
                    }
                  ],
                  initialValue: editableItem.is_synonym
                })(
                  <Radio.Group>
                    <Radio value={true}>
                      {intl.get("synonymAddNewItemTypeSynonym")}
                    </Radio>
                    <Radio value={false}>
                      {intl.get("synonymAddNewItemTypeTranslation")}
                    </Radio>
                  </Radio.Group>
                )}
              </Form.Item>
            </div>
          </div>
          <Form.Item className="selectCountryForNewsynonym">
            {getFieldDecorator("chooseLanguage", {
              rules: [
                {
                  required: true,
                  message: `${intl.get(
                    "synonymAddNewItemValidationChooseLanguage"
                  )}`
                }
              ],
              initialValue:
                editableItem && editableItem.language
                  ? editableItem.language.id
                  : ""
            })(
              <Select
                showSearch
                placeholder={intl.get("synonymAddNewItemSelectPlaceholder")}
                optionFilterProp="children"
              >
                {props.languageList.map((item, index) => (
                  <Select.Option key={item.id} value={item.id}>
                    {item.name}
                  </Select.Option>
                ))}
              </Select>
            )}
          </Form.Item>
        </div>
        <div className="addNewSynonymButtonBlock">
          <Button
            type="primary"
            htmlType="submit"
            className="addNewSynonymButton"
            size={"large"}
          >
            {intl.get("editButton")}
          </Button>
        </div>
      </Form>
    </>
  );
}

EditSynonymItemForm.propTypes = {
  inputValue: PropTypes.string.isRequired,
  listItem: PropTypes.objectOf(PropTypes.any),
  handleSubmit: PropTypes.func.isRequired,
  editableItem: PropTypes.objectOf(PropTypes.any),
  handleClose: PropTypes.func.isRequired
};

EditSynonymItemForm.defaultProps = {
  editableItem: {},
  listItem: {}
};

export default Form.create({ name: "synonymEditForm" })(EditSynonymItemForm);
