import * as React from "react";
import { SubmitButton, Input, FormItem } from "formik-antd";
import { Button } from "antd";
import { Form, Buttons, Error } from "./styled";
import { useHistory } from "react-router";
import Companies from "./Select";

const SampleForm = props => {
  const history = useHistory();

  function onCancel() {
    history.push(`/${props.lang}/users`);
  }
  return (
    <Form onSubmit={props.handleSubmit}>
      <Input name="submitError" type="text" hidden />
      <FormItem name="email" label="E-mail" className="bigger">
        <Input name="email" type="email" placeholder="New user’s e-mail" />
      </FormItem>
      <FormItem name="password" label="Create password" className="bigger">
        <Input.Password name="password" placeholder="Password" />
      </FormItem>
      <FormItem name="first_name" label="First name" className="bigger">
        <Input name="first_name" placeholder="New user’s first name" />
      </FormItem>
      <FormItem name="last_name" label="Last name" className="bigger">
        <Input name="last_name" placeholder="New user’s last name" />
      </FormItem>
      <Companies />
      {props.errors?.submitError && <Error>{props.errors?.submitError}</Error>}
      <Buttons>
        <Button disabled={false} size="large" onClick={onCancel}>
          Cancel
        </Button>
        <SubmitButton type="primary" disabled={false} size="large">
          Create user
        </SubmitButton>
      </Buttons>
    </Form>
  );
};
export default SampleForm;
