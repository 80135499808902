import React from "react";
import PropTypes from "prop-types";
import { Card, Icon, List } from "antd";
import intl from "react-intl-universal";

const AddressBlockItem = props => {
  const handleOpenModalWindow = () => {
    props.openEditModalWindow(props.editableItem);
  };

  return (
    <>
      <Card
        size="small"
        className="companyInfoBlockItemCard"
        headStyle={{
          borderBottom: "none"
        }}
        title={
          <>
            <div className="cardTitleForCompanyIfoBlock">
              <div className="imageIconBlockWithCircle">
                <div className="companyInfoBlockCircle" />
                <Icon type="picture" className="infoBlockIcon" />
              </div>
              <p className="cardCompanyInfoBlockTitle">{props.title}:</p>
            </div>
          </>
        }
        extra={
          <p className="infoBlockActionItem" onClick={handleOpenModalWindow}>
            {intl.get("companyInfoBlockActionEdit")}
          </p>
        }
      >
        <div className="contentForCarItem">
          {props.content && (
            <List
              className="companyProfileBlockItemList"
              dataSource={props.content}
              bordered={false}
              renderItem={item => (
                <List.Item
                  className="companyProfileItemBlockListItem"
                  key={item.id}
                >
                  <p className="companyProfileBlockItemString">
                    {item.country &&
                      item.country.name &&
                      `${item.country.name},`}{" "}
                    {item.state && item.state.name && `${item.state.name},`}{" "}
                    {item.zip} {item.address}
                  </p>
                </List.Item>
              )}
            />
          )}
        </div>
      </Card>
    </>
  );
};

AddressBlockItem.propTypes = {
  title: PropTypes.string.isRequired,
  openEditModalWindow: PropTypes.func,
  editableItem: PropTypes.object.isRequired
};

AddressBlockItem.defaultProps = {
  openEditModalWindow: () => {}
};

export default AddressBlockItem;
