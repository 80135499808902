import React, { useEffect, useState } from "react";
import { FormItem, Select } from "formik-antd";
import { FieldWrap } from "./styled";
import {
  getAllCategories,
  getAllKeywordGroup,
  getSegmentForCurrentIndustry
} from "../../../services/api";
import { useFormikContext } from "formik";
import ErrorNotification from "../../../components/UI/Notifications/ErrorNotification";

const { Option } = Select;

const KeywordsField = ({ industry }) => {
  const { values, setFieldValue } = useFormikContext();
  const [loading, setLoading] = useState();
  const [segments, setSegments] = useState([]);
  const [categories, setCategories] = useState([]);
  const [keywords, setKeywords] = useState([]);

  useEffect(() => {
    if (values?.industry) {
      setLoading("segment");
      getSegmentForCurrentIndustry(values?.industry)
        .then(({ data }) => {
          setSegments(data.items);
          setFieldValue("segment", undefined);
        })
        .catch(text => {
          ErrorNotification({ text });
        })
        .finally(() => {
          setLoading(undefined);
        });
    } else if (segments.length) {
      setSegments([]);
      setFieldValue("segment", undefined);
    }
  }, [values?.industry]);

  useEffect(() => {
    if (values?.segment) {
      setLoading("category");
      getAllCategories(values?.segment)
        .then(({ data }) => {
          setCategories(data.items);
          setFieldValue("category", undefined);
        })
        .catch(text => {
          ErrorNotification({ text });
        })
        .finally(() => {
          setLoading(undefined);
        });
    } else if (categories.length) {
      setCategories([]);
      setFieldValue("category", undefined);
    }
  }, [values?.segment]);

  useEffect(() => {
    if (values?.category) {
      setLoading("keyword");
      getAllKeywordGroup(values?.category)
        .then(({ data }) => {
          setKeywords(data.items);
          setFieldValue("keyword", undefined);
        })
        .catch(text => {
          ErrorNotification({ text });
        })
        .finally(() => {
          setLoading(undefined);
        });
    } else if (keywords.length) {
      setKeywords([]);
      setFieldValue("keyword", undefined);
    }
  }, [values?.category]);

  return (
    <FieldWrap>
      <FormItem name="industry" label="Select a industry">
        <Select name="industry" placeholder="Industry" allowClear>
          {industry.map(i => (
            <Option value={i.id} key={i.id}>
              {i.name}
            </Option>
          ))}
        </Select>
      </FormItem>
      <FormItem name="segment" label="Select a segment">
        <Select
          name="segment"
          placeholder="Segment"
          allowClear
          loading={loading === "segment"}
        >
          {segments.map(i => (
            <Option value={i.id} key={i.id}>
              {i.name}
            </Option>
          ))}
        </Select>
      </FormItem>
      <FormItem name="category" label="Select a category">
        <Select
          name="category"
          placeholder="Category"
          allowClear
          loading={loading === "category"}
        >
          {categories.map(i => (
            <Option value={i.id} key={i.id}>
              {i.name}
            </Option>
          ))}
        </Select>
      </FormItem>
      <FormItem name="keyword" label="Keyword">
        <Select
          name="keyword"
          placeholder="Keyword"
          allowClear
          loading={loading === "keyword"}
        >
          {keywords.map(i => (
            <Option value={i.id} key={i.id}>
              {i.name}
            </Option>
          ))}
        </Select>
      </FormItem>
    </FieldWrap>
  );
};
export default KeywordsField;
