import styled from "styled-components";
import { Button as _Button } from "antd";

export const Buttons = styled.div`
  display: flex;
  justify-content: space-between;
  background: #f3fbff;
  box-shadow: inset 0 5px 5px rgba(0, 0, 0, 0.05);
  padding: 32px;
  margin-left: -24px;
  margin-right: -24px;
  > * {
    width: calc(33% - 16px);
    height: 48px;
  }
`;
export const Title = styled.h3`
  font-weight: 500;
  font-size: 16px;
  line-height: 22px;
  color: #424a56;
  padding: 16px 0 16px 16px;
`;
export const Button = styled(_Button)`
  margin-top: 64px;
  width: 100%;
`;
