import React, { useState, useEffect, useCallback } from "react";
import { Form, Button, Select } from "antd";
import { autocompleteGetItems } from "../../services/api";
import debounce from "lodash.debounce";
import ErrorNotification from "../../components/UI/Notifications/ErrorNotification";

const BusinessModel = props => {
  const [dataSource, setDataSource] = useState(
    props.initialData ? props.initialData.map(item => Number(item)) : []
  );

  const [dataSourceOptions, setDataSourceOptions] = useState([]);

  useEffect(() => {
    triggerOptionsLoad("business_models", "", 80);
  }, []);

  const triggerOptionsLoad = async (model, name = "", limit) => {
    try {
      const opts = await autocompleteGetItems({
        model,
        name,
        limit
      });
      setDataSourceOptions(opts.data.items);
    } catch (err) {
      ErrorNotification({ text: err });
    }
  };

  const searchProductType = useCallback(
    debounce(search => triggerOptionsLoad("business_models", search, 80), 1200),
    [setDataSourceOptions]
  );

  const handleChange = useCallback(
    (v, o) => {
      setDataSource(v);
    },
    [setDataSource]
  );

  const handleSubmit = e => {
    e.preventDefault();
    const data = {};
    if (dataSource) {
      data.business_model_id = dataSource;
    } else {
      data.business_model_id = [];
    }
    props.handleSetValues(data);
    props.handleCloseModalWindow();
  };

  return (
    <Form onSubmit={handleSubmit}>
      <Form.Item>
        <Select
          value={dataSource}
          mode="multiple"
          optionFilterProp="name"
          filterOption={false}
          onSearch={searchProductType}
          onChange={handleChange}
          onSelect={triggerOptionsLoad.bind(null, "business_models", "", 80)}
          showSearch
          defaultActiveFirstOption={false}
          showArrow={false}
        >
          {dataSourceOptions.map((item, index) => (
            <Select.Option key={index} name={item.name} value={Number(item.id)}>
              {item.name}
            </Select.Option>
          ))}
        </Select>
      </Form.Item>

      <Form.Item>
        <Button
          className="submitFormButtonInputForm"
          type="primary"
          htmlType="submit"
        >
          Submit
        </Button>
      </Form.Item>
    </Form>
  );
};

const WrappedDynamicFieldSet = Form.create({ name: "product_type" })(
  BusinessModel
);
export default WrappedDynamicFieldSet;
