import React, { useState, useCallback } from "react";
import PropTypes from "prop-types";
import intl from "react-intl-universal";
import { Card, Icon, Input } from "antd";

import "./ButtonINput.scss";

const ButtonInput = props => {
  const {
    updateItem,
    chooseItem,
    itemOfList,
    text,
    removeIndustryItem,
    choosedItem
  } = props;
  const [state, setState] = useState({
    editFormEnable: false,
    editItem: text || ""
  });

  const handleEditFormenabled = useCallback(() => {
    setState(s => {
      return {
        ...s,
        editFormEnable: true
      };
    });
  }, [setState]);

  const saveEditedItem = useCallback(() => {
    setState(s => {
      const { editItem } = s;
      if (editItem !== "" && editItem !== itemOfList.name) {
        updateItem({ id: itemOfList.id, name: editItem });
        return { ...s, editFormEnable: false, editItem: "" };
      } else {
        return { ...s, editFormEnable: false, editItem: "" };
      }
    });
  }, [updateItem, itemOfList, setState]);

  const handleChangeItemName = useCallback(
    e => {
      e.persist();
      setState(s => {
        return {
          ...s,
          editItem: e.target.value
        };
      });
    },
    [setState]
  );
  const handleChooseItem = useCallback(() => {
    chooseItem(itemOfList.id, itemOfList.name);
  }, [chooseItem, itemOfList.id, itemOfList.name]);
  const removeItem = useCallback(() => {
    removeIndustryItem(itemOfList);
  }, [removeIndustryItem, itemOfList]);

  const handleDoubleClick = useCallback(() => {
    props.goToNext();
  }, [props]);

  const { editFormEnable, editItem } = state;
  const choosedItemEnabled = choosedItem === itemOfList.id;
  return (
    <Card
      className={choosedItemEnabled ? "activeItem" : "cardItem"}
      onClick={handleChooseItem}
      onDoubleClick={handleDoubleClick}
    >
      <span className="cardHeader">
        {!editFormEnable ? (
          <p className="cardHeaderEditButton" onClick={handleEditFormenabled}>
            {intl.get("buttonInputEditButon")}
          </p>
        ) : (
          <p className="cardHeaderEditButton" onClick={saveEditedItem}>
            {intl.get("buttonInpitSaveButton")}
          </p>
        )}
        {!editFormEnable && (
          <Icon type="close" className="closeIconColor" onClick={removeItem} />
        )}
      </span>
      {editFormEnable ? (
        <Input
          className="formEditInput"
          value={editItem}
          onChange={handleChangeItemName}
        />
      ) : (
        <p className="inputTextStyle">{text}</p>
      )}
    </Card>
  );
};

ButtonInput.propTypes = {
  choosedItem: PropTypes.string.isRequired,
  removeIndustryItem: PropTypes.func.isRequired,
  itemOfList: PropTypes.objectOf(PropTypes.any),
  updateItem: PropTypes.func.isRequired,
  chooseItem: PropTypes.func.isRequired
};

ButtonInput.defaultProps = {
  itemOfList: {}
};
export default ButtonInput;
