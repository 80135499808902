import React from "react";
import PropTypes from "prop-types";
import intl from "react-intl-universal";
import { Typography, Row, Col, List, Icon, Button, Input } from "antd";
import "./AddDataSource.scss";

const { Title } = Typography;

const PAGE_SIZE = 10;
const AddDataSource = props => {
  const sendFileToServer = sendedFile => {
    const formData = new FormData();
    formData.append("file", sendedFile);
    props.handleChangeUploadButton(formData);
  };
  const handleImageChange = e => {
    e.preventDefault();
    let file = e.target.files[0];
    sendFileToServer(file);
  };

  return (
    <React.Fragment>
      <div className="titleWrapper">
        <div>
          <Row className="dataSourceRow">
            <Col className="columnDataSourceList">
              <div>
                <Title className="columnTitle">Active Data Source</Title>
                <List
                  className="choseedCountryList"
                  itemLayout="horizontal"
                  dataSource={props.dataSourceList}
                  pagination={{
                    position: "bottom",
                    pageSize: PAGE_SIZE,
                    current: props.paginationPage,
                    total: props.dataSourceTotal,
                    onChange: pageNum =>
                      props.handlePageChange(
                        PAGE_SIZE,
                        (pageNum - 1) * PAGE_SIZE
                      )
                  }}
                  renderItem={(item, index) => (
                    <List.Item className="listItemStyle" key={index}>
                      {item.value}
                      <Icon
                        type="close"
                        className="closeIconColor"
                        onClick={() => {
                          props.heandleRemoveDataSource(item);
                        }}
                      />
                    </List.Item>
                  )}
                />
                <Button
                  type="primary"
                  className="addLanguageStyleButton"
                  onClick={props.handleExportDataSource}
                >
                  {intl.get("addDataSourceExportButton")}
                </Button>
              </div>
            </Col>
            <Col className="columnAddNewDataSource">
              <Title className="columnTitle">
                {intl.get("addDataSourceAddNewDataSource")}
              </Title>
              <Input
                className="addDataSourceInput"
                placeholder={intl.get("addDataSourceInputPlaceholder")}
                onChange={props.heandleChangeInput}
              />
              <Button
                type="primary"
                className="addLanguageStyleButton"
                onClick={props.heandleSaveButton}
              >
                {intl.get("addButtonName")}
              </Button>
              <input
                type="file"
                id="importDataSourceFile"
                onChange={handleImageChange}
                className="inputForChooseFile"
              />
              <label htmlFor="importDataSourceFile">
                <p className="importDataSourceFileButton">
                  {intl.get("addDataSourceImportDataSource")}
                </p>
              </label>
            </Col>
          </Row>
        </div>
      </div>
    </React.Fragment>
  );
};

AddDataSource.propTypes = {
  dataSourceList: PropTypes.arrayOf(PropTypes.any),
  heandleChangeInput: PropTypes.func.isRequired,
  heandleSaveButton: PropTypes.func.isRequired,
  handleExportDataSource: PropTypes.func.isRequired,
  handleChangeUploadButton: PropTypes.func.isRequired
};

AddDataSource.defaultProps = {
  dataSourceList: null,
  choosedCountries: null
};

export default AddDataSource;
