import React, { useState, useEffect, useCallback } from "react";
import { Tag, List, Icon, Modal, Select } from "antd";
import ErrorNotification from "../../../components/UI/Notifications/ErrorNotification";
import {
  getWordsForCurrentCompany,
  removeKeywordItemFromCompany,
  getIndustryListForCompanyById,
  getSegmentListForCompanyByIds,
  getCategoriesListItemsForCompanyByIds,
  addWordItemToCompanyKeywords,
  getWordsForCurrentTaxonomy,
  removeKeywordItemFromTaxonomy,
  getIndustryListForTaxonomyById,
  getSegmentListForTaxonomyByIds,
  getCategoriesListItemsForTaxonomyByIds,
  addWordItemToTaxonomyKeywords
} from "../../../services/api";

import "./WordItem.scss";

const initialState = {
  companyWordsList: [],
  chosenWordItemId: "",
  openModal: false,
  industryList: [],
  segmentList: [],
  categoryList: [],
  chosenCategory: "",
  totalItems: 0,
  nav: { offset: 0, limit: 25 }
};
const WordItem = props => {
  const [state, setState] = useState(initialState);

  const handleGetWordsListItems = useCallback(
    async nav => {
      try {
        const getWordsItemsForCurrentCompany =
          props.type === "taxonomy"
            ? await getWordsForCurrentTaxonomy(props.companyId)
            : await getWordsForCurrentCompany(props.companyId, nav);

        setState(state => {
          return {
            ...state,
            companyWordsList: getWordsItemsForCurrentCompany.data.items || [],
            totalItems: getWordsItemsForCurrentCompany.data.total || 0
          };
        });
      } catch (error) {
        ErrorNotification({ text: error });
      }
    },
    [setState, props]
  );

  useEffect(() => {
    handleGetWordsListItems(state.nav);
  }, [handleGetWordsListItems, state.nav]);

  const handleRemoveWordItem = useCallback(
    async itemId => {
      try {
        await (props.type === "taxonomy"
          ? removeKeywordItemFromTaxonomy
          : removeKeywordItemFromCompany)(itemId);

        handleGetWordsListItems(state.nav);
      } catch (error) {
        ErrorNotification({ text: error });
      }
    },
    [props, handleGetWordsListItems, state.nav]
  );

  const handleOpenAddWordToKeywordItemModal = useCallback(
    async itemId => {
      try {
        const getIndustryItemsFromServer =
          props.type === "taxonomy"
            ? await getIndustryListForTaxonomyById(props.companyId)
            : await getIndustryListForCompanyById(props.companyId);
        setState(state => {
          return {
            ...state,
            chosenWordItemId: itemId,
            openModal: true,
            industryList: getIndustryItemsFromServer.data.items || []
          };
        });
      } catch (error) {
        ErrorNotification({ text: error });
      }
    },
    [props.companyId, props.type]
  );

  const handleClose = useCallback(() => {
    setState(state => {
      return {
        ...state,
        openModal: false
      };
    });
  }, [setState]);

  const handleChangeIndustrySelect = useCallback(
    async itemId => {
      try {
        const getSegmentListFromServer = await (props.type === "taxonomy"
          ? getSegmentListForTaxonomyByIds
          : getSegmentListForCompanyByIds)(props.companyId, itemId);
        setState(state => {
          return {
            ...state,
            segmentList: getSegmentListFromServer.data.items || []
          };
        });
      } catch (error) {
        ErrorNotification({ text: error });
      }
    },
    [props.type, props.companyId]
  );

  const handleChangeSegmentSelect = useCallback(
    async itemId => {
      try {
        const getCategoriesListItemsFromServer = await (props.type ===
          "taxonomy"
          ? getCategoriesListItemsForTaxonomyByIds
          : getCategoriesListItemsForCompanyByIds)(props.companyId, itemId);
        setState(state => {
          return {
            ...state,
            categoryList: getCategoriesListItemsFromServer.data.items || []
          };
        });
      } catch (error) {
        ErrorNotification({ text: error });
      }
    },
    [setState, props]
  );

  const handleChangeCategorySelect = useCallback(itemId => {
    setState(state => {
      return {
        ...state,
        chosenCategory: itemId
      };
    });
  }, []);

  const handleConfirmModalWindow = useCallback(async () => {
    try {
      if (state.chosenCategory) {
        const body = {
          category_id: Number(state.chosenCategory)
        };
        await (props.type === "taxonomy"
          ? addWordItemToTaxonomyKeywords
          : addWordItemToCompanyKeywords)(Number(state.chosenWordItemId), body);
        handleClose();
        handleGetWordsListItems();
      }
    } catch (error) {
      ErrorNotification({ text: error });
    }
  }, [
    handleClose,
    handleGetWordsListItems,
    props.type,
    state.chosenCategory,
    state.chosenWordItemId
  ]);

  const changePage = useCallback(
    (page, size) => {
      setState(s => {
        const nav = {
          offset: size * (page - 1),
          limit: size * page
        };
        handleGetWordsListItems(nav);
        return { ...s, nav };
      });
    },
    [setState, handleGetWordsListItems]
  );

  return (
    <>
      <List
        grid={{
          gutter: 8,
          xs: 1,
          sm: 2,
          md: 3,
          lg: 3,
          xl: 4,
          xxl: 6
        }}
        pagination={{
          position: "bottom",
          current: state.nav.limit / (state.nav.limit - state.nav.offset),
          total: state.totalItems,
          pageSize: 25,
          onChange: changePage
        }}
        dataSource={state.companyWordsList}
        renderItem={item => (
          <List.Item key={item.id}>
            <Tag className="companyProfileWordItem">
              <div className="companyProfileWeordItemTextContainer">
                <span className="companyProfileWordItemText">{item.word}</span>
                <span className="companyProfileWordItemCount">
                  #{item.count}
                </span>
              </div>
              <div className="wordItemButtonsBlock">
                <Icon
                  className="companyProfileWordItemButtons"
                  type="edit"
                  onClick={() => {
                    handleOpenAddWordToKeywordItemModal(item.id);
                  }}
                />
                <Icon
                  className="companyProfileWordItemButtons"
                  type="close-circle"
                  onClick={() => {
                    handleRemoveWordItem(item.id);
                  }}
                />
              </div>
            </Tag>
          </List.Item>
        )}
      />
      <Modal
        title="Chose category for current keyword"
        visible={state.openModal}
        onOk={handleConfirmModalWindow}
        onCancel={handleClose}
      >
        <div>
          <span>Select a industry:</span>
          <Select
            showSearch
            className="addWordItemToCompanyKeywordSelect"
            placeholder="Select a industry"
            optionFilterProp="children"
            onChange={handleChangeIndustrySelect}
            filterOption={(input, option) =>
              option.props.children
                .toLowerCase()
                .indexOf(input.toLowerCase()) >= 0
            }
          >
            {state.industryList.map(industryItem => (
              <Select.Option key={industryItem.id} value={industryItem.id}>
                {industryItem.name}
              </Select.Option>
            ))}
          </Select>
          <span>Select a segment:</span>
          <Select
            showSearch
            className="addWordItemToCompanyKeywordSelect"
            onChange={handleChangeSegmentSelect}
            placeholder="Select a segment"
            optionFilterProp="children"
            filterOption={(input, option) =>
              option.props.children
                .toLowerCase()
                .indexOf(input.toLowerCase()) >= 0
            }
          >
            {state.segmentList.map(segmentItem => (
              <Select.Option key={segmentItem.id} value={segmentItem.id}>
                {segmentItem.name}
              </Select.Option>
            ))}
          </Select>
          <span>Select a category:</span>
          <Select
            showSearch
            className="addWordItemToCompanyKeywordSelect"
            placeholder="Select a category"
            optionFilterProp="children"
            onChange={handleChangeCategorySelect}
            filterOption={(input, option) =>
              option.props.children
                .toLowerCase()
                .indexOf(input.toLowerCase()) >= 0
            }
          >
            {state.categoryList.map(categoryItem => (
              <Select.Option key={categoryItem.id} value={categoryItem.id}>
                {categoryItem.name}
              </Select.Option>
            ))}
          </Select>
        </div>
      </Modal>
    </>
  );
};

export default WordItem;
