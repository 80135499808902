import React, { useState, useCallback, useEffect } from "react";
import intl from "react-intl-universal";
import { Layout, Button, Col, Row } from "antd";
import isEmpty from "lodash.isempty";
import ErrorNotification from "../../components/UI/Notifications/ErrorNotification";
import CompanyInfoItemBlock from "../../components/UI/CompanyInfoItemBlock";
import ModalWindow from "../../components/UI/ModalWindow";
import AddArrayOfItems from "./AddArrayOfItems";
import AddIndustryForm from "./AddIndustryCompanyView";
import AddDataSource from "./AddDataSource";
import AddLanguageForm from "./AddLanguageForm";
import AddBusinessModel from "./AddBusinessModel";
import AddBusinessType from "./AddBusinessType";
import Qualification from "./Qualification";
import QualificationView from "./QualificationView";
import AddBusinessSize from "./AddBusinessSize";
import InputComponent from "./InputComponent";
import ContactsAdd from "./Contacts/Add";
import ContactsItem from "./Contacts/Item";
import AddIsSwathlyClient from "./AddIsSwathlyClient";
import AddAddressForm from "./AddAddressForm";
import DataSourceBlockItem from "./DataSourceBlockItem";
import LanguageBlockItem from "./LanguageBlockItem";
import CountryBlockItem from "./CountryBlockItem";
import AddressBlockItem from "./AddressBlockItem";
import IndustryBlockItem from "./IndustryBlockItem";
import SegmentBlockItem from "./SegmentBlockItem";
import BusinessModelBlockItem from "./BusinessModelBlockItem";
import BusinessTypeBlockItem from "./BusinessTypeBlockItem";
import AddProductTypeForm from "./AddProductTypeForm";
import AddYearEstablishedForm from "./AddYearEstablishedForm";

import "./NewCompany.scss";
import ProductTypeBlockItem from "./ProductTypeBlockItem";
import YearEstablishedBlockItem from "./YearEstablishedBlockItem/ProductTypeBlockItem";

const { Content, Footer } = Layout;

const initialState = {
  openEditModalWindow: false,
  titleForEditModalWindow: "",
  chosenFiledForEdit: "",
  chosenItem: undefined,
  name: undefined,
  languages_id: undefined,
  person: undefined,
  website: undefined,
  note: undefined,
  is_client: false,
  employees_count: undefined,
  description: undefined,
  emails: undefined,
  phones: undefined,
  business_type_id: undefined,
  size: undefined,
  datasource_id: undefined,
  datasource: undefined,
  address: undefined,
  industries: undefined,
  year_established: undefined,
  product_type_id: undefined,
  persons: undefined,
  qualification: undefined
};

function ViewCompanyProfile(props) {
  const [state, setState] = useState(initialState);
  const handleCloseModalWindow = useCallback(() => {
    setState(s => {
      return {
        ...s,
        openEditModalWindow: false,
        titleForEditModalWindow: "",
        chosenFiledForEdit: "",
        fieldId: undefined,
        chosenItem: null
      };
    });
  }, []);

  useEffect(() => {
    let companyDataModify = props.companyData;

    for (var i = 0; i < companyDataModify.industries.length; i++) {
      var obj = companyDataModify.industries[i];
      obj.segments = obj.segments ? obj.segments.map(Number) : undefined;
    }
    if (!isEmpty(companyDataModify.languages)) {
      companyDataModify.languages = companyDataModify.languages.map(Number);
    }
    if (!isEmpty(companyDataModify.address)) {
      companyDataModify.address = companyDataModify.address.map(item => {
        return {
          ...item,
          countries_id: Number(item.countries_id)
        };
      });
    }
    setState(s => {
      return {
        ...s,
        ...companyDataModify
      };
    });
  }, [props]);

  const handleOpenEditModalWindow = useCallback(chosenItem => {
    setState(s => {
      return {
        ...s,
        openEditModalWindow: true,
        chosenItem: chosenItem,
        chosenFiledForEdit: chosenItem.filedName,
        titleForEditModalWindow: chosenItem.editModalWindowTitle,
        fieldId: chosenItem.fieldId
      };
    });
  }, []);

  const handleSetValues = useCallback(
    values => {
      setState(s => {
        return {
          ...s,
          ...values
        };
      });
    },
    [setState]
  );

  const handleAddCompany = () => {
    const newLanguagesArray = !isEmpty(state.languages_id)
      ? state.languages_id.map(item => Number(item))
      : undefined;
    const newAddressArray = !isEmpty(state.address)
      ? state.address.map(item => {
          return {
            ...item,
            coordinates: undefined,
            countries_id: Number(item.countries_id)
          };
        })
      : undefined;
    const data = {
      name: state.name,
      languages_id: newLanguagesArray,
      person: state.person && state.person !== "" ? state.person : undefined,
      website:
        state.website && state.website !== "" ? state.website : undefined,
      is_client: state.is_client,
      employees_count: state.employees_count
        ? Number(state.employees_count)
        : undefined,
      description:
        state.description && state.description !== ""
          ? state.description
          : undefined,
      emails: !isEmpty(state.emails) ? state.emails : undefined,
      phones: !isEmpty(state.phones) ? state.phones : undefined,
      business_model_id: !isEmpty(state.business_model_id)
        ? state.business_model_id
        : [],
      business_type_id: state.business_type_id
        ? Number(state.business_type_id)
        : undefined,
      size: state.size ? Number(state.size) : undefined,
      datasources_id: state.datasources_id,
      address: newAddressArray,
      persons: state.persons,
      year_established: state.year_established,
      product_type_id: state.product_type_id,
      note: !isEmpty(state.note) ? state.note : undefined,
      industries: state.industries,
      qualification_id: state.qualification_id
        ? String(state.qualification_id)
        : undefined,
      lead_phase_id: state.lead_phase_id
        ? String(state.lead_phase_id)
        : undefined,
      lead_priority_id: state.lead_priority_id
        ? String(state.lead_priority_id)
        : undefined,
      lead_statuses_id: state.lead_statuses_id
        ? String(state.lead_statuses_id)
        : undefined
    };
    if (state.name !== "") {
      try {
        props.handleUpdateCompanyProfile(data);
      } catch (error) {
        ErrorNotification({ text: error });
      }
    } else {
      ErrorNotification({ text: "name is required field" });
    }
  };
  const fields = [
    {
      id: 0,
      filedName: "name",
      titleForItemBlock: intl.get("companyProfileCompanyNameTitleBlock"),
      editFunction: handleOpenEditModalWindow,
      editModalWindowTitle: intl.get(
        "companyProfileEditModalWindowTitleCompanyName"
      ),
      initialData: "",
      validationRules: [
        {
          required: true,
          message: intl.get("companyProfileValidationCompanyName")
        }
      ],
      component: InputComponent
    },
    {
      id: 1,
      filedName: "emails",
      titleForItemBlock: intl.get("companyProfileEmailTitleBlock"),
      editFunction: handleOpenEditModalWindow,
      editModalWindowTitle: intl.get(
        "companyProfileEditModalWindowEmailsTitle"
      ),
      initialData: [],
      validationRules: [
        {
          required: true,
          type: "email",
          message: intl.get("filedShouldBeEmail")
        }
      ],
      component: AddArrayOfItems
    },
    {
      id: 2,
      filedName: "phones",
      titleForItemBlock: intl.get("companyProfileItemBlockTitlePhones"),
      editFunction: handleOpenEditModalWindow,
      editModalWindowTitle: intl.get(
        "companyProfilePhonesEditModalWindowTitle"
      ),
      initialData: [],
      validationRules: [
        {
          required: true,
          message: intl.get("validationErrorMessage")
        }
      ],
      component: AddArrayOfItems
    },
    {
      id: 3,
      filedName: "website",
      titleForItemBlock: intl.get("companyProfileWebsiteBlockItemTitle"),
      editFunction: handleOpenEditModalWindow,
      editModalWindowTitle: intl.get("companyProfileWebsiteModalWindowTitle"),
      initialData: "",
      validationRules: [
        {
          required: true,
          message: intl.get("companyProfileWebsiteValidationErrorValidation")
        }
      ],
      component: InputComponent
    },
    {
      id: 4,
      filedName: "persons",
      titleForItemBlock: intl.get("companyProfilePersonBlockTitle"),
      editFunction: handleOpenEditModalWindow,
      editModalWindowTitle: intl.get(
        "companyProfileEditModalWindowTitlePerson"
      ),
      initialData: "",
      validationRules: [
        {
          required: true,
          message: intl.get("companyProfilePersonValidationError")
        }
      ],
      modalWidth: 1500,
      component: ContactsAdd,
      viewComponent: ContactsItem
    },
    {
      id: 5,
      filedName: "datasources_id",
      titleForItemBlock: intl.get("companyProfileDataSourceBlockTitle"),
      editFunction: handleOpenEditModalWindow,
      editModalWindowTitle: intl.get(
        "companyProfileDatSourceEditModalWindowTitle"
      ),
      validationRules: [
        {
          required: true,
          message: intl.get("validationErrorMessage")
        }
      ],
      initialData: [],
      component: AddDataSource,
      viewComponent: DataSourceBlockItem
    },
    {
      id: 6,
      filedName: "languages_id",
      titleForItemBlock: intl.get("companyProfileLanguageBlockItemTitle"),
      editFunction: handleOpenEditModalWindow,
      editModalWindowTitle: intl.get(
        "companyProfileLanguageEditModalWindowTitle"
      ),
      validationRules: [
        {
          required: true,
          message: intl.get("validationErrorMessage")
        }
      ],
      initialData: [],
      component: AddLanguageForm,
      viewComponent: LanguageBlockItem
    },
    {
      id: 7,
      filedName: "address",
      titleForItemBlock: intl.get("companyProfileAddressBlockItemTitle"),
      editFunction: handleOpenEditModalWindow,
      editModalWindowTitle: intl.get(
        "companyProfileAddressEditModalWindowTitle"
      ),
      validationRules: [
        {
          required: true,
          message: intl.get("validationErrorMessage")
        }
      ],
      initialData: [],
      component: AddAddressForm,
      viewComponent: AddressBlockItem
    },
    {
      id: 8,
      filedName: "country",
      titleForItemBlock: intl.get("companyProfileCountryBlockItemTitle"),
      editFunction: handleOpenEditModalWindow,
      editModalWindowTitle: intl.get(
        "companyProfileCountryEditModalWindowTitle"
      ),
      validationRules: [
        {
          required: true,
          message: intl.get("validationErrorMessage")
        }
      ],
      initialData: [],
      dataField: "address",
      component: AddAddressForm,
      viewComponent: CountryBlockItem
    },
    {
      id: 9,
      filedName: "industries",
      titleForItemBlock: intl.get("companyProfileIndustriesBlockItemTitle"),
      editFunction: handleOpenEditModalWindow,
      editModalWindowTitle: intl.get(
        "companyProfileIndustriesEditModalWindowTitle"
      ),
      validationRules: [],
      initialData: [],
      component: AddIndustryForm,
      viewComponent: IndustryBlockItem
    },
    {
      id: 10,
      filedName: "segments",
      titleForItemBlock: intl.get("companyProfileSegmentsBlockItemTitle"),
      editFunction: handleOpenEditModalWindow,
      editModalWindowTitle: intl.get(
        "companyProfileSegmentsEditModalWindowTitle"
      ),
      validationRules: [],
      initialData: [],
      dataField: "industries",
      viewComponent: SegmentBlockItem,
      component: AddIndustryForm
    },
    {
      id: 11,
      filedName: "business_model_id",
      titleForItemBlock: intl.get("companyProfileBusinessModelBlockItemTitle"),
      editFunction: handleOpenEditModalWindow,
      editModalWindowTitle: intl.get(
        "companyProfileBusinessModelEditModalWindowTtle"
      ),
      validationRules: [
        {
          required: true,
          message: intl.get("validationErrorMessage")
        }
      ],
      initialData: [],
      component: AddBusinessModel,
      viewComponent: BusinessModelBlockItem
    },
    {
      id: 12,
      filedName: "business_type_id",
      titleForItemBlock: intl.get("companyProfileBusinessTypeBlockItemTitle"),
      editFunction: handleOpenEditModalWindow,
      editModalWindowTitle: intl.get(
        "companyProfileBusinessTypeEditModalWindowTitle"
      ),
      validationRules: [
        {
          required: true,
          message: intl.get("validationErrorMessage")
        }
      ],
      initialData: [],
      component: AddBusinessType,
      viewComponent: BusinessTypeBlockItem
    },
    {
      id: 13,
      filedName: "size",
      titleForItemBlock: intl.get("companyProfileSizeBlockItemTitle"),
      editFunction: handleOpenEditModalWindow,
      editModalWindowTitle: intl.get("companyProfileSizeEditModalWindowTitle"),
      validationRules: [
        {
          required: true,
          message: intl.get("validationErrorMessage")
        }
      ],
      initialData: "",
      component: AddBusinessSize
    },
    {
      id: 14,
      filedName: "description",
      titleForItemBlock: intl.get("companyProfileDescBlockItemTitle"),
      editFunction: handleOpenEditModalWindow,
      editModalWindowTitle: intl.get("companyProfileDescEditModalWindowTitle"),
      validationRules: [
        {
          required: true,
          message: intl.get("validationErrorMessage")
        }
      ],
      initialData: "",
      component: InputComponent
    },
    {
      id: 15,
      filedName: "is_client",
      titleForItemBlock: intl.get("companyProfileIsClientBlockItemTitle"),
      editFunction: handleOpenEditModalWindow,
      editModalWindowTitle: intl.get(
        "companyProfileIsClientEditModalWindowTitle"
      ),
      validationRules: [
        {
          required: true,
          message: intl.get("validationErrorMessage")
        }
      ],
      initialData: false,
      component: AddIsSwathlyClient
    },
    {
      id: 16,
      filedName: "employees_count",
      titleForItemBlock: intl.get("companyProfileEmployeesCountBlockItemTitle"),
      editFunction: handleOpenEditModalWindow,
      editModalWindowTitle: intl.get(
        "companyProfileEmployeesCountEditModalWindowTitle"
      ),
      validationRules: [
        {
          required: true,
          message: intl.get("validationErrorMessage")
        }
      ],
      initialData: null,
      component: InputComponent
    },
    {
      id: 17,
      titleForItemBlock: "Year established",
      filedName: "year_established",
      editModalWindowTitle: "Add year established",
      component: AddYearEstablishedForm,
      viewComponent: YearEstablishedBlockItem
    },
    {
      id: 18,
      titleForItemBlock: "Product type",
      editModalWindowTitle: "Add product type",
      filedName: "product_type_id",
      component: AddProductTypeForm,
      viewComponent: ProductTypeBlockItem
    },
    {
      id: 19,
      filedName: "note",
      titleForItemBlock: intl.get("companyProfileEmployeesNote"),
      editFunction: handleOpenEditModalWindow,
      editModalWindowTitle: intl.get("companyProfileEmployeesAddNote"),
      validationRules: [
        {
          required: true,
          message: intl.get("validationErrorMessage")
        }
      ],
      initialData: null,
      component: InputComponent
    }
  ];
  if (props.isRoleUser) {
    fields.unshift(
      {
        id: 20,
        filedName: "qualification",
        titleForItemBlock: "Qualification",
        editFunction: handleOpenEditModalWindow,
        editModalWindowTitle: "add Qualification",
        fieldId: "qualification_id",
        validationRules: [
          {
            required: true,
            message: intl.get("validationErrorMessage")
          }
        ],
        initialData: [],
        component: Qualification,
        viewComponent: QualificationView
      },
      {
        id: 21,
        filedName: "lead_phase",
        titleForItemBlock: "Lead Phase",
        editFunction: handleOpenEditModalWindow,
        editModalWindowTitle: "add Lead Phase",
        fieldId: "lead_phase_id",
        validationRules: [
          {
            required: true,
            message: intl.get("validationErrorMessage")
          }
        ],
        initialData: [],
        component: Qualification,
        viewComponent: QualificationView
      },
      {
        id: 22,
        filedName: "lead_priority",
        titleForItemBlock: "Lead Priority",
        editFunction: handleOpenEditModalWindow,
        editModalWindowTitle: "add Lead Priority",
        fieldId: "lead_priority_id",
        validationRules: [
          {
            required: true,
            message: intl.get("validationErrorMessage")
          }
        ],
        initialData: [],
        component: Qualification,
        viewComponent: QualificationView
      },
      {
        id: 22,
        filedName: "lead_statuses",
        titleForItemBlock: "Lead status",
        editFunction: handleOpenEditModalWindow,
        editModalWindowTitle: "add Lead status",
        fieldId: "lead_statuses_id",
        validationRules: [
          {
            required: true,
            message: intl.get("validationErrorMessage")
          }
        ],
        initialData: [],
        component: Qualification,
        viewComponent: QualificationView
      }
    );
  }
  return (
    <div className="infoBlocksContainer">
      <Layout className="removeBackGroundOnLayout">
        <Content className="companyInfoBlockItemsList">
          <Row type="flex" justify="space-around">
            {fields.map(item => (
              <Col
                span={4}
                key={item.id}
                className="companyProfileItemBlockColumn"
              >
                <div className="infoBlocksContainer">
                  {item.viewComponent ? (
                    <item.viewComponent
                      editable={props.handleUpdateCompanyProfile}
                      editableItem={item}
                      title={item.titleForItemBlock}
                      content={
                        item.dataField
                          ? state[`${item.dataField}`]
                          : state[`${item.filedName}`]
                      }
                      openEditModalWindow={handleOpenEditModalWindow}
                    />
                  ) : (
                    <CompanyInfoItemBlock
                      editable={props.handleUpdateCompanyProfile}
                      editableItem={item}
                      title={item.titleForItemBlock}
                      content={
                        item.dataField
                          ? state[`${item.dataField}`]
                          : state[`${item.filedName}`]
                      }
                      openEditModalWindow={handleOpenEditModalWindow}
                    />
                  )}
                </div>
              </Col>
            ))}
          </Row>
        </Content>
        {props.handleUpdateCompanyProfile && (
          <Footer>
            <div>
              <Button
                size={"large"}
                className="addCompanyButton"
                onClick={handleAddCompany}
              >
                {props.isRoleUser
                  ? "Update lead profile"
                  : intl.get("companyProfileUpdateCompanyButtonName")}
              </Button>
            </div>
          </Footer>
        )}
      </Layout>
      <ModalWindow
        open={state.openEditModalWindow}
        title={state.titleForEditModalWindow}
        handleClose={handleCloseModalWindow}
        handleSubmit={handleCloseModalWindow}
        footer={null}
        width={(state.chosenItem && state.chosenItem.modalWidth) || 520}
      >
        <>
          {state.chosenItem && (
            <state.chosenItem.component
              companyName={state.name}
              companyId={state.id}
              initialData={state[state.chosenFiledForEdit]}
              handleSetValues={handleSetValues}
              validationRules={state.chosenItem.validationRules}
              chosenFiledForEdit={state.chosenFiledForEdit}
              fieldId={state.fieldId}
              handleChangeSetValues={handleSetValues}
              handleCloseModalWindow={handleCloseModalWindow}
            />
          )}
        </>
      </ModalWindow>
    </div>
  );
}

export default ViewCompanyProfile;
