import { DataContext, Steps } from "../../Context";
import React, { useContext } from "react";
import { Formik } from "formik";
import { Button as Submit } from "../../styled";
import { Radio, Form } from "formik-antd";
import { RadioWrap as Wrap } from "../styled";

function Step1({ uploadFile }) {
  const { setStep } = useContext(DataContext);

  function handleOnSubmit(val) {
    if (val.update === "all") {
      uploadFile();
    } else {
      setStep(Steps.STEP_3);
    }
  }
  return (
    <Formik
      initialValues={{ update: "all" }}
      onSubmit={handleOnSubmit}
      render={({ values }) => (
        <Form>
          <Wrap>
            <Radio.Group
              name="update"
              options={[
                { label: "Update all", value: "all" },
                { label: "Update specific field", value: "specific" }
              ]}
            />
          </Wrap>
          <Submit type="primary" htmlType="submit" size="large">
            {values.update === "all" ? "Import file" : "Next"}
          </Submit>
        </Form>
      )}
    />
  );
}
export default Step1;
