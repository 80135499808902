/* eslint-disable jsx-a11y/anchor-is-valid */

import React, { Component } from "react";
import isEmpty from "lodash.isempty";
import debounce from "lodash.debounce";
import { Layout, Spin, Input, Button, List, Icon, Modal, Radio } from "antd";
import intl from "react-intl-universal";
import ErrorNotification from "../../components/UI/Notifications/ErrorNotification";
import SuccessNotification from "../../components/UI/Notifications/SuccessNotification";
import ModalWindow from "../../components/UI/ModalWindow";
import ButtonInput from "../../components/UI/ButtonInput";
import BreadcrumbComponent from "../../components/BreadcrumbComponent";
import Synonyms from "./Synonyms";
import {
  getAllKeywordGroup,
  addNewKeywordGroup,
  updateKeyworGroupdItem,
  deleteKeywordGroupItem,
  getAllSynonyms,
  searchAllKeywordGroup,
  changeCaseInChosenKeyword
} from "../../services/api";
import {
  exportKeywordGroups,
  importKeywords
} from "../../services/importExportApi";
import "./Keyword.scss";

const { Content, Header } = Layout;

export default class Keywords extends Component {
  state = {
    inited: false,
    keywordGroupList: [],
    modalForNewKeywordsGroup: false,
    newKeywordNameInput: "",
    choosedKeywordItem: {},
    openkeywordsModalWindow: false,
    synonymsList: [],
    totalItems: 0,
    pageSize: 15,
    chosedKeywordGroupName: "",
    changeCaseSensitive: true
  };
  componentDidMount = () => {
    this.updateKeywordsList();
  };
  updateKeywordsList = async () => {
    try {
      const { match } = this.props;
      const res = await getAllKeywordGroup(match.params.categoryId);
      this.setState({
        inited: true,
        keywordGroupList: res.data.items || [],
        totalItems: res.data.total || 0,
        choosedKeywordItem: "",
        newKeywordNameInput: "",
        modalForNewKeywordsGroup: false,
        openkeywordsModalWindow: false
      });
      this.handleClose();
    } catch (error) {
      ErrorNotification({ text: error });
    }
  };
  openModalForAddNewKeyword = () => {
    this.setState({
      modalForNewKeywordsGroup: true
    });
  };
  addNewKeywordsGroupItem = async () => {
    const { newKeywordNameInput } = this.state;
    const { match } = this.props;
    try {
      const data = {
        name: newKeywordNameInput,
        categoryId: Number(match.params.categoryId)
      };
      if (newKeywordNameInput !== "") {
        await addNewKeywordGroup(data);
        SuccessNotification({
          text: intl.get("successMessageForNotificationWindow")
        });
        this.updateKeywordsList();
      } else {
        ErrorNotification({ text: intl.get("validationErrorMessage") });
      }
    } catch (error) {
      ErrorNotification({ text: error });
    }
  };
  handleClose = () => {
    this.setState({
      modalForNewKeywordsGroup: false,
      newKeywordNameInput: "",
      openkeywordsModalWindow: false
    });
  };
  handleChangeAddNewKeyWordsGroupItemInput = e => {
    this.setState({
      newKeywordNameInput: e.target.value
    });
  };
  chooseItemList = (itemId, itemName) => {
    this.setState({
      choosedKeywordItem: itemId,
      chosedKeywordGroupName: itemName
    });
  };
  heandleRemoveKeywordItem = async item => {
    try {
      await deleteKeywordGroupItem(item.id);
      SuccessNotification({
        text: intl.get("successMessageForNotificationWindow")
      });
      this.updateKeywordsList();
    } catch (error) {
      ErrorNotification({ text: error });
    }
  };
  updateKeywordItem = async item => {
    try {
      await updateKeyworGroupdItem(item.id, item);
      SuccessNotification({
        text: intl.get("successMessageForNotificationWindow")
      });
      this.updateKeywordsList();
    } catch (error) {
      ErrorNotification({ text: error });
    }
  };
  getSynonymsForChosedKeyword = async () => {
    const { choosedKeywordItem } = this.state;
    try {
      const res = await getAllSynonyms(choosedKeywordItem);
      this.setState({
        synonymsList: res.data.items || []
      });
    } catch (error) {
      ErrorNotification({ text: error });
    }
  };
  heandleOpenModalWindowForKeyword = () => {
    const { choosedKeywordItem } = this.state;
    if (!isEmpty(choosedKeywordItem)) {
      this.getSynonymsForChosedKeyword();
      this.setState({
        openkeywordsModalWindow: true
      });
    } else {
      ErrorNotification({ text: intl.get("keywordChooseItemError") });
    }
  };
  handleChangePaginationPage = async (page, pageSize) => {
    const { match } = this.props;
    const { totalItems } = this.state;
    try {
      const limit = totalItems;
      const offset = page * pageSize;
      const res = await getAllKeywordGroup(
        match.params.categoryId,
        limit,
        offset
      );
      this.setState({
        currentPage: page,
        totalItems: res.data.total || 0
      });
    } catch (error) {
      ErrorNotification({ text: error });
    }
  };
  handleChangeSearchInput = e => {
    this.setState(
      {
        searchFiled: e.target.value
      },
      () => {
        if (this.state.searchFiled.length > 3) {
          this.filteringKeywordsList();
        } else {
          this.updateKeywordsList();
        }
      }
    );
  };
  filteringKeywordsList = debounce(async () => {
    const { match } = this.props;
    const { searchFiled } = this.state;
    try {
      const res = await searchAllKeywordGroup(
        match.params.categoryId,
        searchFiled
      );
      this.setState({
        keywordGroupList: res.data.items || []
      });
    } catch (error) {
      ErrorNotification({ text: error });
    }
  });
  handleChangeChangeCaseInSynonym = e => {
    this.setState({
      changeCaseSensitive: e.target.value
    });
  };
  changeCaseInChosenKeyword = async () => {
    const { changeCaseSensitive, choosedKeywordItem } = this.state;
    try {
      const data = {
        caseSensitive: changeCaseSensitive
      };
      await changeCaseInChosenKeyword(Number(choosedKeywordItem), data);
      SuccessNotification({
        text: intl.get("successMessageForNotificationWindow")
      });
      this.getSynonymsForChosedKeyword();
    } catch (error) {
      ErrorNotification({ text: error });
    }
  };
  exportKeywordGroups = async () => {
    const { match } = this.props;
    try {
      await exportKeywordGroups(Number(match.params.categoryId));
    } catch (error) {
      ErrorNotification({ text: error });
    }
  };
  sendFileToServer = async data => {
    const { match } = this.props;
    try {
      await importKeywords(match.params.categoryId, data);
      SuccessNotification({
        text: intl.get("successMessageForNotificationWindow")
      });
      this.updateKeywordsList();
    } catch (error) {
      ErrorNotification({ text: error });
    }
  };
  handleChangeFile = e => {
    e.preventDefault();
    let file = e.target.files[0];
    const formData = new FormData();
    formData.append("file", file);

    this.sendFileToServer(formData);
  };
  render() {
    const { match, lang } = this.props;
    const {
      inited,
      keywordGroupList,
      modalForNewKeywordsGroup,
      choosedKeywordItem,
      newKeywordNameInput,
      openkeywordsModalWindow,
      synonymsList,
      pageSize,
      totalItems,
      chosedKeywordGroupName,
      changeCaseSensitive
    } = this.state;
    if (!inited) {
      return (
        <Layout className="layoutForSpinner">
          <Spin size="large" className="spinComponentStyle" />
        </Layout>
      );
    }
    return (
      <>
        <Layout className="removeBackGroundOnLayout">
          <Header className="headerStyle">
            <div className="headerBlock">
              <div className="keywordsBreadcrumbsBlock">
                <BreadcrumbComponent
                  lang={lang}
                  matchParam={this.props.match}
                  currentPlace={intl.get("breadCrumbsKeywords")}
                />
                <div className="doCaseSensitiveOrNotBlock">
                  <p className="makeCaseSensitiveTitle">
                    {intl.get("makeAllKeywordSenSensitiveOrNot")}
                  </p>
                  <Radio.Group
                    onChange={this.handleChangeChangeCaseInSynonym}
                    value={changeCaseSensitive}
                  >
                    <Radio value={false}>
                      {intl.get("synonymRadioCaseSensetive")}
                    </Radio>
                    <Radio value={true}>
                      {intl.get("synonymRadioCaseInsensetive")}
                    </Radio>
                  </Radio.Group>
                  <Button
                    type="primary"
                    className="changeCaseInSynonymsButton"
                    onClick={this.changeCaseInChosenKeyword}
                  >
                    {intl.get("makeAllKeywordSensitiveButton")}
                  </Button>
                </div>
              </div>
              <div className="keywordsSearchImportExportBlock">
                <Input
                  placeholder={intl.get("keywordsSearchFiledPlaceHolder")}
                  onChange={this.handleChangeSearchInput}
                />
                <div className="importExportButtonsBlock">
                  <div>
                    <input
                      type="file"
                      id="importDataSourceFile"
                      onChange={this.handleChangeFile}
                      className="inputForChooseFile"
                    />
                    <label htmlFor="importDataSourceFile">
                      <p className="importKeywordsButton">
                        {intl.get("importKeywordsButtonName")}
                      </p>
                    </label>
                  </div>
                  <Button
                    type="primary"
                    className="exportKeywordButtonStyle"
                    onClick={this.exportKeywordGroups}
                  >
                    {intl.get("exportKeywordsButtonName")}
                  </Button>
                </div>
              </div>
            </div>
          </Header>
          <Content>
            <List
              dataSource={keywordGroupList}
              pagination={{
                position: "bottom",
                pageSize: pageSize,
                total: totalItems,
                onChange: this.handleChangePaginationPage
              }}
              grid={{
                gutter: 8,
                xs: 1,
                sm: 2,
                md: 3,
                lg: 3,
                xl: 4,
                xxl: 6
              }}
              renderItem={(item, index) => (
                <List.Item key={item.id}>
                  <ButtonInput
                    key={item.id}
                    itemOfList={item}
                    text={item.name}
                    goToNext={this.heandleOpenModalWindowForKeyword}
                    choosedItem={choosedKeywordItem}
                    chooseItem={this.chooseItemList}
                    removeIndustryItem={this.heandleRemoveKeywordItem}
                    updateItem={this.updateKeywordItem}
                  />
                </List.Item>
              )}
            />
            <Button
              type="primary"
              size={"large"}
              className="addNewIndustryButton"
              onClick={this.openModalForAddNewKeyword}
            >
              <Icon type="plus-circle" className="iconAddONButton" />
              {intl.get("keywordsAddNewButtonName")}
            </Button>
          </Content>
          {/*<Footer>
            <Button
              type="primary"
              size={"large"}
              className="selectButton"
              onClick={this.heandleOpenModalWindowForKeyword}
            >
              {intl.get("bottomButtonSelect")}
            </Button>
          </Footer>*/}
        </Layout>
        <ModalWindow
          open={modalForNewKeywordsGroup}
          title={intl.get("keywordAddNewItemModalTitle")}
          handleSubmit={this.addNewKeywordsGroupItem}
          handleClose={this.handleClose}
        >
          <Input
            value={newKeywordNameInput}
            onChange={this.handleChangeAddNewKeyWordsGroupItemInput}
          />
        </ModalWindow>
        <Modal
          visible={openkeywordsModalWindow}
          className="modalWindowForKeywords"
          onCancel={this.handleClose}
          footer={null}
        >
          <Synonyms
            getSynonymsForChosedKeyword={this.getSynonymsForChosedKeyword}
            keywordCategory={choosedKeywordItem}
            match={match}
            keywordGroupName={chosedKeywordGroupName}
            synonymsList={synonymsList}
            onCancel={this.handleClose}
          />
        </Modal>
      </>
    );
  }
}
