import React, { useCallback, useState } from "react";
import PropTypes from "prop-types";
import intl from "react-intl-universal";
import { useHistory } from "react-router-dom";
import { List, Icon, Card, Input } from "antd";
import ModalWindow from "../../../components/UI/ModalWindow";
import SuccessNotification from "../../../components/UI/Notifications/SuccessNotification";
import ErrorNotification from "../../../components/UI/Notifications/ErrorNotification";
import { updateKeyworGroupdItem } from "../../../services/api";
import "./ListItem.scss";

const ListItem = props => {
  const { listItem, selectListItem, goToNext } = props;
  const [state, setState] = useState({
    editItem: listItem.name || "",
    openEditModal: false
  });

  const history = useHistory();

  const handleChooseItem = useCallback(() => {
    selectListItem(listItem);
  }, [selectListItem, listItem]);

  const handleDoubleClick = useCallback(() => {
    selectListItem(listItem);
    goToNext(listItem.id);
  }, [goToNext, listItem, selectListItem]);

  const handleGoToCategory = useCallback(() => {
    history.push(
      `/${props.lang}/typology/${props.listItem.industry_id}/segment/${props.listItem.segment_id}/category/${props.listItem.category_id}/keywords`
    );
  }, [
    history,
    props.lang,
    props.listItem.category_id,
    props.listItem.industry_id,
    props.listItem.segment_id
  ]);

  const handleOpenEditWindow = useCallback(() => {
    setState(s => {
      return {
        ...s,
        openEditModal: true
      };
    });
  }, [setState]);

  const handleCloseEditWindow = useCallback(() => {
    setState(s => {
      return {
        ...s,
        openEditModal: false
      };
    });
  }, [setState]);

  const handleChangeItemName = useCallback(
    e => {
      e.persist();
      setState(s => {
        return {
          ...s,
          editItem: e.target.value
        };
      });
    },
    [setState]
  );

  const saveEditedItem = useCallback(async () => {
    try {
      const data = {
        name: state.editItem
      };
      await updateKeyworGroupdItem(props.listItem.id, data).then(() => {
        SuccessNotification({
          text: intl.get("successMessageForNotificationWindow")
        });
        handleCloseEditWindow();
        props.updateKeywordList();
      });
    } catch (error) {
      ErrorNotification({ text: error });
    }
  }, [handleCloseEditWindow, props, state.editItem]);

  return (
    <React.Fragment>
      <Card
        className="cardListItem"
        onClick={handleChooseItem}
        //onDoubleClick={handleDoubleClick}
      >
        <List.Item className="listItemRow">
          <React.Fragment>
            <div className="listItemContentBlock">
              <div className="listItemBlockGlobalKeyword">
                <Icon
                  type="check-circle"
                  theme="filled"
                  style={{
                    paddingTop: "5px",
                    paddingRight: "5px",
                    color:
                      props.selectedItem === props.listItem.id ||
                      props.synonymItem
                        ? "#2F80ED"
                        : ""
                  }}
                />
                <p className="listItemText">{props.children}</p>
              </div>
              <div className="listItemBlockTopologyBlock">
                <p className="listItemText">
                  {props.listItem.industry_name}
                  {" / "}
                </p>
                <p className="listItemText">
                  {props.listItem.category_name}
                  {" / "}
                </p>
                <p className="listItemText">{props.listItem.segment_name}</p>
              </div>
            </div>
            <div>
              <Icon
                className="globalKeywordSearchListButton"
                type="edit"
                onClick={handleOpenEditWindow}
              />
              <Icon
                className="globalKeywordSearchListButton"
                type="folder"
                onClick={handleGoToCategory}
              />
              <Icon
                className="globalKeywordSearchListButton"
                type="eye"
                onClick={handleDoubleClick}
              />
            </div>
          </React.Fragment>
        </List.Item>
      </Card>
      <ModalWindow
        open={state.openEditModal}
        title="Edit keyword"
        handleClose={handleCloseEditWindow}
        handleSubmit={saveEditedItem}
      >
        <Input onChange={handleChangeItemName} value={state.editItem} />
      </ModalWindow>
    </React.Fragment>
  );
};

ListItem.propTypes = {
  synonymItem: PropTypes.bool,
  listItem: PropTypes.objectOf(PropTypes.any),
  handleDeleteItem: PropTypes.func.isRequired,
  selectedItem: PropTypes.string,
  selectListItem: PropTypes.func.isRequired,
  handleEditForm: PropTypes.func,
  goToNext: PropTypes.func
};

ListItem.defaultProps = {
  synonymItem: false,
  listItem: {},
  handleEditForm: () => {},
  selectedItem: "",
  goToNext: () => {}
};

export default ListItem;
